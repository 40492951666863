export const DEFAULT_IMAGE_SRC = '/images/def-image.png'

export const LOGIN_REQUEST = '/identity/login'
export const APP_CONFIG_REQUEST = '/appConfig'

export const PROFILE_GET_REQUEST = '/profile'
export const PROFILE_SAVE_REQUEST = '/profile/save'

export const BRANCHES_RESOURCE = 'dictionaries/branches'
export const ORDER_CHECKOUT_OPTIONS_KEYS_RESOURCE = 'dictionaries/orderCheckoutOptions'
export const CATEGORIES_RESOURCE = 'categories'
export const PRODUCTS_RESOURCE = 'products'
export const PRODUCT_ADDITIONAL_OPTIONS_RESOURCE = 'dictionaries/productAdditionalOptions'
export const PRODUCT_MODIFICATIONS_RESOURCE = 'dictionaries/productModifications'

export const GOODS_GET_REQUEST = '/goods'

/**
 * Получить категорие и продукты без родительской категории по конкретному филиалу
 */
export const GOODS_GET_WITHOUT_PARENT_REQUEST = '/goods/withoutParent'

/**
 * /goods/delete/{id}/{GoodsType}
 */
export const GOODS_DELETE_REQUEST = '/goods/delete'

/**
 * /goods/toggle/{id}/{GoodsType}
 */
export const GOODS_ACTIVE_TOGGLE_REQUEST = '/goods/toggle'

export const CATEGORY_GET_REQUEST = '/categories'
export const CATEGORY_SAVE_REQUEST = '/categories/save'
export const PRODUCT_GET_REQUEST = '/products'
export const PRODUCT_SAVE_REQUEST = '/products/save'
export const GOODS_UPDATE_ORDER_NUMBER_REQUEST = '/goods/updateOrderNumber'

/**
 * /products/modifications/{parentProductId?}
 */
export const PRODUCT_MODIFICATIONS_GET_REQUEST = '/products/modifications'

export const UPLOAD_FILE = '/files/upload'

/**
 * '/files/{id}'
 */
export const GET_FILE_DTO = '/files'

/**
 * promocodes - получить все промокоды.
 * promocodes/id - получить промокод по id.
 * promocodes/default - получить дефолтное представление для создания нового промокода.
 */
export const GET_PROMOCODES = '/promocodes'
export const SAVE_PROMOCODE = '/promocodes/save'
export const DELETE_PROMOCODES = '/promocodes/delete'

/**
 * Получить перечисление PromocodeConditionType для списка
 */
export const PROMOMCODE_CONDITION_TYPES_RESOURCE = 'promocodeConditionType'

/**
 * Получение списка продукты доступных в продвижнии
 */
export const PRODUCTS_PROMOTION_RESOURCE = 'productsForPromotion'

/**
 * Получить перечисление PromocodeConditionType для списка
 */
export const PROMOMCODE_REWARD_TYPES_RESOURCE = 'promocodeRewardType'

/**
  * Активация/деактивация новости 'dictionaries/informationPosts/toggle/{id}'
  */
export const INFORMATION_POST_TOGGLE_REQUEST = 'dictionaries/informationPosts/toggle'

/**
* stock - получить все акции.
* stock/id - получить акцию по id.
* stock/default - получить дефолтное представление для создания новой акции.
*/
export const GET_STOCKS = '/stocks'
export const SAVE_STOCK = '/stocks/save'
export const DELETE_STOCKS = '/stocks/delete'

/**
 * Получить перечисление StockConditionType для списка
 */
export const STOCK_CONDITION_TYPES_RESOURCE = 'stockConditionType'

/**
* Получить перечисление StockConditionType для списка
*/
export const STOCK_REWARD_TYPES_RESOURCE = 'stockRewardType'

/**
* Получить перечисление StockDurationType для списка
*/
export const STOCK_DURATION_TYPES_RESOURCE = 'stockDurationType'

/**
* Получить перечисление DeliveryOrderType для списка
*/
export const DELIVERY_ORDER_TYPES_RESOURCE = 'deliveryOrderType'

/**
  * Активация/деактивация акции 'stocks/toggle/{id}'
  */
 export const STOCK_TOGGLE_REQUEST = 'stocks/toggle'

 /**
 * Получить перечисление AffiliateRewardType для списка
 */
export const AFFILIATE_REWARD_TYPES_RESOURCE = 'affiliateRewardType'

/**
 * promocodes - получить все промокоды.
 * promocodes/id - получить промокод по id.
 * promocodes/default - получить дефолтное представление для создания нового промокода.
 */
export const GET_AFFILIATE_PROGRAMS = '/dictionaries/affiliatePrograms'
export const SAVE_AFFILIATE_PROGRAMS = '/dictionaries/affiliatePrograms/save'
export const DELETE_AFFILIATE_PROGRAMS = '/dictionaries/affiliatePrograms/delete'

/**
* Получить перечисление TargetOpenType(для push уведомлений) для списка
*/
export const TARGET_OPEN_TYPE_RESOURCE = 'targetOpenType'

export const CATEGORIES_BY_BRANCH_RESOURCE = 'categoryByBranchLookups'
export const PRODUCTS_BY_BRANCH_RESOURCE = 'productByBranchLookups'
export const STOCKS_BY_BRANCH_RESOURCE = 'stockByBranchLookups'
export const INFORMATION_POSTS_BY_BRANCH_RESOURCE = 'informationPostByBranchLookups'

/**
 * Добавить push уведомление в очередь рассылки. Метод POST
 */
export const SEND_PUSH_NOTIFICATION_REQUEST = '/pushNotifications/addToQueue'
import React from 'react'
import { Layout } from './pages/mainLayout/Layout'
import { toast, ToastContainer } from 'react-toastify'
import './app.css'

const App: React.FC = () => {
  return (
    <>
      <Layout />
      <ToastContainer
        draggable={false}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={5000}
        theme = 'colored'
      />
    </>)
}

export default App
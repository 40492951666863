import { AnyAction } from '@reduxjs/toolkit'
import { IResourcesState } from '../../domain/resources/IResourcesState'
import { FETCH_GET_RESOURCES_FAILURE, FETCH_GET_RESOURCES_REQUEST, FETCH_GET_RESOURCES_SUCCESS, RESOURCES_CLEAR } from './actions'

const initialState: IResourcesState = {
    isGetResourcesFetching: false,
    error: '',
    resources: {},
    countExecutingRequest: 0
}

export const resourcesReducer = (state = initialState, action: AnyAction): IResourcesState => {
    switch (action.type) {
        case FETCH_GET_RESOURCES_REQUEST:
            return {
                ...state,
                isGetResourcesFetching: true,
                countExecutingRequest: state.countExecutingRequest + 1,
                resources: {
                    ...state.resources,
                    [action.payload.propName]: []
                }
            }
        case FETCH_GET_RESOURCES_SUCCESS:
            {
                const countExecutingRequest = state.countExecutingRequest - 1
                return {
                    ...state,
                    isGetResourcesFetching: countExecutingRequest != 0,
                    countExecutingRequest,
                    resources: {
                        ...state.resources,
                        [action.payload.propName]: action.payload.resources
                    }
                }
            }
        case FETCH_GET_RESOURCES_FAILURE:
            {
                const countExecutingRequest = state.countExecutingRequest - 1
                return {
                    ...state,
                    isGetResourcesFetching: countExecutingRequest != 0,
                    countExecutingRequest,
                    error: action.payload
                }
            }
        case RESOURCES_CLEAR: return {...initialState}

        default: return state
    }
}
import React, { useEffect } from "react"
import { Pages } from "../../domain/navigation/pages"
import { useDispatchAction } from "../../hooks/reduxHook"
import { changeNavigatePage } from "../../store/navigation/actions"

export const SettingsPage: React.FC = () => {
    var dispatch = useDispatchAction()

    useEffect(() => {
        dispatch(changeNavigatePage(Pages.Settings))
    }, [dispatch])

    return <div>Settings page</div>
}
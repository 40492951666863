import { IGoodsState } from './../../domain/pages/goods/IGoodsState'
import { AnyAction } from '@reduxjs/toolkit'
import { CLEAR_DELETE_GOODS_RESULT, CLEAR_TOGGLE_ACTIVE_GOODS_RESULT, FETCH_DELETE_GOODS_FAILURE, FETCH_DELETE_GOODS_REQUEST, FETCH_DELETE_GOODS_SUCCESS, FETCH_GET_GOODS_FAILURE, FETCH_GET_GOODS_REQUEST, FETCH_GET_GOODS_SUCCESS, FETCH_TOGGLE_ACTIVE_GOODS_FAILURE, FETCH_TOGGLE_ACTIVE_GOODS_REQUEST, FETCH_TOGGLE_ACTIVE_GOODS_SUCCESS, SET_GOODS } from './actions'

const initialState: IGoodsState = {
    error: '',
    goods: [],
    isFetching: false,
    currentCategory: null,
    deleteGoodsResult: null,
    toggleActiveGoodsResult: null,
    isDeleteGoodsFetching: false,
    isToggleActiveGoodsFetching: false
}

export const goodsReducer = (state = initialState, action: AnyAction): IGoodsState => {
    switch (action.type) {
        case FETCH_GET_GOODS_REQUEST:
            return {
                ...initialState,
                currentCategory: state.currentCategory,
                isFetching: true,
                error: '',
                deleteGoodsResult: null,
                toggleActiveGoodsResult: null,
            }
        case FETCH_GET_GOODS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                ...action.payload
            }
        case FETCH_GET_GOODS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload
            }
        case FETCH_DELETE_GOODS_REQUEST:
            return {
                ...state,
                isDeleteGoodsFetching: true,
                error: '',
                deleteGoodsResult: null
            }
        case FETCH_DELETE_GOODS_SUCCESS:
            return {
                ...state,
                deleteGoodsResult: action.payload,
                isDeleteGoodsFetching: false,
            }
        case FETCH_DELETE_GOODS_FAILURE:
            return {
                ...state,
                isDeleteGoodsFetching: false,
                error: action.payload
            }
        case CLEAR_DELETE_GOODS_RESULT:
            return {
                ...state,
                deleteGoodsResult: null
            }
        case FETCH_TOGGLE_ACTIVE_GOODS_REQUEST:
            return {
                ...state,
                isToggleActiveGoodsFetching: true,
                error: '',
                toggleActiveGoodsResult: null
            }
        case FETCH_TOGGLE_ACTIVE_GOODS_SUCCESS:
            return {
                ...state,
                toggleActiveGoodsResult: action.payload,
                isToggleActiveGoodsFetching: false,
            }
        case FETCH_TOGGLE_ACTIVE_GOODS_FAILURE:
            return {
                ...state,
                isToggleActiveGoodsFetching: false,
                error: action.payload
            }
        case CLEAR_TOGGLE_ACTIVE_GOODS_RESULT:
            return {
                ...state,
                toggleActiveGoodsResult: null
            }
        case SET_GOODS:
            return {
                ...state,
                goods: [...action.payload]
            }
        default: return state
    }
}

import { AnyAction } from '@reduxjs/toolkit'
import { ICategoryFormState } from '../../../domain/pages/goods/category/ICategoryFormState'
import { CLEAR_CATEGORY_SAVING_RESULT, FETCH_GET_BY_ID_CATEGORY_FAILURE, FETCH_GET_BY_ID_CATEGORY_REQUEST, FETCH_GET_BY_ID_CATEGORY_SUCCESS, FETCH_SAVE_CATEGORY_FAILURE, FETCH_SAVE_CATEGORY_REQUEST, FETCH_SAVE_CATEGORY_SUCCESS } from './actions'

const initialState: ICategoryFormState = {
    error: '',
    categoryFormSavingResult: null,
    isCategoryFormSaveFetching: false,
    isGetCategoryByIdFetching: false,
    form: null
}

export const categoryFormReducer = (state = initialState, action: AnyAction): ICategoryFormState => {
    switch (action.type) {
        case FETCH_GET_BY_ID_CATEGORY_REQUEST:
            return {
                ...initialState,
                isGetCategoryByIdFetching: true,
                error: '',
                categoryFormSavingResult: null
            }
        case FETCH_GET_BY_ID_CATEGORY_SUCCESS:
            return {
                ...state,
                isGetCategoryByIdFetching: false,
                form: action.payload
            }
        case FETCH_GET_BY_ID_CATEGORY_FAILURE:
            return {
                ...state,
                isGetCategoryByIdFetching: false,
                error: action.payload
            }
        case FETCH_SAVE_CATEGORY_REQUEST:
            return {
                ...state,
                isCategoryFormSaveFetching: true,
                error: '',
                categoryFormSavingResult: null
            }
        case FETCH_SAVE_CATEGORY_SUCCESS:
            return {
                ...state,
                categoryFormSavingResult: action.payload,
                isCategoryFormSaveFetching: false,
            }
        case FETCH_SAVE_CATEGORY_FAILURE:
            return {
                ...state,
                isCategoryFormSaveFetching: false,
                error: action.payload
            }
        case CLEAR_CATEGORY_SAVING_RESULT: {
            return {
                ...state,
                categoryFormSavingResult: null,
                form: null
            }
        }
        default: return state
    }
}
/**
 * Форматирование даты и времени

 * @returns string dd.mm.yyyy hh:mm
 */
export const formatDateTime = (dateTime: Date | null | undefined) => {
    return formatDateTimeInner(dateTime)
}

/**
 * Форматирование даты
 * @returns string dd.mm.yyyy
 */
 export const formatDate = (date: Date | null | undefined) => {
    return formatDateTimeInner(date, true)
}

/**
 * Форматирование даты и времени
 * @param dateTime 
 * @returns string dd.mm.yyyy hh:mm
 */
 export const formatDateTimeInner = (dateTime: Date | null | undefined, onlyDate: boolean = false) => {
    if (!dateTime)
        return ''

    const minutes = dateTime.getMinutes()
    const month = dateTime.getMonth() + 1

    const date = [dateTime.getDate(),
        month < 10 ? `0${month}` : month,
        dateTime.getFullYear()]
        .join('.')
    const time = onlyDate
        ? ''
        : ' ' + [dateTime.getHours(),
        minutes < 10 ? `0${minutes}` : minutes]
        .join(':')

    return `${date}${time}`
}

export const isDateTime = (dateTime: string) => {
    return !isNaN(Date.parse(dateTime))
}

export const createDateAsUTC = (date: Date | undefined) => {
    if (!date)
        return undefined

    return new Date(Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()))
}

/**
 * 
 * @param value строка с датой и временем в формате dd.mm.yyyy hh:mm
 * @returns Date
 */
export const parseDateTime = (value: string | null | undefined): Date | undefined => {
    return parseDateTimeInner(value)
}

/**
 * 
 * @param value строка с датой в формате dd.mm.yyyy
 * @returns Date
 */
export const parseDate = (value: string | null | undefined): Date | undefined => {
    return parseDateTimeInner(value, true)
}

/**
 * 
 * @param value строка с датой и временем в формате dd.mm.yyyy hh:mm
 * @param onlyDate парсит только дату игнорирую время
 * @returns Date
 */
const parseDateTimeInner = (value: string | null | undefined, onlyDate: boolean = false) => {
    if (!value)
        return

    const dateAndTimeSplit = value.split(' ')
    const dateSplit = dateAndTimeSplit[0].split('.')
    const day = dateSplit[0]
    const month = dateSplit[1]
    const year = dateSplit[2]
    
    // на js Date парсит строку в формате mm/dd/yyyy
    // используем / вместо . для поддержки браузера Safari
    const date = `${month}/${day}/${year}`
    const time = onlyDate ? '' : ` ${dateAndTimeSplit[1]}`
    const validFormatValueForJs = `${date}${time}`
    
    return new Date(validFormatValueForJs)
}
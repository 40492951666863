
import { AnyAction } from '@reduxjs/toolkit'
import { IDictionaryState } from '../../domain/pages/dictionaries/IDictionaryState'
import {
    CLEAR_DICTIONARY_FORM_SAVING_RESULT,
    FETCH_DELETE_DICTIONARY_ITEMS_FAILURE,
    FETCH_DELETE_DICTIONARY_ITEMS_REQUEST,
    FETCH_DELETE_DICTIONARY_ITEMS_SUCCESS,
    FETCH_GET_BY_ID_DICTIONARY_FAILURE,
    FETCH_GET_BY_ID_DICTIONARY_REQUEST,
    FETCH_GET_BY_ID_DICTIONARY_SUCCESS,
    FETCH_GET_DICTIONARY_FAILURE,
    FETCH_GET_DICTIONARY_REQUEST,
    FETCH_GET_DICTIONARY_SUCCESS,
    FETCH_SAVE_FORM_DICTIONARY_FAILURE,
    FETCH_SAVE_FORM_DICTIONARY_REQUEST,
    FETCH_SAVE_FORM_DICTIONARY_SUCCESS
} from './actions'

const initialState: IDictionaryState = {
    error: '',
    isGetDictionaryFetching: false,
    dictionaryFormSavingResult: null,
    isDictionaryFormSaveFetching: false,
    items: [],
    isGetByIdDictionaryFetching: false,
    form: null,
    dictionaryDeleteItemsResult: null,
    isDictionaryDeleteItemsFetching: false,
}

export const dictionariesReducer = (state = initialState, action: AnyAction): IDictionaryState => {
    switch (action.type) {
        case FETCH_GET_DICTIONARY_REQUEST:
            return {
                ...initialState,
                isGetDictionaryFetching: true,
                error: '',
                dictionaryFormSavingResult: null,
                dictionaryDeleteItemsResult: null
            }
        case FETCH_GET_DICTIONARY_SUCCESS:
            return {
                ...state,
                isGetDictionaryFetching: false,
                items: action.payload
            }
        case FETCH_GET_DICTIONARY_FAILURE:
            return {
                ...state,
                isGetDictionaryFetching: false,
                error: action.payload
            }
        case FETCH_GET_BY_ID_DICTIONARY_REQUEST:
            return {
                ...initialState,
                isGetByIdDictionaryFetching: true,
                error: '',
                dictionaryFormSavingResult: null
            }
        case FETCH_GET_BY_ID_DICTIONARY_SUCCESS:
            return {
                ...state,
                isGetByIdDictionaryFetching: false,
                form: action.payload
            }
        case FETCH_GET_BY_ID_DICTIONARY_FAILURE:
            return {
                ...state,
                isGetByIdDictionaryFetching: false,
                error: action.payload
            }
        case FETCH_SAVE_FORM_DICTIONARY_REQUEST:
            return {
                ...state,
                isDictionaryFormSaveFetching: true,
                error: '',
                dictionaryFormSavingResult: null
            }
        case FETCH_SAVE_FORM_DICTIONARY_SUCCESS:
            return {
                ...state,
                dictionaryFormSavingResult: action.payload,
                isDictionaryFormSaveFetching: false,
            }
        case FETCH_SAVE_FORM_DICTIONARY_FAILURE:
            return {
                ...state,
                isDictionaryFormSaveFetching: false,
                error: action.payload
            }
        case CLEAR_DICTIONARY_FORM_SAVING_RESULT: {
            return {
                ...state,
                dictionaryFormSavingResult: null
            }
        }
        case FETCH_DELETE_DICTIONARY_ITEMS_REQUEST:
            return {
                ...state,
                isDictionaryDeleteItemsFetching: true,
                error: '',
                dictionaryDeleteItemsResult: null
            }
        case FETCH_DELETE_DICTIONARY_ITEMS_SUCCESS:
            return {
                ...state,
                dictionaryDeleteItemsResult: action.payload,
                isDictionaryDeleteItemsFetching: false,
            }
        case FETCH_DELETE_DICTIONARY_ITEMS_FAILURE:
            return {
                ...state,
                isDictionaryDeleteItemsFetching: false,
                error: action.payload
            }
        default: return state
    }
}
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Image } from "semantic-ui-react"
import { DEFAULT_IMAGE_SRC } from "../../api/requestEndpoints"
import { IPushNotificationPreviewDeviceProps } from "../../domain/components/pushNotificationPreviewDevice/IPushNotificationPreviewDeviceProps"
import { PushNotificationPreviewDeviceType } from "../../domain/components/pushNotificationPreviewDevice/PushNotificationPreviewDeviceType"
import './index.css'

export const PushNotificationPreviewDevice: React.FC<IPushNotificationPreviewDeviceProps> = props => {
    const { t } = useTranslation()

    const backgroundClass = useMemo(() => {
        switch (props.type) {
            case PushNotificationPreviewDeviceType.Android:
                return 'push-preview-background-android'
            case PushNotificationPreviewDeviceType.Ios:
                return 'push-preview-background-ios'
        }
    }, [props.type])

    const containerClass = useMemo(() => {
        switch (props.type) {
            case PushNotificationPreviewDeviceType.Android:
                return 'preview-push-container-android'
            case PushNotificationPreviewDeviceType.Ios:
                return 'preview-push-container-ios'
        }
    }, [props.type])

    return (
        <div className='push-notification-preview-device'>
            <div className={`device-preview ${backgroundClass}`}></div>
            <div className={containerClass}>
                <div className='preview-msg-container'>
                    <p className='preview-msg-subject'>{props.subject || t('pushNotificationPreviewDefSubject')}</p>
                    <p className='preview-msg-body'>{props.body || t('pushNotificationPreviewDefBody')}</p>
                </div>
                <div className='preview-msg-image-container'>
                    <div className='preview-msg-attached-image'>
                        <Image src={props.imageUrl || DEFAULT_IMAGE_SRC} size='small' />
                    </div>
                </div>
            </div>
        </div>
    )
}
export enum Pages {
    Unknown = '',
    DictionaryAddNewRecord = 'dictionaryAddNewRecord',
    DictionaryEditRecord = 'dictionaryEditRecord',
    Orders = 'orders',
    Goods = 'goods',
    Promotion = 'promotion',
    Analytics = 'analytics',
    Settings = 'settings',
    Profile = 'profile',
    Promocodes = 'promocodes',
    InformationPosts = 'informationPosts',
    Stocks = 'stocks',
    AffiliatePrograms = 'affiliatePrograms',
    PushNotifications = 'pushNotifications'
}
import { IValidationResult } from '../../../domain/validationResult/IValidationResult';
import { AnyAction } from "@reduxjs/toolkit"
import { put } from '@redux-saga/core/effects'
import { postman } from '../../../utils/postman'
import { saveDictionaryFormFailure, saveDictionaryFormSuccess } from '../../../store/dictionaries/actions'
import { Dictionaries } from '../../../domain/navigation/dictionaries'
import { getEndpointsForDictionaryGetter } from '../../../api/endpointsHelper'

export function* saveDictionaryFormSaga(action: AnyAction): any {    
    try {
        const form = action.payload.form
        const dictionary: Dictionaries = action.payload.dictionary
        const endpointsGetter = getEndpointsForDictionaryGetter(dictionary)
        const url = endpointsGetter.endpointSave()
        const result: IValidationResult = yield postman.post(url, form)
        
        yield put(saveDictionaryFormSuccess(result))
    } catch (ex: any) {
        yield put(saveDictionaryFormFailure(ex.response.data))
    }
}
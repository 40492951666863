import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Card, Header, Icon, Message, Modal, Popup, Segment, Table } from 'semantic-ui-react'
import { CardWithTitle } from '../../../components/cardWithTitle'
import { IAppResult } from '../../../domain/appResult/IAppResult'
import { Pages } from '../../../domain/navigation/pages'
import { IStockDto } from '../../../domain/pages/promotion/stocks/IStockDto'
import { useDispatchAction, useSelectorState } from '../../../hooks/reduxHook'
import { changeNavigatePage } from '../../../store/navigation/actions'
import { clearDeleteStockResult, clearToggleStockResult, deleteStockRequest, getStocksRequest, setStocks, toggleStockRequest } from '../../../store/promotion/stocks/actions'

export const StocksPage: React.FC = () => {
    var dispatch = useDispatchAction()
    const history = useHistory()
    const { t } = useTranslation()
    const config = useSelectorState(state => state.appConfig.promotions.find(p => p.page == Pages.Stocks))
    const [openModal, setOpenModal] = React.useState(false)
    const openModalHandler = () => setOpenModal(true)
    const closeModalHandler = () => setOpenModal(false)
    const confirmedActionHandler = () => {
        if (deleteStockId) {
            dispatch(deleteStockRequest(deleteStockId))
        }

        closeModalHandler()
    }

    const stocks = useSelectorState(state => state.stocks.items)
    const isGetStocksFetching = useSelectorState(state => state.stocks.isGetStocksFetching)
    const loadStocks = () => dispatch(getStocksRequest())

    useEffect(() => {
        dispatch(changeNavigatePage(Pages.Stocks))
        loadStocks()
        
        return function() {
            dispatch(clearDeleteStockResult())
            dispatch(clearToggleStockResult())
        }
    }, [dispatch])

    const isStockDeleteFetching = useSelectorState(state => state.stocks.isStockDeleteFetching)
    const stockDeleteResult = useSelectorState(state => state.stocks.stockDeleteResult)
    const isStockToggleFetching = useSelectorState(state => state.stocks.isStockToggleFetching)
    const stockToggleResult = useSelectorState(state => state.stocks.stockToggleResult)

    const actionChangeResultDispatch = (
        result: IAppResult | null,
        successCallback: () => void) => {
        if (result && result.isError)
            toast.error(result.message)
        else if (result && !result.isError) {
            toast.success(result.message, { autoClose: 5000 })
            successCallback()
        }
    }

    const setStocksStore = (news: Array<IStockDto>) => dispatch(setStocks(news))

    useEffect(() => {
        var successCallback = () => {
            if (deleteStockId) {
                const itemIndex = stocks.findIndex(p => p.id == deleteStockId)
                const newsForChange = [...stocks]
                newsForChange.splice(itemIndex, 1)

                setStocksStore(newsForChange)
                setDeleteStockId(undefined)
            }
        }
        actionChangeResultDispatch(stockDeleteResult, successCallback)
    }, [stockDeleteResult])

    useEffect(() => {
        const successCallback = () => {
            if (toggleStockId) {
                const newsForChange = [...stocks]
                const itemIndex = newsForChange.findIndex(p => p.id == toggleStockId)
                const item = newsForChange[itemIndex]
                const newItem = { ...item, isActive: !item.isActive }
                newsForChange[itemIndex] = newItem

                setStocksStore(newsForChange)
                setToggleStockId(undefined)
            }
        }
        actionChangeResultDispatch(stockToggleResult, successCallback)
    }, [stockToggleResult])

    const stockEditHandler = (id: string) => history.push(`/stocks/${id}`)

    const [toggleStockId, setToggleStockId] = useState<string>()
    const stockToggleHandler = (id: string) => {
        setToggleStockId(id)
        dispatch(toggleStockRequest(id))
    }

    const [deleteStockId, setDeleteStockId] = useState<string>()
    const stockDeleteHandler = (id: string) => {
        setDeleteStockId(id)
        openModalHandler()
    }

    const isLoading = () => isGetStocksFetching || isStockDeleteFetching || isStockToggleFetching

    return (
        <div className='container'>
            <Segment attached='top'>
                <div className='segmentHeaderWithAdditionalBlock'>
                    <Header as='h3' className={'segmentHeader'}>{t(Pages.Stocks)}</Header>
                    <div className={'segmentHeaderButtonsWrapper'}>
                        {
                            config?.editAllowed &&
                            <Button.Group basic size='small'>
                                <Popup
                                    content={t('dictionaryAddNewRecord')}
                                    position='bottom right'
                                    trigger={
                                        <Button
                                            icon='add'
                                            disabled={isLoading()}
                                            as={Link}
                                            to={`/stocks/new`}
                                        />}
                                />
                            </Button.Group>
                        }
                    </div>
                </div>
            </Segment>
            {
                ((stocks && stocks.length > 0) || isLoading()) &&
                <Segment
                    attached='bottom'
                    loading={isLoading()}
                    className={'containerWrapper containerWrapperWithMinSizeForLoadingIndicator'}>
                    <Card.Group stackable>
                        {
                            stocks && stocks.length != 0 &&
                            stocks.map(stock => <CardWithTitle
                                key={stock.id}
                                id={stock.id}
                                src={stock.imageId!.path}
                                title={stock.name}
                                isActive={stock.isActive}
                                editHandler={stockEditHandler}
                                toggleHandler={stockToggleHandler}
                                deleteHandler={stockDeleteHandler} />)
                        }
                    </Card.Group>
                </Segment>
            }
            {
                ((!stocks || !stocks.length) && !isLoading()) &&
                <Message attached='bottom' size='tiny' info style={{ textAlign: 'center' }}>
                    <Icon name='info' />
                    {t('emptyStocksInfoMsg')}
                </Message>
            }
            <Modal
                size='mini'
                dimmer='blurring'
                open={openModal}
                onClose={closeModalHandler}
            >
                <Modal.Header>{t('dictionary.delete.records.confirm.header')}</Modal.Header>
                <Modal.Content>
                    <p>{t('dictionary.delete.records.confirm.question')}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={closeModalHandler}>
                        {t('false')}
                    </Button>
                    <Button positive onClick={confirmedActionHandler}>
                        {t('true')}
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>)
}
import { put } from '@redux-saga/core/effects'
import { setDefState as setDefAuthState} from '../../../store/auth/actions'
import { ACCESS_TOKEN } from '../../../domain/constants'
import { setDefState as setDefAppConfigState } from '../../../store/appConfig/actions'
import { setAccessToken } from '../../../utils/postman'

export function* logoutSaga(): any {    
    localStorage.removeItem(ACCESS_TOKEN)
    setAccessToken(null)
    
    yield put(setDefAuthState())
    yield put(setDefAppConfigState())
}
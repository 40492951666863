import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, Icon, Image, Popup } from 'semantic-ui-react'
import { ICardWithTitle } from '../../domain/components/cardWithTitle/ICardWithTitle'

export const CardWithTitle: React.FC<ICardWithTitle> = (props) => {
    const { t } = useTranslation()

    const editHandler = () => props.editHandler(props.id)
    const toggleHandler = () => props.toggleHandler(props.id)
    const deleteHandler = () => props.deleteHandler(props.id)

    return (
        <Card>
            <Image src={props.src} wrapped ui={false} />
            <Card.Content>
                <Card.Description>
                    {props.title}
                </Card.Description>
            </Card.Content>
            <Card.Content extra className={'card-extra-button-groups'}>
                <Button.Group basic size='tiny'>
                    <Popup
                        content={t('edit')}
                        position='bottom right'
                        trigger={
                            <Button
                                icon='edit'
                                onClick={editHandler}
                            />}
                    />
                    <Popup
                        content={props.isActive ? t('deactivate') : t('activate')}
                        position='bottom right'
                        trigger={
                            <Button
                                icon={props.isActive ? 'eye' : 'eye slash'}
                                onClick={toggleHandler}
                            />}
                    />
                    <Popup
                        content={t('delete')}
                        position='bottom right'
                        trigger={
                            <Button
                                icon='trash'
                                onClick={deleteHandler}
                            />}
                    />
                </Button.Group>
            </Card.Content>
        </Card>)
}
import { AnyAction } from 'redux'
import { IAppResult } from '../../../domain/appResult/IAppResult'
import { IPromocodeDto } from '../../../domain/pages/promotion/promocodes/IPromocodeDto'
import { IValidationResult } from '../../../domain/validationResult/IValidationResult'


export const FETCH_GET_PROMOCODES_REQUEST = 'FETCH_GET_PROMOCODES_REQUEST'
export const FETCH_GET_PROMOCODES_SUCCESS = 'FETCH_GET_PROMOCODES_SUCCESS'
export const FETCH_GET_PROMOCODES_FAILURE = 'FETCH_GET_PROMOCODES_FAILURE'

export const FETCH_GET_BY_ID_PROMOCODE_REQUEST = 'FETCH_GET_BY_ID_PROMOCODE_REQUEST'
export const FETCH_GET_BY_ID_PROMOCODE_SUCCESS = 'FETCH_GET_BY_ID_PROMOCODE_SUCCESS'
export const FETCH_GET_BY_ID_PROMOCODE_FAILURE = 'FETCH_GET_BY_ID_PROMOCODE_FAILURE'

export const FETCH_SAVE_FORM_PROMOCODE_REQUEST = 'FETCH_SAVE_FORM_PROMOCODE_REQUEST'
export const FETCH_SAVE_FORM_PROMOCODE_SUCCESS = 'FETCH_SAVE_FORM_PROMOCODE_SUCCESS'
export const FETCH_SAVE_FORM_PROMOCODE_FAILURE = 'FETCH_SAVE_FORM_PROMOCODE_FAILURE'
export const CLEAR_PROMOCODE_FORM_SAVING_RESULT = 'CLEAR_PROMOCODE_FORM_SAVING_RESULT'

export const FETCH_DELETE_PROMOCODE_ITEMS_REQUEST = 'FETCH_DELETE_PROMOCODE_ITEMS_REQUEST'
export const FETCH_DELETE_PROMOCODE_ITEMS_SUCCESS = 'FETCH_DELETE_PROMOCODE_ITEMS_SUCCESS'
export const FETCH_DELETE_PROMOCODE_ITEMS_FAILURE = 'FETCH_DELETE_PROMOCODE_ITEMS_FAILURE'

export const getPromocodesRequest = (): AnyAction => {
    return {
        type: FETCH_GET_PROMOCODES_REQUEST
    }
}

export const getPromocodesSuccess = (payload: Array<IPromocodeDto>): AnyAction => {
    return {
        type: FETCH_GET_PROMOCODES_SUCCESS,
        payload
    }
}

export const getPromocodesFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_GET_PROMOCODES_FAILURE,
        payload
    }
}

/**
 * 
 * @param payload promocode id
 * @returns 
 */
export const getByIdPromocodeRequest = (payload: string | undefined): AnyAction => {
    return {
        type: FETCH_GET_BY_ID_PROMOCODE_REQUEST,
        payload
    }
}

export const getByIdPromocodeSuccess = (payload: IPromocodeDto): AnyAction => {
    return {
        type: FETCH_GET_BY_ID_PROMOCODE_SUCCESS,
        payload
    }
}

export const getByIdPromocodeFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_GET_BY_ID_PROMOCODE_FAILURE,
        payload
    }
}

export const savePromocodeFormRequest = (payload: IPromocodeDto): AnyAction => {
    return {
        type: FETCH_SAVE_FORM_PROMOCODE_REQUEST,
        payload
    }
}

export const savePromocodeFormSuccess = (payload: IValidationResult): AnyAction => {
    return {
        type: FETCH_SAVE_FORM_PROMOCODE_SUCCESS,
        payload
    }
}

export const savePromocodeFormFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_SAVE_FORM_PROMOCODE_FAILURE,
        payload
    }
}

export const clearPromocodeFormSavingResult = (): AnyAction => {
    return {
        type: CLEAR_PROMOCODE_FORM_SAVING_RESULT
    }
}

/**
 * 
 * @param payload список индентификаторов для удаления
 * @returns 
 */
export const deletePromocodeItemRequest = (payload: Array<string>): AnyAction => {
    return {
        type: FETCH_DELETE_PROMOCODE_ITEMS_REQUEST,
        payload
    }
}

export const deletePromocodeItemSuccess = (payload: IAppResult): AnyAction => {
    return {
        type: FETCH_DELETE_PROMOCODE_ITEMS_SUCCESS,
        payload
    }
}

export const deletePromocodeItemFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_DELETE_PROMOCODE_ITEMS_FAILURE,
        payload
    }
}
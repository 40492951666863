import {
    all,
    takeEvery
} from 'redux-saga/effects'
import { FETCH_GET_RESOURCES_REQUEST } from '../../store/resources/actions'
import { getResourcesSaga } from './getResourcesSaga'


export function* saga() {
    yield all([
        takeEvery(FETCH_GET_RESOURCES_REQUEST, getResourcesSaga)
    ])
}

import { APP_CONFIG_REQUEST } from '../../api/requestEndpoints'
import { IAppConfig } from '../../domain/appConfig/IAppConfig'
import { put } from "@redux-saga/core/effects"
import { AnyAction } from "@reduxjs/toolkit"
import { postman } from '../../utils/postman'
import { appConfigFailure, appConfigSuccess } from '../../store/appConfig/actions'

export function* getAppConfigSaga(action: AnyAction): any {    
    try {
        const config: IAppConfig = yield postman.get(APP_CONFIG_REQUEST)
        
        yield put(appConfigSuccess(config))
    } catch (ex: any) {
        yield put(appConfigFailure(ex.response.data))
    }
}

import React, { useEffect } from 'react'
import { Router } from 'react-router-dom'
import { useDispatchAction, useSelectorState } from '../../hooks/reduxHook'
import { useRouts } from '../../routes/AppRout'
import { Navbar } from '../../components/navbar/Navbar'
import { Segment } from 'semantic-ui-react'
import { appConfigRequest } from '../../store/appConfig/actions'
import './index.css'
import Sidebar from '../../components/navbar/Sidebar'
import history from '../../utils/historyHelper'
export const Layout: React.FC = () => {
    const isAuth = useSelectorState(state => state.auth.isAuth)
    const routs = useRouts(isAuth)
    const pages = useSelectorState(state => state.appConfig.pages)
    const dispatch = useDispatchAction()
    const getProfile = () => {
        if (!pages.length)
            dispatch(appConfigRequest())
    }

    useEffect(getProfile, [dispatch, pages])

    return (
        <Router history={history}>
            <Sidebar>
                <Segment basic>
                    {isAuth && pages && <Navbar />}
                    {(!isAuth || pages) &&
                        (
                            <div>
                                {routs}
                            </div>
                        )
                    }
                </Segment>
            </Sidebar>
        </Router>
    )
}
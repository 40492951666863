import React from "react"
import { Menu,  MenuItemProps, Icon, Label } from 'semantic-ui-react'
import { useDispatchAction, useSelectorState } from "../../hooks/reduxHook"
import { useTranslation } from 'react-i18next'
import { Media } from "../../utils/media"
import { toggleSidebar } from "../../store/navigation/actions"

export const MobileNavbar: React.FC = () => {
    const { t } = useTranslation()
    const activeItem = useSelectorState(state => state.navigation.activeItemName)
    const dispatch = useDispatchAction()

    const toggleSidebarHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
        dispatch(toggleSidebar(true))
    }

    return (
        <Media lessThan='computer'>
            <Menu size={'large'} color={'blue'} pointing className="mb-1">
                <Menu.Item onClick={toggleSidebarHandler}>
                    <Icon name='bars' />
                    <Label color='blue' floating circular content={12} />
                </Menu.Item>
                <Menu.Menu position='right'>
                    <Menu.Item active={true}>
                        {t(`${activeItem}`)}
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
        </Media>
    )
}
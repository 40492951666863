import { AnyAction } from '@reduxjs/toolkit'
import { put } from '@redux-saga/core/effects'
import { postman } from '../../../utils/postman'
import { GOODS_DELETE_REQUEST } from '../../../api'
import { deleteGoodsFailure, deleteGoodsSuccess } from '../../../store/goods/actions'

export function* deleteGoodsSaga(action: AnyAction): any {
    try {
        const url = `${GOODS_DELETE_REQUEST}/${action.payload.id}/${action.payload.type}`
        const result = yield postman.post(url)

        yield put(deleteGoodsSuccess(result))
    } catch (ex: any) {
        yield put(deleteGoodsFailure(ex.response.data))
    }
}
import { AnyAction } from 'redux'
import { IAppResult } from '../../domain/appResult/IAppResult'
import { IGoodsDto } from '../../domain/pages/goods/IGoodsDto'
import { IGoodsOrderNumberDto } from '../../domain/pages/goods/IGoodsOrderNumberDto'
import { IGoodsPayload } from '../../domain/pages/goods/IGoodsPayload'

export const FETCH_GET_GOODS_REQUEST = 'FETCH_GET_GOODS_REQUEST'
export const FETCH_GET_GOODS_SUCCESS = 'FETCH_GET_GOODS_SUCCESS'
export const FETCH_GET_GOODS_FAILURE = 'FETCH_GET_GOODS_FAILURE'

export const FETCH_DELETE_GOODS_REQUEST = 'FETCH_DELETE_GOODS_REQUEST'
export const FETCH_DELETE_GOODS_SUCCESS = 'FETCH_DELETE_GOODS_SUCCESS'
export const FETCH_DELETE_GOODS_FAILURE = 'FETCH_DELETE_GOODS_FAILURE'
export const CLEAR_DELETE_GOODS_RESULT = 'CLEAR_DELETE_GOODS_RESULT'

export const FETCH_TOGGLE_ACTIVE_GOODS_REQUEST = 'FETCH_TOGGLE_ACTIVE_GOODS_REQUEST'
export const FETCH_TOGGLE_ACTIVE_GOODS_SUCCESS = 'FETCH_TOGGLE_ACTIVE_GOODS_SUCCESS'
export const FETCH_TOGGLE_ACTIVE_GOODS_FAILURE = 'FETCH_TOGGLE_ACTIVE_GOODS_FAILURE'
export const CLEAR_TOGGLE_ACTIVE_GOODS_RESULT = 'CLEAR_TOGGLE_ACTIVE_GOODS_RESULT'

export const SET_GOODS = 'SET_GOODS'

export const UPDATE_PRODUCTS_ORDER_NUMBER = 'UPDATE_PRODUCTS_ORDER_NUMBER'

export const getGoodsRequest = (categoryId: string | null | undefined, branchId?: string | undefined): AnyAction => {
    return {
        type: FETCH_GET_GOODS_REQUEST,
        payload: {
            branchId,
            categoryId
        }
    }
}

export const getGoodsSuccess = (payload: Array<any>): AnyAction => {
    return {
        type: FETCH_GET_GOODS_SUCCESS,
        payload
    }
}

export const getGoodsFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_GET_GOODS_FAILURE,
        payload
    }
}

export const deleteGoodsRequest = (payload: IGoodsPayload): AnyAction => ({
    type: FETCH_DELETE_GOODS_REQUEST,
    payload
})

export const deleteGoodsSuccess = (payload: IAppResult): AnyAction => {
    return {
        type: FETCH_DELETE_GOODS_SUCCESS,
        payload
    }
}

export const deleteGoodsFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_DELETE_GOODS_FAILURE,
        payload
    }
}

export const toggleActiveGoodsRequest = (payload: IGoodsPayload): AnyAction => ({
    type: FETCH_TOGGLE_ACTIVE_GOODS_REQUEST,
    payload
})

export const toggleActiveGoodsSuccess = (payload: IAppResult): AnyAction => {
    return {
        type: FETCH_TOGGLE_ACTIVE_GOODS_SUCCESS,
        payload
    }
}

export const toggleActiveGoodsFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_TOGGLE_ACTIVE_GOODS_FAILURE,
        payload
    }
}

export const setGoods = (payload: Array<IGoodsDto>): AnyAction => {
    return {
        type: SET_GOODS,
        payload
    }
}

export const updateProductsOrderNumber = (payload: Array<IGoodsOrderNumberDto>) => {
    return {
        type: UPDATE_PRODUCTS_ORDER_NUMBER,
        payload
    }
}

export const clearDeleteGoodsResult = () => {
    return {
        type: CLEAR_DELETE_GOODS_RESULT
    }
}

export const clearToggleGoodsResult = () => {
    return {
        type: CLEAR_TOGGLE_ACTIVE_GOODS_RESULT
    }
}
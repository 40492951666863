import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { Segment, Header, Grid, Form, Button, Container, DropdownItemProps, DropdownProps, Message, Divider } from "semantic-ui-react"
import { BRANCHES_RESOURCE, CATEGORIES_BY_BRANCH_RESOURCE, INFORMATION_POSTS_BY_BRANCH_RESOURCE, PRODUCTS_BY_BRANCH_RESOURCE, STOCKS_BY_BRANCH_RESOURCE, TARGET_OPEN_TYPE_RESOURCE } from "../../../api"
import MultiSelectControl from "../../../components/form/dictionaries/MultiSelectControl"
import SelectControl from "../../../components/form/dictionaries/SelectControl"
import { ImageInput } from "../../../components/ImageInput/ImageInput"
import { PushNotificationPreviewDevice } from "../../../components/pushNotificationPreviewDevice/pushNotificationPreviewDevice"
import { PushNotificationPreviewDeviceType } from "../../../domain/components/pushNotificationPreviewDevice/PushNotificationPreviewDeviceType"
import { IFileDto } from "../../../domain/general/IFileDto"
import { ILookUpDto } from "../../../domain/general/ILookUpDto"
import { Pages } from "../../../domain/navigation/pages"
import { IPushNotificationDto } from "../../../domain/pages/promotion/pushNotification/IPushNotificationDto"
import { IPushNotificationPageProps } from "../../../domain/pages/promotion/pushNotification/IPushNotificationPageProps"
import { TargetOpenType } from "../../../domain/pages/promotion/pushNotification/targetOpenType"
import { useDispatchAction, useSelectorState } from "../../../hooks/reduxHook"
import { changeNavigatePage } from "../../../store/navigation/actions"
import { clearSendPushNotificationResult, sendPushNotificationRequest } from "../../../store/promotion/pushNotification/actions"
import { getResourcesRequest } from "../../../store/resources/actions"

const fieldNames = {
    id: 'id',
    subject: 'subject',
    body: 'body',
    imageId: 'imageId',
    branches: 'branches',
    targetOpen: 'targetOpen',
    targetOpenId: 'targetOpenId',
    categoriesResource: 'categoriesResource',
    productsResource: 'productsResource',
    stocksResource: 'stocksResource',
    informationPostsResource: 'informationPostsResource'
}

const defaultFormPushNotification: IPushNotificationDto = {
    id: undefined,
    subject: undefined,
    body: undefined,
    imageId: undefined,
    branches: undefined,
    targetOpen: undefined,
    targetOpenId: undefined
}

export const PushNotificationsPageBase: React.FC<IPushNotificationPageProps> = props => {
    var dispatch = useDispatchAction()
    const { t } = useTranslation()

    const isGetResourcesFetching = useSelectorState(state => state.resources.isGetResourcesFetching)
    const resources = useSelectorState(state => state.resources.resources)
    const userBranchId = useSelectorState(state => state.appConfig.userBranchId)
    const isSendPushFetching = useSelectorState(state => state.pushNotification.isSendPushNotificationFetching)
    const sendPushNotificationResult = useSelectorState(state => state.pushNotification.sendPushNotificationResult)

    const [form, setFormState] = useState<IPushNotificationDto>(defaultFormPushNotification)

    useEffect(() => {
        dispatch(changeNavigatePage(Pages.PushNotifications))
        dispatch(getResourcesRequest({ propName: fieldNames.branches, route: BRANCHES_RESOURCE }))
        dispatch(getResourcesRequest({ propName: fieldNames.targetOpen, route: TARGET_OPEN_TYPE_RESOURCE }))

        return function () {
            dispatch(clearSendPushNotificationResult())
        }
    }, [dispatch])

    useEffect(() => {
        if (userBranchId) {
            const branches: Array<ILookUpDto> = [{ name: undefined, value: userBranchId }]
            setFormState(prev => ({ ...prev, branches } as IPushNotificationDto))
        }
    }, [userBranchId])

    useEffect(() => {
        if (form?.branches && form.branches.length > 1) {
            const targetOpen: ILookUpDto = { name: undefined, value: TargetOpenType.OpenApplication.toString() }
            setFormState(prev => ({ ...prev, targetOpen, targetOpenId: undefined } as IPushNotificationDto))
        } else {
            setFormState(prev => ({ ...prev, targetOpen: undefined, targetOpenId: undefined } as IPushNotificationDto))
        }
    }, [form?.branches])

    useEffect(() => {
        switch (form?.targetOpen?.value) {
            case TargetOpenType.OpenCategory.toString():
                loadCategoriesResources()
                break
            case TargetOpenType.OpenProduct.toString():
                loadProductResources()
                break
            case TargetOpenType.OpenStock.toString():
                loadStocksResources()
                break
            case TargetOpenType.OpenInformationPost.toString():
                loadInformationPostsResources()
                break
        }
    }, [form?.targetOpen])

    const getError = (propName: string) => {
        if (!sendPushNotificationResult)
            return

        const message = sendPushNotificationResult.errors.find(p => p.name === propName)?.message

        return message
    }

    useEffect(() => {
        if (sendPushNotificationResult && sendPushNotificationResult.isError)
            toast.error(sendPushNotificationResult.message)
        else if (sendPushNotificationResult && !sendPushNotificationResult.isError) {
            toast.success(sendPushNotificationResult.message, { autoClose: 5000 })
            setFormState(defaultFormPushNotification)
        }
    }, [sendPushNotificationResult])

    const getTargetOpenOptions = (): Array<DropdownItemProps> => {
        switch (form?.targetOpen?.value) {
            case TargetOpenType.OpenCategory.toString():
                return categoriesOptions
            case TargetOpenType.OpenProduct.toString():
                return productsOptions
            case TargetOpenType.OpenStock.toString():
                return stocksOptions
            case TargetOpenType.OpenInformationPost.toString():
                return informationPostsOptions
            default:
                return []
        }
    }

    const categoriesResource = resources[fieldNames.categoriesResource]
    const categoriesOptions: Array<DropdownItemProps> = useMemo(() => categoriesResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [categoriesResource])

    const loadCategoriesResources = () => {
        if (form?.branches?.length == 1) {
            dispatch(getResourcesRequest({
                propName: fieldNames.categoriesResource,
                route: CATEGORIES_BY_BRANCH_RESOURCE,
                id: form.branches[0].value
            }))
        }
    }

    const productsResource = resources[fieldNames.productsResource]
    const productsOptions: Array<DropdownItemProps> = useMemo(() => productsResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [productsResource])

    const loadProductResources = () => {
        if (form?.branches?.length == 1) {
            dispatch(getResourcesRequest({
                propName: fieldNames.productsResource,
                route: PRODUCTS_BY_BRANCH_RESOURCE,
                id: form.branches[0].value
            }))
        }
    }

    const stocksResource = resources[fieldNames.stocksResource]
    const stocksOptions: Array<DropdownItemProps> = useMemo(() => stocksResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [stocksResource])

    const loadStocksResources = () => {
        if (form?.branches?.length == 1) {
            dispatch(getResourcesRequest({
                propName: fieldNames.stocksResource,
                route: STOCKS_BY_BRANCH_RESOURCE,
                id: form.branches[0].value
            }))
        }
    }

    const informationPostsResource = resources[fieldNames.informationPostsResource]
    const informationPostsOptions: Array<DropdownItemProps> = useMemo(() => informationPostsResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [informationPostsResource])

    const loadInformationPostsResources = () => {
        if (form?.branches?.length == 1) {
            dispatch(getResourcesRequest({
                propName: fieldNames.informationPostsResource,
                route: INFORMATION_POSTS_BY_BRANCH_RESOURCE,
                id: form.branches[0].value
            }))
        }
    }

    const branchResource = resources[fieldNames.branches]
    const branchOptions: Array<DropdownItemProps> = useMemo(() => branchResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [branchResource])

    const targetOpenResource = resources[fieldNames.targetOpen]
    const targetOpenOptions: Array<DropdownItemProps> = useMemo(() => targetOpenResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [targetOpenResource])

    const isLoading = () => isGetResourcesFetching || isSendPushFetching

    const changeInputHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFormState((prev: any) => ({ ...prev, [event.target.name]: event.target.value || undefined }))
    }

    const changeSelectHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        if (event.type !== 'click')
            return

        const item: ILookUpDto | null = resources[data.name]
            ?.find(p => p.value === data.value) ?? null

        if (data.name == fieldNames.targetOpen) {
            setFormState((prev: any) => ({ ...prev, [data.name as string]: item, [fieldNames.targetOpenId]: undefined }))
        } else {
            setFormState((prev: any) => ({ ...prev, [data.name as string]: item }))
        }
    }

    const changeTargetOpenIdHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        if (event.type !== 'click')
            return

        setFormState((prev: any) => ({ ...prev, [fieldNames.targetOpenId]: data.value }))
    }

    const changeMultiSelectHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const items: Array<ILookUpDto> = resources[data.name]
            ?.map(p => ({ name: p.name, value: p.value }))
            .filter(p => (data.value as Array<string>).includes(p.value))
        setFormState((prev: any) => ({ ...prev, [data.name as string]: items.length ? items : null }))
    }

    const changeImageHandler = (image: IFileDto | null) => {
        setFormState((prev: any) => ({ ...prev, [fieldNames.imageId]: image }))
    }

    const getBranchesValueFromState = () => {
        return form?.branches?.map((p: any) => p.value.toString()) || []
    }

    const isDisabledTargetOpenControl = () => {
        return !form?.branches
            || form.branches.length > 1
    }

    const isDisabledTargetOpenIdControl = () => {
        return !form?.targetOpen
            || form?.targetOpen?.value == TargetOpenType.OpenApplication.toString()
    }

    const sendPushNotificationHandler = () => {
        form && dispatch(sendPushNotificationRequest(form))
    }

    return (
        <div className='container'>
            <Segment attached='top'>
                <Header as='h3' className={'segmentHeader'}>{t(Pages.PushNotifications)}</Header>
            </Segment>
            <Segment
                attached='bottom'
                loading={isLoading()}
                className={'containerWrapperWithMinSizeForLoadingIndicator'}>
                <Grid stackable columns={props.showPreviewPushNotificationBlock ? 2 : 1}>
                    <Grid.Column>
                        <Segment attached='top'>
                            <Header as='h5' className={'segmentHeader'}>Конструктор уведомления</Header>
                        </Segment>
                        <Segment attached='bottom'>
                            <Form noValidate='novalidate'>
                                <Form.Group widths='equal'>
                                    <Form.TextArea
                                        required
                                        value={form?.subject || ''}
                                        type='text'
                                        name={fieldNames.subject}
                                        onChange={changeInputHandler}
                                        label={t('pushNotification.subject')}
                                        placeholder={t('pushNotification.subject.placeholder')}
                                        error={getError(fieldNames.subject)}
                                    />
                                    <Form.TextArea
                                        required
                                        value={form?.body || ''}
                                        type='text'
                                        name={fieldNames.body}
                                        onChange={changeInputHandler}
                                        label={t('pushNotification.body')}
                                        placeholder={t('pushNotification.body.placeholder')}
                                        error={getError(fieldNames.body)}
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <ImageInput
                                        value={form?.imageId}
                                        aspectRatio={1}
                                        onChange={changeImageHandler}
                                    />
                                    <MultiSelectControl
                                        disabled={!!userBranchId}
                                        required
                                        label={t('pushNotification.branches')}
                                        name={fieldNames.branches}
                                        placeholder={t('pushNotification.branches.placeholder')}
                                        value={getBranchesValueFromState()}
                                        options={branchOptions}
                                        onChange={changeMultiSelectHandler}
                                        error={getError(fieldNames.branches)}
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <SelectControl
                                        disabled={isDisabledTargetOpenControl()}
                                        required
                                        label={t('pushNotification.targetOpen')}
                                        name={fieldNames.targetOpen}
                                        placeholder={t('pushNotification.targetOpen.placeholder')}
                                        value={form?.targetOpen?.value || ''}
                                        options={targetOpenOptions}
                                        onChange={changeSelectHandler}
                                        error={getError(fieldNames.targetOpen)}
                                    />
                                    <SelectControl
                                        disabled={isDisabledTargetOpenIdControl()}
                                        required
                                        label={t('pushNotification.targetOpenId')}
                                        name={fieldNames.targetOpenId}
                                        placeholder={t('pushNotification.targetOpenId.placeholder')}
                                        value={form?.targetOpenId || ''}
                                        options={getTargetOpenOptions()}
                                        onChange={changeTargetOpenIdHandler}
                                        error={getError(fieldNames.targetOpenId)}
                                    />
                                </Form.Group>
                                <div className="container-flex-content-right">
                                    <Form.Button
                                        primary
                                        disabled={isLoading()}
                                        onClick={sendPushNotificationHandler}>
                                        {t('pushNotification.send')}
                                    </Form.Button>
                                </div>
                            </Form>
                        </Segment>
                    </Grid.Column>
                    {
                        props.showPreviewPushNotificationBlock &&
                        <Grid.Column>
                        <Segment attached='top'>
                            <Header as='h5' className={'segmentHeader'}>Предпросмотр на устройстве</Header>
                        </Segment>
                        <Segment attached='bottom'>
                            <Message size='tiny'>
                                {t('pushPreviewInfoMessage')}
                            </Message>
                            <Grid>
                                <Grid.Row centered>
                                    <PushNotificationPreviewDevice
                                        type={PushNotificationPreviewDeviceType.Android}
                                        subject={form.subject}
                                        body={form.body}
                                        imageUrl={form.imageId?.path} />
                                </Grid.Row>
                                <Grid.Row centered>
                                    <PushNotificationPreviewDevice
                                        type={PushNotificationPreviewDeviceType.Ios}
                                        subject={form.subject}
                                        body={form.body}
                                        imageUrl={form.imageId?.path} />
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                    }
                </Grid >
            </Segment >
        </div >)
}
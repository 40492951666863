import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Checkbox, CheckboxProps, Dimmer, DropdownItemProps, DropdownProps, Form, Header, Input, Loader, Radio, Segment, Table } from 'semantic-ui-react'
import { FooterMenu } from '../../components/footerMenu/FooterMenu'
import { useDispatchAction, useSelectorState } from '../../hooks/reduxHook'
import './index.css'
import { IGoodsFormMatchParams } from '../../domain/navigation/IGoodsFormMatchParams'
import { changeNavigatePage } from '../../store/navigation/actions'
import { Pages } from '../../domain/navigation/pages'
import { getGoodsRequest } from '../../store/goods/actions'
import { getResourcesRequest } from '../../store/resources/actions'
import { BRANCHES_RESOURCE, CATEGORIES_RESOURCE, PRODUCT_ADDITIONAL_OPTIONS_RESOURCE, PRODUCT_MODIFICATIONS_RESOURCE } from '../../api'
import { ILookUpDto } from '../../domain/general/ILookUpDto'
import { IGoodsDto } from '../../domain/pages/goods/IGoodsDto'
import { ImageInput } from '../../components/ImageInput/ImageInput'
import { IFileDto } from '../../domain/general/IFileDto'
import { IProductDto, ModificationDto } from '../../domain/pages/goods/product/IProductDto'
import { clearProductForm, clearProductModifications, clearProductSavingResult, getProductByIdRequest, getProductModificationsRequest, saveProductRequest } from '../../store/product/form/actions'
import MultiSelectWithOrderControl from '../../components/form/dictionaries/MultiSelectWithOrderControl'
import { reorder } from '../../utils/arrayHelper'
import { ProductModificationsPayload } from '../../domain/pages/goods/product/IProductFormState'

const fieldNames = {
    name: 'name',
    branchId: 'branchId',
    cost: 'cost',
    isAvailablePromotion: 'isAvailablePromotion',
    isActive: 'isActive',
    quantityInfo: 'quantityInfo',
    integrationId: 'integrationId',
    description: 'description',
    parentCategoryId: 'parentCategoryId',
    additionalOptions: 'additionalOptions',
    productModifications: 'productModifications',
    isDefault: 'isDefault'
}

export const ProductFormPage: React.FC<RouteComponentProps<IGoodsFormMatchParams>> = props => {
    const dispatch = useDispatchAction()
    const history = useHistory()
    const { t } = useTranslation()
    const isGetProductByIdFetching = useSelectorState(state => state.productForm.isGetProductByIdFetching)
    const isProductFormSaveFetching = useSelectorState(state => state.productForm.isProductFormSaveFetching)
    const isGetProductModificationsFetching = useSelectorState(state => state.productForm.isGetProductModificationsFetching)
    const productModificationsFromStore = useSelectorState(state => state.productForm.productModifications)
    const currentCategoryContainer = useSelectorState(state => state.goods.currentCategory)
    const userBranchId = useSelectorState(state => state.appConfig.userBranchId)
    const userDynamicBranchId = useSelectorState(state => state.appConfig.userDynamicBranchId)
    const form = useSelectorState(state => state.productForm.form)
    const [formState, setFormState] = useState<IProductDto>()
    const isGetResourcesFetching = useSelectorState(state => state.resources.isGetResourcesFetching)
    const resources = useSelectorState(state => state.resources.resources)
    const error = useSelectorState(state => state.productForm.error)
    const saveResult = useSelectorState(state => state.productForm.productFormSavingResult)

    const setFormStateFromStore = () => setFormState(form || undefined)
    const clearProductValidationResult = () => dispatch(clearProductSavingResult())

    const getHeaderText = () => {
        if (props.match.params.id)
            return `${t('productEditRecordHeader')}`
        else
            return `${t('productNewRecordHeader')}`
    }

    const cancelHandler = () => {
        clearProductValidationResult()
        history.goBack()
    }

    useEffect(() => {
        dispatch(changeNavigatePage(Pages.Goods))
        dispatch(getProductByIdRequest(props.match.params.id))
        dispatch(getGoodsRequest(props.match.params.containerCategoryId ?? null))
        dispatch(getResourcesRequest({ propName: fieldNames.branchId, route: BRANCHES_RESOURCE }))
        dispatch(getResourcesRequest({ propName: fieldNames.parentCategoryId, route: CATEGORIES_RESOURCE }))
        dispatch(getResourcesRequest({ propName: fieldNames.additionalOptions, route: PRODUCT_ADDITIONAL_OPTIONS_RESOURCE }))
        dispatch(getResourcesRequest({ propName: fieldNames.productModifications, route: PRODUCT_MODIFICATIONS_RESOURCE }))

        return function () {
            clearProductValidationResult()
            dispatch(clearProductForm())
            dispatch(clearProductModifications())
        }
    }, [dispatch])

    const setParentCategory = (currentCategoryContainer: IGoodsDto | null) => {
        setFormState((prev: any) => ({
            ...prev, parentCategoryId: currentCategoryContainer ? {
                value: currentCategoryContainer.id,
                name: undefined
            } : null
        }))
    }

    useEffect(() => {
        setParentCategory(currentCategoryContainer)
    }, [currentCategoryContainer])

    useEffect(() => {
        setFormStateFromStore()
        setParentCategory(currentCategoryContainer)
    }, [form])

    useEffect(() => {
        if (saveResult && saveResult.isError)
            toast.error(saveResult.message)
        else if (saveResult && !saveResult.isError) {
            toast.success(saveResult.message, { autoClose: 5000 })
            history.goBack()
        }
    }, [saveResult])

    useEffect(() => {
        if (error)
            toast.error(error)
    }, [error])

    const saveHandler = () => {
        let formForSave = formState as IProductDto

        if (currentCategoryContainer || userBranchId || userDynamicBranchId) {
            formForSave = {
                ...formForSave,
                branchId: {
                    value: (currentCategoryContainer?.branchId || userBranchId || userDynamicBranchId) as string,
                    name: undefined
                }
            }
        }

        dispatch(saveProductRequest(formForSave))
    }

    const branchResource = resources[fieldNames.branchId]
    const branchOptions: Array<DropdownItemProps> = useMemo(() => branchResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [branchResource])
    const categoryResource = resources[fieldNames.parentCategoryId]
    const categoryOptions: Array<DropdownItemProps> = useMemo(() => categoryResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [categoryResource])
    const additionalOptionResource = resources[fieldNames.additionalOptions]
    const productAdditionalOptions: Array<DropdownItemProps> = useMemo(() => additionalOptionResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [additionalOptionResource])

    const productModificationResource = resources[fieldNames.productModifications]
    const productModifications: Array<DropdownItemProps> = useMemo(() => productModificationResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [productModificationResource])

    const changeSelectHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        if (event.type !== 'click')
            return

        const item: ILookUpDto | null = resources[data.name]
            ?.find(p => p.value === data.value) ?? null
        setFormState((prev: any) => ({ ...prev, [data.name as string]: item }))
    }

    useEffect(() => {
        if (!formState)
            return

        onChangeModification()
    }, [formState?.productModifications])

    useEffect(() => {
        setFormState(prev => ({ ...prev, modifications: productModificationsFromStore } as IProductDto))
    }, [productModificationsFromStore])

    const onChangeModification = () => {
        if (formState?.productModifications && formState?.productModifications.length) {
            const payload: ProductModificationsPayload = {
                id: formState.id,
                modificationIds: formState.productModifications.map(p => p.value)
            }
            dispatch(getProductModificationsRequest(payload))
        } else {
            dispatch(clearProductModifications())
        }
    }

    const changeMultiSelectHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const items: Array<ILookUpDto> = resources[data.name]
            ?.map(p => ({ name: p.name, value: p.value }))
            .filter(p => (data.value as Array<string>).includes(p.value))
        setFormState((prev: any) => ({ ...prev, [data.name as string]: items.length ? items : null }))
    }

    const changeInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormState((prev: any) => ({ ...prev, [event.target.name]: event.target.value || null }))
    }

    const changeTextAreaHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFormState((prev: any) => ({ ...prev, [event.target.name]: event.target.value || null }))
    }

    const changeRadioHandler = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        setFormState((prev: any) => ({ ...prev, [data.name as string]: data.checked }))
    }

    const changeInputModificationHandler = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        setFormState(prev => {
            const modifications = [...prev!.modifications as Array<ModificationDto>]
            modifications[index] = { ...modifications[index], [event.target.name]: event.target.value || null }

            return { ...prev, modifications } as IProductDto
        })
    }

    const changeCheckboxModificationHandler = (data: CheckboxProps, index: number) => {
        setFormState(prev => {
            const propName = data.name as string
            let modifications = [...prev!.modifications as Array<ModificationDto>]

            if (propName == fieldNames.isDefault)
                modifications = modifications.map(p => ({ ...p, [fieldNames.isDefault]: false }))

            modifications[index] = { ...modifications[index], [propName]: data.checked }

            return { ...prev, modifications } as IProductDto
        })
    }

    const getError = (propName: string) => {
        if (!saveResult)
            return

        const message = saveResult.errors.find(p => p.name === propName)?.message

        return message
    }

    const isModificationError = (propName: string, index: number) => {
        if (!saveResult)
            return

        const name = `${propName}_i_${index}`
        const message = saveResult.errors.find(p => p.name === name)?.message

        return !!message
    }

    const changeImageHandler = (image: IFileDto | null) => {
        setFormState((prev: any) => ({ ...prev, imageId: image }))
    }

    const getAdditionalOptionSelected = () => {
        if (formState?.additionalOptions?.length)
            return formState.additionalOptions.map(p => p.value)

        return []
    }

    const getProductModificationSelected = () => {
        if (formState?.productModifications?.length)
            return formState.productModifications.map(p => p.value)

        return []
    }

    const isExistProductModificationSelected = () => !!formState?.productModifications?.length

    const dragEndHandler = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }, propName: string) => {
        const selectedItems = reorder(
            (formState as any)[propName],
            oldIndex,
            newIndex
        )
        setFormState((prev: any) => ({ ...prev, [propName]: selectedItems }))
    }

    return (
        <div className='containerWithFooterMenu'>
            <Segment attached='top'>
                <Header as='h3' className={'segmentHeader'}>
                    {getHeaderText()}
                </Header>
            </Segment>
            <Segment
                attached='bottom'
                loading={isGetProductByIdFetching || isGetResourcesFetching || isProductFormSaveFetching}
            >
                <Form>
                    <div className={'container-grid-auto-fit'}>
                        <Form.Input
                            required
                            value={formState?.name || ''}
                            onChange={changeInputHandler}
                            label={t('product.name')}
                            name={fieldNames.name}
                            placeholder={t('product.name.placeholder')}
                            error={getError(fieldNames.name)}
                        />
                        {
                            (!currentCategoryContainer && !userBranchId && !userDynamicBranchId) &&
                            <Form.Dropdown
                                required
                                clearable
                                selection fluid search
                                value={formState?.branchId?.value || undefined}
                                options={branchOptions}
                                onChange={changeSelectHandler}
                                name={fieldNames.branchId}
                                label={t('branch')}
                                noResultsMessage={t('dropdownNotResultMessage')}
                                error={getError(fieldNames.branchId)}
                            />
                        }
                        <Form.Input
                            required
                            value={formState?.cost === null ? '' : formState?.cost}
                            type="number"
                            onChange={changeInputHandler}
                            label={t('product.cost')}
                            name={fieldNames.cost}
                            placeholder={t('product.cost.placeholder')}
                            error={getError(fieldNames.cost)}
                        />
                        <Form.Input
                            required
                            value={formState?.quantityInfo || ''}
                            onChange={changeInputHandler}
                            label={t('product.quantityInfo')}
                            name={fieldNames.quantityInfo}
                            placeholder={t('product.quantityInfo.placeholder')}
                            error={getError(fieldNames.quantityInfo)}
                        />
                        <ImageInput
                            value={formState?.imageId || null}
                            aspectRatio={1}
                            onChange={changeImageHandler}
                        />
                        <Form.Input
                            value={formState?.integrationId || ''}
                            disabled={isExistProductModificationSelected()}
                            onChange={changeInputHandler}
                            label={t(fieldNames.integrationId)}
                            name={fieldNames.integrationId}
                            placeholder={t('placeholder.integrationId')}
                            error={getError(fieldNames.integrationId)}
                        />
                        <Form.TextArea
                            value={formState?.description || ''}
                            onChange={changeTextAreaHandler}
                            label={t('product.description')}
                            name={fieldNames.description}
                            placeholder={t('product.description.placeholder')}
                            error={getError(fieldNames.description)}
                        />
                        <MultiSelectWithOrderControl
                            required={false}
                            disabled={!productAdditionalOptions || !productAdditionalOptions.length}
                            value={getAdditionalOptionSelected()}
                            draggableValue={formState?.additionalOptions || new Array<ILookUpDto>()}
                            options={productAdditionalOptions}
                            onChange={changeMultiSelectHandler}
                            onDragEnd={dragEndHandler}
                            name={fieldNames.additionalOptions}
                            label={t('productAdditionalOptions')}
                            error={getError(fieldNames.additionalOptions)}
                        />
                        <MultiSelectWithOrderControl
                            required={false}
                            disabled={!productModifications || !productModifications.length}
                            value={getProductModificationSelected()}
                            draggableValue={formState?.productModifications || new Array<ILookUpDto>()}
                            options={productModifications}
                            onChange={changeMultiSelectHandler}
                            onDragEnd={dragEndHandler}
                            name={fieldNames.productModifications}
                            label={t(fieldNames.productModifications)}
                            error={getError(fieldNames.additionalOptions)}
                        />
                        <Form.Dropdown
                            clearable
                            selection fluid search
                            value={formState?.parentCategoryId?.value || ''}
                            options={categoryOptions}
                            disabled={!categoryOptions || !categoryOptions.length}
                            onChange={changeSelectHandler}
                            name={fieldNames.parentCategoryId}
                            label={t(fieldNames.parentCategoryId)}
                            noResultsMessage={t('dropdownNotResultMessage')}
                            error={getError(fieldNames.parentCategoryId)}
                            placeholder={t('noParentCategory.placeholder')}
                        />
                        <Form.Group grouped>
                            <label>{t('product.isAvailablePromotion')}</label>
                            <Form.Radio
                                name={fieldNames.isAvailablePromotion}
                                toggle
                                className={'mt-03'}
                                checked={formState?.isAvailablePromotion}
                                onChange={changeRadioHandler}
                            />
                        </Form.Group>
                        <Form.Group grouped>
                            <label>{t(fieldNames.isActive)}</label>
                            <Form.Radio
                                name={fieldNames.isActive}
                                toggle
                                className={'mt-03'}
                                checked={formState?.isActive}
                                onChange={changeRadioHandler}
                            />
                        </Form.Group>
                    </div>
                </Form>
                <Segment basic loading={isGetProductModificationsFetching} className='segmentNonHorizontalPadding containerWrapper'>
                    {
                        !!formState?.modifications &&
                        <Table celled unstackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{t('modifications')}</Table.HeaderCell>
                                    <Table.HeaderCell>{`${t('product.cost')}*`}</Table.HeaderCell>
                                    <Table.HeaderCell>{`${t('product.quantityInfo')}*`}</Table.HeaderCell>
                                    <Table.HeaderCell>{t(fieldNames.integrationId)}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('modification.defaultSelector')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t(fieldNames.isActive)}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    formState.modifications.map((modification, i) => {
                                        return (
                                            <Table.Row key={`modification_${i}`}>
                                                <Table.Cell>{modification.name}</Table.Cell>
                                                <Table.Cell>
                                                    <Input
                                                        size='mini'
                                                        required
                                                        value={modification.cost === null ? '' : modification.cost}
                                                        type="number"
                                                        onChange={event => changeInputModificationHandler(event, i)}
                                                        name={fieldNames.cost}
                                                        placeholder={t('product.cost.placeholder')}
                                                        error={isModificationError(fieldNames.cost, i)} />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Input
                                                        size='mini'
                                                        required
                                                        value={modification.quantityInfo || ''}
                                                        onChange={event => changeInputModificationHandler(event, i)}
                                                        name={fieldNames.quantityInfo}
                                                        placeholder={t('product.quantityInfo.placeholder')}
                                                        error={isModificationError(fieldNames.quantityInfo, i)} />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Input
                                                        size='mini'
                                                        value={modification.integrationId || ''}
                                                        onChange={event => changeInputModificationHandler(event, i)}
                                                        name={fieldNames.integrationId}
                                                        placeholder={t('placeholder.integrationId')}
                                                        error={isModificationError(fieldNames.integrationId, i)} />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Radio
                                                        name={fieldNames.isDefault}
                                                        toggle
                                                        checked={modification.isDefault}
                                                        disabled={modification.isDefault}
                                                        onChange={(_, data) => changeCheckboxModificationHandler(data, i)}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Checkbox
                                                        name={fieldNames.isActive}
                                                        toggle
                                                        checked={modification.isActive}
                                                        onChange={(_, data) => changeCheckboxModificationHandler(data, i)}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })
                                }
                            </Table.Body>
                        </Table>
                    }
                </Segment>

            </Segment>
            <FooterMenu
                onCancel={cancelHandler}
                onSave={saveHandler}
                cancelDisabled={isGetProductByIdFetching || isGetResourcesFetching || isProductFormSaveFetching}
                saveDisabled={isGetProductByIdFetching || isGetResourcesFetching || isProductFormSaveFetching}
            />
        </div>)
}
import { getAffiliateProgramsSuccess, getAffiliateProgramsFailure } from './../../../../store/promotion/affiliatePrograms/actions';
import { AnyAction } from '@reduxjs/toolkit'
import { put } from '@redux-saga/core/effects'
import { postman } from '../../../../utils/postman'
import { GET_AFFILIATE_PROGRAMS } from '../../../../api'

export function* getItemsSaga(action: AnyAction): any {
    try {
        const url = GET_AFFILIATE_PROGRAMS
        const items = yield postman.post(url)

        yield put(getAffiliateProgramsSuccess(items))
    } catch (ex: any) {
        yield put(getAffiliateProgramsFailure(ex.response.data))
    }
}
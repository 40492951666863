import React, { useEffect, useState } from "react"
import { Route, Redirect, Switch } from 'react-router-dom'
import { Pages } from "../domain/navigation/pages"
import { useSelectorState } from "../hooks/reduxHook"
import { DictionaryFormPage } from "../pages/dictionaries/DictionaryFormPage"
import { DictionaryTablePage } from "../pages/dictionaries/DictionaryTablePage"
import { OrdersPage } from "../pages/orders/OrdersPage"
import { GoodsPage } from "../pages/goods/GoodsPage"
import { ProfilePage } from "../pages/profile/ProfilePage"
import { SettingsPage } from "../pages/settings/SettingsPage"
import { CategoryFormPage } from "../pages/goods/CategoryFormPage"
import { ProductFormPage } from "../pages/goods/ProductFormPage"
import { PromocodesPage } from "../pages/promotion/promocodes/PromocodesPage"
import { PromocodesFormPage } from "../pages/promotion/promocodes/PromocodesFormPage"
import { NewsPage } from "../pages/promotion/news/NewsPage"
import { StocksPage } from "../pages/promotion/stocks/StocksPage"
import { StocksFormPage } from "../pages/promotion/stocks/StocksFormPage"
import { AffiliateProgramsFormPage } from "../pages/promotion/affiliatePrograms/AffiliateProgramsFormPage"
import { AffiliateProgramsPage } from "../pages/promotion/affiliatePrograms/AffiliateProgramsPage"
import { PushNotificationsPage } from "../pages/promotion/pushNotification/PushNotificationsPage"

export const MainRouts: React.FC = () => {
    const pages = useSelectorState(state => state.appConfig.pages)
    const dictionaries = useSelectorState(state => state.appConfig.dictionaries)
    const [homePage, setHomePage] = useState('')

    useEffect(() => {
        let defPage = ''
        if ((pages.length && pages[0] != Pages.Profile)
            || (pages.length && !dictionaries.length))
            defPage = pages[0]
        else if (dictionaries.length)
            defPage = `/dictionaries/${dictionaries[0].name}`

        setHomePage(defPage)
    }, [pages, dictionaries])

    return (
        <Switch>
            <Route path={`/${Pages.Orders}`} exact>
                <OrdersPage />
            </Route>
            <Route path={`/${Pages.Goods}/:id?`} exact component={GoodsPage} />
            <Route path={[
                "/category/:containerCategoryId?/new",
                "/category/:containerCategoryId?/:id"]} exact component={CategoryFormPage} />
            <Route path={[
                "/product/:containerCategoryId?/new",
                "/product/:containerCategoryId?/:id"]} exact component={ProductFormPage} />
            <Route path={`/${Pages.Settings}`} exact>
                <SettingsPage />
            </Route>
            <Route path="/dictionaries/:dictionaryName" exact component={DictionaryTablePage} />
            <Route path={[
                "/dictionaries/:dictionaryName/new",
                "/dictionaries/:dictionaryName/:id"]} exact component={DictionaryFormPage} />
            <Route path={`/${Pages.Promocodes}`} exact component={PromocodesPage} />
            <Route path={[
                `/${Pages.Promocodes}/new`,
                `/${Pages.Promocodes}/:id`]} exact component={PromocodesFormPage} />
            <Route path={`/${Pages.Stocks}`} exact component={StocksPage} />
            <Route path={[
                `/${Pages.Stocks}/new`,
                `/${Pages.Stocks}/:id`]} exact component={StocksFormPage} />
            <Route path={`/${Pages.InformationPosts}`} exact component={NewsPage} />
            <Route path={`/${Pages.AffiliatePrograms}`} exact component={AffiliateProgramsPage} />
            <Route path={[
                `/${Pages.AffiliatePrograms}/new`,
                `/${Pages.AffiliatePrograms}/:id`]} exact component={AffiliateProgramsFormPage} />
            <Route path={`/${Pages.PushNotifications}`} exact>
                <PushNotificationsPage />
            </Route>
            <Route path={`/${Pages.Profile}`} exact>
                <ProfilePage />
            </Route>
            <Redirect to={homePage} />
        </Switch>
    )
}
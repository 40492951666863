import { AnyAction } from "@reduxjs/toolkit"
import { put } from '@redux-saga/core/effects'
import { SAVE_PROMOCODE } from "../../../../api"
import { IValidationResult } from "../../../../domain/validationResult/IValidationResult"
import { savePromocodeFormSuccess, savePromocodeFormFailure } from "../../../../store/promotion/promocodes/actions"
import { postman } from "../../../../utils/postman"

export function* savePromocodeFormSaga(action: AnyAction): any {    
    try {
        const form = action.payload
        const url = SAVE_PROMOCODE
        const result: IValidationResult = yield postman.post(url, form)
        
        yield put(savePromocodeFormSuccess(result))
    } catch (ex: any) {
        yield put(savePromocodeFormFailure(ex.response.data))
    }
}
import { CLEAR_PROFILE_SAVING_RESULT, FETCH_LOAD_PROFILE_FAILURE, FETCH_LOAD_PROFILE_REQUEST, FETCH_LOAD_PROFILE_SUCCESS, FETCH_SAVE_PROFILE_FAILURE, FETCH_SAVE_PROFILE_REQUEST, FETCH_SAVE_PROFILE_SUCCESS } from './actions'
import { IProfileState } from './../../domain/pages/profile/IProfileState'
import { AnyAction } from '@reduxjs/toolkit'

const initialState: IProfileState = {
    error: '',
    isProfileFetching: false,
    isProfileSaveFetching: false,
    profileSavingResult: null,
    form: {
        name: null,
        email: null,
        phone: null,
        role: null,
        password: null,
        newPassword: null,
        repeatNewPassword: null,
    }
}

export const profileReducer = (state = initialState, action: AnyAction): IProfileState => {
    switch (action.type) {
        case FETCH_LOAD_PROFILE_REQUEST:
            return {
                ...state,
                isProfileFetching: true,
                error: '',
                profileSavingResult: null
            }
        case FETCH_LOAD_PROFILE_SUCCESS:
            return {
                ...state,
                form: {
                    ...state.form,
                    ...action.payload
                },
                isProfileFetching: false,
            }
        case FETCH_LOAD_PROFILE_FAILURE:
            return {
                ...state,
                isProfileFetching: false,
                error: action.payload
            }
        case FETCH_SAVE_PROFILE_REQUEST:
            return {
                ...state,
                isProfileSaveFetching: true,
                error: '',
                profileSavingResult: null
            }
        case FETCH_SAVE_PROFILE_SUCCESS:
            return {
                ...state,
                profileSavingResult: action.payload,
                isProfileSaveFetching: false,
            }
        case FETCH_SAVE_PROFILE_FAILURE:
            return {
                ...state,
                isProfileSaveFetching: false,
                error: action.payload
            }
        case CLEAR_PROFILE_SAVING_RESULT: {
            return {
                ...state,
                profileSavingResult: null
            }
        }
        default: return state
    }
}
import React from "react"
import { Grid, Header, } from "semantic-ui-react"
import './index.css'
import { useTranslation } from 'react-i18next'

export const LoginHeader: React.FC = () => {
    const { t } = useTranslation()

    return (
        <Grid.Column>
            <div className='auth-header'>
                <div className='auth-header-body'>
                    <Header
                        as='h2'
                        content={t('loginPageHeader')}
                        subheader={t('loginPageSubHeader')}
                    />
                </div>
            </div>
        </Grid.Column>
    )
}
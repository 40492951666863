import { AnyAction } from 'redux'
import { IAppResult } from '../../../domain/appResult/IAppResult'
import { IStockDto } from '../../../domain/pages/promotion/stocks/IStockDto'
import { IValidationResult } from '../../../domain/validationResult/IValidationResult'

export const FETCH_GET_STOCKS_REQUEST = 'FETCH_GET_STOCKS_REQUEST'
export const FETCH_GET_STOCKS_SUCCESS = 'FETCH_GET_STOCKS_SUCCESS'
export const FETCH_GET_STOCKS_FAILURE = 'FETCH_GET_STOCKS_FAILURE'

export const FETCH_GET_BY_ID_STOCK_REQUEST = 'FETCH_GET_BY_ID_STOCK_REQUEST'
export const FETCH_GET_BY_ID_STOCK_SUCCESS = 'FETCH_GET_BY_ID_STOCK_SUCCESS'
export const FETCH_GET_BY_ID_STOCK_FAILURE = 'FETCH_GET_BY_ID_STOCK_FAILURE'

export const FETCH_SAVE_FORM_STOCK_REQUEST = 'FETCH_SAVE_FORM_STOCK_REQUEST'
export const FETCH_SAVE_FORM_STOCK_SUCCESS = 'FETCH_SAVE_FORM_STOCK_SUCCESS'
export const FETCH_SAVE_FORM_STOCK_FAILURE = 'FETCH_SAVE_FORM_STOCK_FAILURE'
export const CLEAR_STOCK_FORM_SAVING_RESULT = 'CLEAR_STOCK_FORM_SAVING_RESULT'

export const FETCH_DELETE_STOCK_REQUEST = 'FETCH_DELETE_STOCK_REQUEST'
export const FETCH_DELETE_STOCK_SUCCESS = 'FETCH_DELETE_STOCK_SUCCESS'
export const FETCH_DELETE_STOCK_FAILURE = 'FETCH_DELETE_STOCK_FAILURE'

export const SET_STOCKS = 'SET_STOCKS'

export const FETCH_TOGGLE_STOCK_REQUEST = 'FETCH_TOGGLE_STOCK_REQUEST'
export const FETCH_TOGGLE_STOCK_SUCCESS = 'FETCH_TOGGLE_STOCK_SUCCESS'
export const FETCH_TOGGLE_STOCK_FAILURE = 'FETCH_TOGGLE_STOCK_FAILURE'

export const CLEAR_DELETE_STOCK_RESULT = 'CLEAR_DELETE_STOCK_RESULT'
export const CLEAR_TOGGLE_STOCK_RESULT = 'CLEAR_TOGGLE_STOCK_RESULT'

export const getStocksRequest = (): AnyAction => {
    return {
        type: FETCH_GET_STOCKS_REQUEST
    }
}

export const getStocksSuccess = (payload: Array<IStockDto>): AnyAction => {
    return {
        type: FETCH_GET_STOCKS_SUCCESS,
        payload
    }
}

export const getStocksFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_GET_STOCKS_FAILURE,
        payload
    }
}

/**
 * 
 * @param payload stock id
 * @returns 
 */
export const getByIdStockRequest = (payload: string | undefined): AnyAction => {
    return {
        type: FETCH_GET_BY_ID_STOCK_REQUEST,
        payload
    }
}

export const getByIdStockSuccess = (payload: IStockDto): AnyAction => {
    return {
        type: FETCH_GET_BY_ID_STOCK_SUCCESS,
        payload
    }
}

export const getByIdStockFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_GET_BY_ID_STOCK_FAILURE,
        payload
    }
}

export const saveStockFormRequest = (payload: IStockDto): AnyAction => {
    return {
        type: FETCH_SAVE_FORM_STOCK_REQUEST,
        payload
    }
}

export const saveStockFormSuccess = (payload: IValidationResult): AnyAction => {
    return {
        type: FETCH_SAVE_FORM_STOCK_SUCCESS,
        payload
    }
}

export const saveStockFormFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_SAVE_FORM_STOCK_FAILURE,
        payload
    }
}

export const clearStockFormSavingResult = (): AnyAction => {
    return {
        type: CLEAR_STOCK_FORM_SAVING_RESULT
    }
}

export const deleteStockRequest = (stockId: string): AnyAction => {
    return {
        type: FETCH_DELETE_STOCK_REQUEST,
        payload: stockId
    }
}

export const deleteStockSuccess = (payload: IAppResult): AnyAction => {
    return {
        type: FETCH_DELETE_STOCK_SUCCESS,
        payload
    }
}

export const deleteStockFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_DELETE_STOCK_FAILURE,
        payload
    }
}

export const setStocks = (stock: Array<IStockDto>): AnyAction => {
    return {
        type: SET_STOCKS,
        payload: stock
    }
}

export const toggleStockRequest = (stockId: string): AnyAction => {
    return {
        type: FETCH_TOGGLE_STOCK_REQUEST,
        payload: stockId
    }
}

export const toggleStockSuccess = (payload: IAppResult): AnyAction => {
    return {
        type: FETCH_TOGGLE_STOCK_SUCCESS,
        payload
    }
}

export const toggleStockFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_TOGGLE_STOCK_FAILURE,
        payload
    }
}

export const clearDeleteStockResult = () => {
    return {
        type: CLEAR_DELETE_STOCK_RESULT
    }
}

export const clearToggleStockResult = () => {
    return {
        type: CLEAR_TOGGLE_STOCK_RESULT
    }
}

import { AnyAction } from "@reduxjs/toolkit"
import { put } from '@redux-saga/core/effects'
import { postman } from "../../../../utils/postman"
import { IAppResult } from "../../../../domain/appResult/IAppResult"
import { DELETE_AFFILIATE_PROGRAMS } from "../../../../api"
import { deleteAffiliateProgramItemFailure, deleteAffiliateProgramItemSuccess } from "../../../../store/promotion/affiliatePrograms/actions"

export function* deleteItemsSaga(action: AnyAction): any {
    try {
        const ids = action.payload
        const url = DELETE_AFFILIATE_PROGRAMS
        const result: IAppResult = yield postman.post(url, ids)

        yield put(deleteAffiliateProgramItemSuccess(result))
    } catch (ex: any) {
        yield put(deleteAffiliateProgramItemFailure(ex.response.data))
    }
}
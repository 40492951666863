import { sendPushNotificationSuccess, sendPushNotificationFailure } from './../../../../store/promotion/pushNotification/actions';
import { SEND_PUSH_NOTIFICATION_REQUEST } from './../../../../api/requestEndpoints';
import { AnyAction } from "@reduxjs/toolkit"
import { put } from '@redux-saga/core/effects'
import { IValidationResult } from "../../../../domain/validationResult/IValidationResult"
import { postman } from "../../../../utils/postman"

export function* sendPushNotificationSaga(action: AnyAction): any {    
    try {
        const form = action.payload
        const url = SEND_PUSH_NOTIFICATION_REQUEST
        const result: IValidationResult = yield postman.post(url, form)
        
        yield put(sendPushNotificationSuccess(result))
    } catch (ex: any) {
        yield put(sendPushNotificationFailure(ex.response.data))
    }
}
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, RouteComponentProps, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Checkbox, CheckboxProps, Header, Label, Modal, Popup, Segment, Table } from 'semantic-ui-react'
import { FieldType } from '../../domain/general/fieldType'
import { IDictionaryMatchParams } from '../../domain/navigation/IDictionaryMatchParams'
import { useDispatchAction, useSelectorState } from '../../hooks/reduxHook'
import { deleteDictionaryItemRequest, getDictionaryRequest } from '../../store/dictionaries/actions'
import { changeNavigatePage } from '../../store/navigation/actions'
import { convertValueForTable } from '../../utils/converter'

export const DictionaryTablePage: React.FC<RouteComponentProps<IDictionaryMatchParams>> = props => {
    var dispatch = useDispatchAction()
    const history = useHistory()
    const { t } = useTranslation()
    const isGetDictionaryFetching = useSelectorState(state => state.dictionaries.isGetDictionaryFetching)
    const tableData = useSelectorState(state => state.dictionaries.items)
    const config = useSelectorState(state => state.appConfig.dictionaries.find(p => p.name === props.match.params.dictionaryName))
    const [selectedRows, setSelectedRows] = useState<Array<string>>([])
    const isDictionaryDeleteItemsFetching = useSelectorState(state => state.dictionaries.isDictionaryDeleteItemsFetching)
    const dictionaryDeleteItemsResult = useSelectorState(state => state.dictionaries.dictionaryDeleteItemsResult)

    const loadDictionaryItems = () => dispatch(getDictionaryRequest({ dictionary: props.match.params.dictionaryName }))

    useEffect(() => {
        setSelectedRows([])
        dispatch(changeNavigatePage(props.match.params.dictionaryName))
        loadDictionaryItems()
    }, [props.match.params.dictionaryName])

    useEffect(() => {
        if (dictionaryDeleteItemsResult && dictionaryDeleteItemsResult.isError)
            toast.error(dictionaryDeleteItemsResult.message)
        else if (dictionaryDeleteItemsResult && !dictionaryDeleteItemsResult.isError) {
            toast.success(dictionaryDeleteItemsResult.message, { autoClose: 5000 })
            loadDictionaryItems()
            setSelectedRows([])
        }
    }, [dictionaryDeleteItemsResult])

    const rowClickHandler = (route: string) => {
        history.push(route)
    }

    const selectAllRowsHandler = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        if (data.checked)
            setSelectedRows(tableData.map(p => p.id))
        else
            setSelectedRows([])
    }

    const selectRowsHandler = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        if (data.checked)
            setSelectedRows(prev => [...prev, data.value as string])
        else
            setSelectedRows(prev => prev.filter(p => p !== data.value))
    }

    const isSelectAll = () => tableData && !!tableData.length && tableData.length === selectedRows.length

    const deleteRows = () => dispatch(deleteDictionaryItemRequest({
        dictionary: props.match.params.dictionaryName,
        ids: selectedRows
    }))

    const getHeaders = () => {
        const headers = []

        headers.push(
            <Table.HeaderCell key={`header_cell_cbx_all`} className={"cbx-first-cell"}>
                <Checkbox
                    key={`header_cbx_all`}
                    indeterminate={!!selectedRows.length && !isSelectAll()}
                    checked={isSelectAll()}
                    onChange={selectAllRowsHandler}
                />
            </Table.HeaderCell>
        )

        for (const propName of config!.tableProps) {
            headers.push(<Table.HeaderCell key={`header_${propName}`}>{t(config!.propsLocalizationKey[propName])}</Table.HeaderCell>)
        }

        return headers
    }

    const getRows = () => {
        const rows = []

        for (const index in tableData) {
            const value = tableData[index]
            rows.push(
                <Table.Row key={`row_${index}`}>
                    {getCells(value)}
                </Table.Row>)
        }

        return rows
    }

    const getAddNewRecordLink = () => props.match.params.addNewRecordLink ||
        `/dictionaries/${props.match.params.dictionaryName}/new`

    const getEditRecordLink = (id: string) =>  props.match.params.editRecordLink
        ? `${props.match.params.editRecordLink}${id}`
        : `/dictionaries/${props.match.params.dictionaryName}/${id}`

    const getCells = (data: any) => {
        const cells = []

        cells.push(
            <Table.Cell key={`header_cell_cbx_${data.id}`} className={"cbx-first-cell"}>
                <Checkbox
                    key={`header_cbx_${data.id}`}
                    checked={selectedRows.includes(data.id)}
                    onChange={selectRowsHandler}
                    value={data.id}
                />
            </Table.Cell>
        )

        for (const propName of config!.tableProps) {
            const fieldType = config!.propsType[propName]
            let cellValue = convertValueForTable(data[propName], fieldType, t)

            if (cellValue
                && (fieldType === FieldType.MultiEnumSelect
                    || fieldType === FieldType.MultiSelect
                    || fieldType === FieldType.MultiSelectWithOrder)) {
                const labels = []
                for (const item of cellValue) {
                    labels.push(<Label className={'margin-label'} key={`L_${item.value}`} size='small'>{item.name}</Label>)
                }

                cellValue = labels
            }

            cells.push(
                <Table.Cell
                    className={'cursor-cell-pointer'}
                    key={`header_${propName}`}
                    onClick={() => config?.editAllowed && rowClickHandler(getEditRecordLink(data.id))}
                >
                    {cellValue}
                </Table.Cell>)
        }

        return cells
    }

    const [openModal, setOpenModal] = React.useState(false)
    const openModalHandler = () => setOpenModal(true)
    const closeModalHandler = () => setOpenModal(false)
    const confirmedActionHandler = () => {
        deleteRows()
        closeModalHandler()
    }

    return (
        <div className='container'>
            <Segment attached='top'>
                <div className='segmentHeaderWithAdditionalBlock'>
                    <Header as='h3' className={'segmentHeader'}>{t(`${props.match.params.dictionaryName}`)}</Header>
                    <div className={'segmentHeaderButtonsWrapper'}>
                        {
                            config?.editAllowed &&
                            <Button.Group basic size='small'>
                                <Popup
                                    content={t('dictionaryAddNewRecord')}
                                    position='bottom right'
                                    trigger={
                                        <Button
                                            icon='add'
                                            disabled={isDictionaryDeleteItemsFetching}
                                            as={Link}
                                            to={getAddNewRecordLink()}
                                        />}
                                />
                                <Popup
                                    content={t('dictionaryDeleteRecords')}
                                    position='bottom right'
                                    trigger={
                                        <Button
                                            icon='trash'
                                            disabled={!selectedRows.length || isDictionaryDeleteItemsFetching}
                                            onClick={openModalHandler}
                                        />}
                                />
                            </Button.Group>
                        }
                    </div>
                </div>
            </Segment>
            <Segment
                attached='bottom'
                loading={isGetDictionaryFetching || isDictionaryDeleteItemsFetching}
                className={'containerWrapper'}>
                {
                    config && config.tableProps &&
                    <Table unstackable celled selectable>
                        <Table.Header>
                            <Table.Row>
                                {getHeaders()}
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {getRows()}
                        </Table.Body>
                    </Table>
                }
            </Segment>
            <Modal
                size='mini'
                dimmer='blurring'
                open={openModal}
                onClose={closeModalHandler}
            >
                <Modal.Header>{t('dictionary.delete.records.confirm.header')}</Modal.Header>
                <Modal.Content>
                    <p>{t('dictionary.delete.records.confirm.question')}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={closeModalHandler}>
                        {t('false')}
                    </Button>
                    <Button positive onClick={confirmedActionHandler}>
                    {t('true')}
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>)
}
import { newsReducer } from './promotion/news/reducer'
import { fileReducer } from './file/reducer'
import { goodsReducer } from './goods/reducer'
import { dictionariesReducer } from './dictionaries/reducer'
import { profileReducer } from './profile/reducer'
import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { authReducer } from './auth/reducer'
import { mainSaga } from '../sagas'
import { pageNavigationReducer } from './navigation/reducer'
import { appConfigReducer } from './appConfig/reducer'
import { resourcesReducer } from './resources/reducer'
import { categoryFormReducer } from './category/form/reducer'
import { productFormReducer } from './product/form/reducer'
import { promocodesReducer } from './promotion/promocodes/reducer'
import { stocksReducer } from './promotion/stocks/reducer'
import { affiliateProgramsReducer } from './promotion/affiliatePrograms/reducer'
import { pushNotificationReducer } from './promotion/pushNotification/reducer'

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
    reducer: {
        auth: authReducer,
        navigation: pageNavigationReducer,
        appConfig: appConfigReducer,
        profile: profileReducer,
        dictionaries: dictionariesReducer,
        resources: resourcesReducer,
        goods: goodsReducer,
        categoryForm: categoryFormReducer,
        productForm: productFormReducer,
        file: fileReducer,
        promocodes: promocodesReducer,
        news: newsReducer,
        stocks: stocksReducer,
        affiliatePrograms: affiliateProgramsReducer,
        pushNotification: pushNotificationReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware)
})

sagaMiddleware.run(mainSaga)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
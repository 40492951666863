import { AnyAction } from '@reduxjs/toolkit'
import { put } from '@redux-saga/core/effects'
import { postman } from '../../../../utils/postman'
import { deleteNewsItemFailure, deleteNewsItemSuccess } from '../../../../store/promotion/news/actions'
import { getEndpointsForDictionaryGetter } from '../../../../api/endpointsHelper'
import { Dictionaries } from '../../../../domain/navigation/dictionaries'

export function* deleteInformationPostsSaga(action: AnyAction): any {
    try {
        // т.к новости под капотом это справочник, то юзаем ендпоинты справочника
        const dictUrls = getEndpointsForDictionaryGetter(Dictionaries.InformationPosts);
        const url = dictUrls.endpointDelete()
        const deleteResult = yield postman.post(url, [action.payload])

        yield put(deleteNewsItemSuccess(deleteResult))
    } catch (ex: any) {
        yield put(deleteNewsItemFailure(ex.response.data))
    }
}
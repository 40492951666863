import {
    all,
    takeEvery
} from 'redux-saga/effects'
import { FETCH_GET_FILE_DTO_REQUEST, FETCH_UPLOAD_FILE_REQUEST } from '../../store/file/actions'
import { getFileDtoSaga } from './getFileDtoSaga'
import { uploadFileSaga } from './uploadFileSaga'

export function* saga() {
    yield all([
        takeEvery(FETCH_UPLOAD_FILE_REQUEST, uploadFileSaga),
        takeEvery(FETCH_GET_FILE_DTO_REQUEST, getFileDtoSaga)
    ])
}

import { AnyAction } from '@reduxjs/toolkit'
import { INewsState } from './../../../domain/pages/promotion/news/INewsState'
import {
    CLEAR_DELETE_NEW_RESULT,
    CLEAR_TOGGLE_ACTIVE_NEWS_RESULT,
    FETCH_DELETE_NEWS_ITEM_FAILURE,
    FETCH_DELETE_NEWS_ITEM_REQUEST,
    FETCH_DELETE_NEWS_ITEM_SUCCESS,
    FETCH_GET_NEWS_FAILURE,
    FETCH_GET_NEWS_REQUEST,
    FETCH_GET_NEWS_SUCCESS,
    FETCH_TOGGLE_NEWS_ITEM_FAILURE,
    FETCH_TOGGLE_NEWS_ITEM_REQUEST,
    FETCH_TOGGLE_NEWS_ITEM_SUCCESS,
    SET_NEWS
} from './actions'

const initialState: INewsState = {
    error: '',
    isGetNewsFetching: false,
    items: [],
    newsDeleteItemsResult: null,
    isNewsDeleteItemsFetching: false,
    newsToggleItemsResult: null,
    isNewsToggleItemsFetching: false
}

export const newsReducer = (state = initialState, action: AnyAction): INewsState => {
    switch (action.type) {
        case FETCH_GET_NEWS_REQUEST:
            return {
                ...initialState,
                isGetNewsFetching: true,
                error: '',
                newsToggleItemsResult: null,
                newsDeleteItemsResult: null
            }
        case SET_NEWS:
            return {
                ...state,
                items: action.payload
            }
        case FETCH_GET_NEWS_SUCCESS:
            return {
                ...state,
                isGetNewsFetching: false,
                items: action.payload
            }
        case FETCH_GET_NEWS_FAILURE:
            return {
                ...state,
                isGetNewsFetching: false,
                error: action.payload
            }
        case FETCH_TOGGLE_NEWS_ITEM_REQUEST:
            return {
                ...state,
                isNewsToggleItemsFetching: true,
                error: '',
                newsToggleItemsResult: null
            }
        case FETCH_TOGGLE_NEWS_ITEM_SUCCESS:
            return {
                ...state,
                newsToggleItemsResult: action.payload,
                isNewsToggleItemsFetching: false,
            }
        case FETCH_TOGGLE_NEWS_ITEM_FAILURE:
            return {
                ...state,
                isNewsToggleItemsFetching: false,
                error: action.payload
            }
        case CLEAR_TOGGLE_ACTIVE_NEWS_RESULT:
            return {
                ...state,
                newsToggleItemsResult: null
            }
        case FETCH_DELETE_NEWS_ITEM_REQUEST:
            return {
                ...state,
                isNewsDeleteItemsFetching: true,
                error: '',
                newsDeleteItemsResult: null
            }
        case FETCH_DELETE_NEWS_ITEM_SUCCESS:
            return {
                ...state,
                newsDeleteItemsResult: action.payload,
                isNewsDeleteItemsFetching: false,
            }
        case FETCH_DELETE_NEWS_ITEM_FAILURE:
            return {
                ...state,
                isNewsDeleteItemsFetching: false,
                error: action.payload
            }
        case CLEAR_DELETE_NEW_RESULT:
            return {
                ...state,
                newsDeleteItemsResult: null
            }
        default: return state
    }
}
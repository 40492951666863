import { Dictionaries } from '../../../domain/navigation/dictionaries'
import { AnyAction } from '@reduxjs/toolkit'
import { put } from '@redux-saga/core/effects'
import { postman } from '../../../utils/postman'
import { getByIdDictionaryFailure, getByIdDictionarySuccess } from '../../../store/dictionaries/actions'
import { getEndpointsForDictionaryGetter } from '../../../api/endpointsHelper'

export function* getByIdDictionarySaga(action: AnyAction): any {
    try {
        const dictionary: Dictionaries = action.payload.dictionary
        const endpointsGetter = getEndpointsForDictionaryGetter(dictionary)
        const url = endpointsGetter.endpointGetById(action.payload.id)
        const dto = yield postman.get(url)

        yield put(getByIdDictionarySuccess(dto))
    } catch (ex: any) {
        yield put(getByIdDictionaryFailure(ex.response.data))
    }
}
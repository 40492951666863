import { IAppConfig } from './../../domain/appConfig/IAppConfig';
import { AnyAction } from 'redux'

export const FETCH_APP_CONFIG_REQUEST = 'FETCH_APP_CONFIG_REQUEST'
export const FETCH_APP_CONFIG_SUCCESS = 'FETCH_APP_CONFIG_SUCCESS'
export const FETCH_APP_CONFIG_FAILURE = 'FETCH_APP_CONFIG_FAILURE'

export const DEFAULT_STATE = 'DEFAULT_STATE'

export const SET_DYNAMIC_BRANCH_ID = 'SET_DYNAMIC_BRANCH_ID'

export const appConfigRequest = (): AnyAction => {
    return {
        type: FETCH_APP_CONFIG_REQUEST
    }
}

export const appConfigSuccess = (payload: IAppConfig): AnyAction => {
    return {
        type: FETCH_APP_CONFIG_SUCCESS,
        payload
    }
}

export const appConfigFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_APP_CONFIG_FAILURE,
        payload
    }
}

export const setDefState = (): AnyAction => {
    return {
        type: DEFAULT_STATE,
    }
}

export const setDynamicBranchId = (branchId: string): AnyAction => {
    return {
        type: SET_DYNAMIC_BRANCH_ID,
        payload: branchId
    }
}
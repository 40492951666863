import React from "react"
import { Form } from "semantic-ui-react"
import { IRadioControl } from "../../../domain/components/form/dictionaries/IRadioControl"

const BooleanControl: React.FC<IRadioControl> = ({
    required,
    label,
    name,
    value,
    error,
    onChange,
}) => {
    return <Form.Group grouped>
        <label>{label}</label>
        <Form.Radio
            required={required}
            checked={!!value || false}
            name={name}
            toggle
            className={'mt-03'}
            onChange={onChange}
            error={error}
        />
    </Form.Group>
}

export default BooleanControl
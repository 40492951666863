export enum FieldType {
    Text = 'text',
    Password = 'password',
    Number = 'number',
    DateTime = 'dateTime',
    Boolean = 'boolean',
    Select = 'select',
    MultiSelect = 'multiSelect',
    EnumSelect = 'enumSelect',
    MultiEnumSelect = 'multiEnumSelect',
    TimePeriod = 'timePeriod',
    Time = 'time',
    LocalDateTime = 'localDateTime',
    MultiSelectWithOrder = 'multiSelectWithOrder',
    Date = 'date',
    BigText = 'bigText',
    Image = 'image'
}
import { executionAsyncId } from "async_hooks"
import { IFileDto } from "../../../general/IFileDto"
import { ILookUpDto } from "../../../general/ILookUpDto"

export enum StockConditionType {
    CostCondition = 0,
    DeliveryOrderCondition = 1,
    ProductsCondition = 2
}

export type StockConditionDto = {
    id: string,
    conditionType: ILookUpDto,
    cost: number | null | undefined
    deliveryOrderType: ILookUpDto,
    numberRequiredProducts: number | null | undefined
    conditionProducts: Array<ILookUpDto> | null | undefined,
}

export enum StockRewardType {
    Discount = 0,
    Products = 1
}

export type StockRewardDto = {
    id: string,
    rewardType: ILookUpDto,
    rewardProducts: Array<ILookUpDto> | null | undefined,
    numberProducts: number | null | undefined
    discount: number | null | undefined
}

export enum StockDurationType {
    firstOrder = 0,
    oneOrder = 1,
    period = 2,
    infinity = 3
}

export type ActivityHoursDto = {
    monday: string,
    tuesday: string,
    wednesday: string,
    thursday: string,
    friday: string,
    saturday: string,
    sunday: string,
}

export type StockDurationDto = {
    durationType: ILookUpDto,
    startPeriod: string,
    endPeriod: string,
    activityHours: ActivityHoursDto
}

export interface IStockDto {
    id: string,
    branchId: ILookUpDto | undefined,
    name: string,
    stockDuration: StockDurationDto,
    imageId: IFileDto | undefined | null,
    description: string,
    condition: StockConditionDto,
    reward: StockRewardDto
    isActive: boolean,
    createDate: string,
    modifiedDate: string,
}
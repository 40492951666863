
import { AnyAction } from '@reduxjs/toolkit'
import { IProductFormState } from '../../../domain/pages/goods/product/IProductFormState'
import {
    CLEAR_PRODUCT_FORM,
    CLEAR_PRODUCT_MODIFICATIONS,
    CLEAR_PRODUCT_SAVING_RESULT,
    FETCH_GET_BY_ID_PRODUCT_FAILURE,
    FETCH_GET_BY_ID_PRODUCT_REQUEST,
    FETCH_GET_BY_ID_PRODUCT_SUCCESS,
    FETCH_GET_PRODUCT_MODIFICATIONS_FAILURE,
    FETCH_GET_PRODUCT_MODIFICATIONS_REQUEST,
    FETCH_GET_PRODUCT_MODIFICATIONS_SUCCESS,
    FETCH_SAVE_PRODUCT_FAILURE,
    FETCH_SAVE_PRODUCT_REQUEST,
    FETCH_SAVE_PRODUCT_SUCCESS
} from './actions'

const initialState: IProductFormState = {
    error: '',
    productFormSavingResult: null,
    isProductFormSaveFetching: false,
    isGetProductByIdFetching: false,
    form: null,
    productModifications: null,
    isGetProductModificationsFetching: false
}

export const productFormReducer = (state = initialState, action: AnyAction): IProductFormState => {
    switch (action.type) {
        case CLEAR_PRODUCT_FORM:
            return {
                ...state,
                form: null
            }
        case FETCH_GET_PRODUCT_MODIFICATIONS_REQUEST:
            return {
                ...state,
                isGetProductModificationsFetching: true,
                error: '',
                productFormSavingResult: null
            }
        case FETCH_GET_PRODUCT_MODIFICATIONS_SUCCESS:
            return {
                ...state,
                isGetProductModificationsFetching: false,
                productModifications: action.payload
            }
        case FETCH_GET_PRODUCT_MODIFICATIONS_FAILURE:
            return {
                ...state,
                isGetProductModificationsFetching: false,
                error: action.payload
            }
        case CLEAR_PRODUCT_MODIFICATIONS: {
            return {
                ...state,
                productModifications: null,
            }
        }
        case FETCH_GET_BY_ID_PRODUCT_REQUEST:
            return {
                ...initialState,
                isGetProductByIdFetching: true,
                error: '',
                productFormSavingResult: null,
            }
        case FETCH_GET_BY_ID_PRODUCT_SUCCESS:
            return {
                ...state,
                isGetProductByIdFetching: false,
                form: action.payload
            }
        case FETCH_GET_BY_ID_PRODUCT_FAILURE:
            return {
                ...state,
                isGetProductByIdFetching: false,
                error: action.payload
            }
        case FETCH_SAVE_PRODUCT_REQUEST:
            return {
                ...state,
                isProductFormSaveFetching: true,
                error: '',
                productFormSavingResult: null
            }
        case FETCH_SAVE_PRODUCT_SUCCESS:
            return {
                ...state,
                productFormSavingResult: action.payload,
                isProductFormSaveFetching: false,
            }
        case FETCH_SAVE_PRODUCT_FAILURE:
            return {
                ...state,
                isProductFormSaveFetching: false,
                error: action.payload
            }
        case CLEAR_PRODUCT_SAVING_RESULT: {
            return {
                ...state,
                productFormSavingResult: null
            }
        }
        default: return state
    }
}
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CheckboxProps, DropdownItemProps, DropdownProps, Form, Header, Segment } from 'semantic-ui-react'
import { FooterMenu } from '../../components/footerMenu/FooterMenu'
import { useDispatchAction, useSelectorState } from '../../hooks/reduxHook'
import './index.css'
import { IGoodsFormMatchParams } from '../../domain/navigation/IGoodsFormMatchParams'
import { changeNavigatePage } from '../../store/navigation/actions'
import { Pages } from '../../domain/navigation/pages'
import { clearCategorySavingResult, getCategoryByIdRequest, saveCategoryRequest } from '../../store/category/form/actions'
import { ICategoryDto } from '../../domain/pages/goods/category/ICategoryDto'
import { getGoodsRequest } from '../../store/goods/actions'
import { getResourcesRequest } from '../../store/resources/actions'
import { BRANCHES_RESOURCE, CATEGORIES_RESOURCE, ORDER_CHECKOUT_OPTIONS_KEYS_RESOURCE } from '../../api'
import { ILookUpDto } from '../../domain/general/ILookUpDto'
import { isConstructorDeclaration } from 'typescript'
import { IGoodsDto } from '../../domain/pages/goods/IGoodsDto'
import { ImageInput } from '../../components/ImageInput/ImageInput'
import { IFileDto } from '../../domain/general/IFileDto'

const fieldNames = {
    name: 'name',
    branchId: 'branchId',
    orderCheckoutOptions: 'orderCheckoutOptions',
    isActive: 'isActive',
    parentCategoryId: "parentCategoryId"
}

export const CategoryFormPage: React.FC<RouteComponentProps<IGoodsFormMatchParams>> = props => {
    const dispatch = useDispatchAction()
    const history = useHistory()
    const { t } = useTranslation()
    const isGetCategoryByIdFetching = useSelectorState(state => state.categoryForm.isGetCategoryByIdFetching)
    const isCategoryFormSaveFetching = useSelectorState(state => state.categoryForm.isCategoryFormSaveFetching)
    const currentCategoryContainer = useSelectorState(state => state.goods.currentCategory)
    const userBranchId = useSelectorState(state => state.appConfig.userBranchId)
    const userDynamicBranchId = useSelectorState(state => state.appConfig.userDynamicBranchId)
    const form = useSelectorState(state => state.categoryForm.form)
    const [formState, setFormState] = useState<ICategoryDto>()
    const isGetResourcesFetching = useSelectorState(state => state.resources.isGetResourcesFetching)
    const resources = useSelectorState(state => state.resources.resources)
    const error = useSelectorState(state => state.categoryForm.error)
    const saveResult = useSelectorState(state => state.categoryForm.categoryFormSavingResult)

    const setFormStateFromStore = () => setFormState(form || undefined)
    const clearCategoryValidationResult = () => dispatch(clearCategorySavingResult())

    useEffect(() => {
        dispatch(changeNavigatePage(Pages.Goods))
        dispatch(getCategoryByIdRequest(props.match.params.id))
        dispatch(getGoodsRequest(props.match.params.containerCategoryId ?? null))
        dispatch(getResourcesRequest({ propName: fieldNames.branchId, route: BRANCHES_RESOURCE }))
        dispatch(getResourcesRequest({ propName: fieldNames.orderCheckoutOptions, route: ORDER_CHECKOUT_OPTIONS_KEYS_RESOURCE }))
        dispatch(getResourcesRequest({ propName: fieldNames.parentCategoryId, route: CATEGORIES_RESOURCE }))

        return function cleanup() {
            clearCategoryValidationResult()
        }
    }, [dispatch])

    useEffect(() => {
        setFormStateFromStore()
        setParentCategory(currentCategoryContainer)
    }, [form])

    useEffect(() => {
        if (saveResult && saveResult.isError)
            toast.error(saveResult.message)
        else if (saveResult && !saveResult.isError) {
            toast.success(saveResult.message, { autoClose: 5000 })
            history.goBack()
        }
    }, [saveResult])

    useEffect(() => {
        if (error)
            toast.error(error)
    }, [error])

    const setParentCategory = (currentCategoryContainer: IGoodsDto | null) => {
        setFormState((prev: any) => ({
            ...prev, parentCategoryId: currentCategoryContainer ? {
                value: currentCategoryContainer.id,
                name: undefined
            } : null
        }))
    }
    useEffect(() => {
        setParentCategory(currentCategoryContainer)
    }, [currentCategoryContainer])


    const getHeaderText = () => {
        if (props.match.params.id)
            return `${t('categoryEditRecordHeader')}`
        else
            return `${t('categoryNewRecordHeader')}`
    }

    const saveHandler = () => {
        let formForSave = formState as ICategoryDto

        if (currentCategoryContainer || userBranchId || userDynamicBranchId) {
            formForSave = {
                ...formForSave,
                branchId: {
                    value: (currentCategoryContainer?.branchId || userBranchId || userDynamicBranchId) as string,
                    name: undefined
                }
            }
        }

        dispatch(saveCategoryRequest(formForSave))
    }

    const cancelHandler = () => {
        clearCategoryValidationResult()
        history.goBack()
    }
    const branchResource = resources[fieldNames.branchId]
    const branchOptions: Array<DropdownItemProps> = useMemo(() => branchResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [branchResource])
    const orderCheckoutResource = resources[fieldNames.orderCheckoutOptions]
    const orderCheckoutOptions: Array<DropdownItemProps> = useMemo(() => orderCheckoutResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [orderCheckoutResource])
    const categoryResource = resources[fieldNames.parentCategoryId]
    const categoryOptions: Array<DropdownItemProps> = useMemo(() => categoryResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [categoryResource])

    const changeSelectHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        if (event.type !== 'click')
            return

        const item: ILookUpDto | null = resources[data.name]
            ?.find(p => p.value === data.value) ?? null
        setFormState((prev: any) => ({ ...prev, [data.name as string]: item }))
    }

    const changeMultiSelectHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const items: Array<ILookUpDto> = resources[data.name]
            ?.map(p => ({ name: p.name, value: p.value }))
            .filter(p => (data.value as Array<string>).includes(p.value))
        setFormState((prev: any) => ({ ...prev, [data.name as string]: items.length ? items : null }))
    }

    const changeInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormState((prev: any) => ({ ...prev, [event.target.name]: event.target.value || null }))
    }

    const changeRadioHandler = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        setFormState((prev: any) => ({ ...prev, [data.name as string]: data.checked }))
    }

    const getError = (propName: string) => {
        if (!saveResult)
            return

        const message = saveResult.errors.find(p => p.name === propName)?.message

        return message
    }

    const getOrderCheckoutOptionSelected = () => {
        if (formState?.orderCheckoutOptions?.length)
            return formState.orderCheckoutOptions.map(p => p.value)

        return []
    }

    const changeImageHandler = (image: IFileDto | null) => {
        setFormState((prev: any) => ({ ...prev, imageId: image }))
    }

    return (
        <div className='containerWithFooterMenu'>
            <Segment attached='top'>
                <Header as='h3' className={'segmentHeader'}>
                    {getHeaderText()}
                </Header>
            </Segment>
            <Segment
                attached='bottom'
                loading={isGetCategoryByIdFetching || isGetResourcesFetching || isCategoryFormSaveFetching}
            >
                <Form>
                    <div className={'container-grid-auto-fit'}>
                        <Form.Input
                            required
                            value={formState?.name || ''}
                            onChange={changeInputHandler}
                            label={t('category.name')}
                            name={fieldNames.name}
                            placeholder={t('category.name.placeholder')}
                            error={getError(fieldNames.name)}
                        />
                        {
                            (!currentCategoryContainer && !userBranchId && !userDynamicBranchId) &&
                            <Form.Dropdown
                                required
                                clearable
                                selection fluid search
                                value={formState?.branchId?.value || undefined}
                                options={branchOptions}
                                onChange={changeSelectHandler}
                                name={fieldNames.branchId}
                                label={t('branch')}
                                noResultsMessage={t('dropdownNotResultMessage')}
                                error={getError(fieldNames.branchId)}
                            />
                        }
                        <Form.Dropdown
                            clearable
                            multiple selection fluid search
                            value={getOrderCheckoutOptionSelected()}
                            options={orderCheckoutOptions}
                            onChange={changeMultiSelectHandler}
                            name={fieldNames.orderCheckoutOptions}
                            label={t('category.orderCheckoutOptions')}
                            noResultsMessage={t('dropdownNotResultMessage')}
                            error={getError(fieldNames.orderCheckoutOptions)}
                        />
                        <ImageInput
                            value={formState?.imageId || null}
                            aspectRatio={1}
                            onChange={changeImageHandler}
                        />
                        <Form.Dropdown
                            clearable
                            selection fluid search
                            value={formState?.parentCategoryId?.value || ''}
                            options={categoryOptions}
                            disabled={!categoryOptions || !categoryOptions.length}
                            onChange={changeSelectHandler}
                            name={fieldNames.parentCategoryId}
                            label={t(fieldNames.parentCategoryId)}
                            noResultsMessage={t('dropdownNotResultMessage')}
                            error={getError(fieldNames.parentCategoryId)}
                            placeholder={t('noParentCategory.placeholder')}
                        />
                        <Form.Group grouped>
                            <label>{t(fieldNames.isActive)}</label>
                            <Form.Radio
                                name={fieldNames.isActive}
                                toggle
                                className={'mt-03'}
                                checked={formState?.isActive}
                                onChange={changeRadioHandler}
                            />
                        </Form.Group>
                    </div>
                </Form>
            </Segment>
            <FooterMenu
                onCancel={cancelHandler}
                onSave={saveHandler}
                cancelDisabled={isGetCategoryByIdFetching || isGetResourcesFetching || isCategoryFormSaveFetching}
                saveDisabled={isGetCategoryByIdFetching || isGetResourcesFetching || isCategoryFormSaveFetching}
            />
        </div>)
}
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
  Menu,
  Segment,
  Sidebar,
} from 'semantic-ui-react'
import { useDispatchAction, useSelectorState } from '../../hooks/reduxHook'
import { logout } from '../../store/auth/actions'
import { toggleSidebar } from '../../store/navigation/actions'
import './index.css'
import { MenuItems } from './MenuItems'

const SidebarDimmed: React.FC = props => {
  const dispatch = useDispatchAction()
  const visible = useSelectorState(state => state.navigation.visibleSidebar)
  const location = useLocation()

  useEffect(() => {
    dispatch(toggleSidebar(false))
  }, [dispatch, location])

  const toggleSidebarHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    dispatch(toggleSidebar(false))
  }

  const logoutHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    toggleSidebarHandler(event)
    dispatch(logout())
  }

  return (
    <Sidebar.Pushable as={Segment} basic>
      <Sidebar
        as={Menu}
        animation='overlay'
        onHide={() => dispatch(toggleSidebar(false))}
        vertical
        visible={visible}
        width='thin'
        className='thinCustomWidth'
      >
        <MenuItems numberOrdersSimpleText={true} onLogout={logoutHandler} useInSidebar={true} />
      </Sidebar>

      <Sidebar.Pusher dimmed={visible} className="sidebar">
        {props.children}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  )
}

export default SidebarDimmed
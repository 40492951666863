import { AnyAction } from '@reduxjs/toolkit'
import { put } from '@redux-saga/core/effects'
import { postman } from '../../../utils/postman'
import { CATEGORY_GET_REQUEST } from '../../../api'
import { getCategoryByIdFailure, getCategoryByIdSuccess } from '../../../store/category/form/actions'

export function* getCategoryFormSaga(action: AnyAction): any {
    try {
        const url = `${CATEGORY_GET_REQUEST}/${action.payload || 'default'}`
        const category = yield postman.get(url)

        yield put(getCategoryByIdSuccess(category))
    } catch (ex: any) {
        yield put(getCategoryByIdFailure(ex.response.data))
    }
}
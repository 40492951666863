import { AnyAction } from '@reduxjs/toolkit'
import { IFileState } from '../../domain/general/IFileState'
import {
    DEFAULT_FILE_STATE,
    FETCH_GET_FILE_DTO_FAILURE,
    FETCH_GET_FILE_DTO_REQUEST,
    FETCH_GET_FILE_DTO_SUCCESS,
    FETCH_UPLOAD_FILE_FAILURE,
    FETCH_UPLOAD_FILE_REQUEST
} from './actions'

const initialState: IFileState = {
    isFetching: false,
    error: '',
    file: null
}

export const fileReducer = (state = initialState, action: AnyAction): IFileState => {
    switch (action.type) {
        case FETCH_UPLOAD_FILE_REQUEST:
        case FETCH_GET_FILE_DTO_REQUEST:
            return {
                isFetching: true,
                error: '',
                file: null
            }
        case FETCH_UPLOAD_FILE_FAILURE:
        case FETCH_GET_FILE_DTO_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload
            }
        case FETCH_GET_FILE_DTO_SUCCESS:
            return {
                ...state,
                isFetching: false,
                file: action.payload
            }
        case DEFAULT_FILE_STATE:
            return initialState
        default: return state
    }
}
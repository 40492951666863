import { IAppResult } from './../../../domain/appResult/IAppResult'
import { AnyAction } from "@reduxjs/toolkit"
import { put } from '@redux-saga/core/effects'
import { postman } from '../../../utils/postman'
import { deleteDictionaryItemFailure, deleteDictionaryItemSuccess } from '../../../store/dictionaries/actions'
import { Dictionaries } from '../../../domain/navigation/dictionaries'
import { getEndpointsForDictionaryGetter } from '../../../api/endpointsHelper'

export function* dictionaryDeleteItemsSaga(action: AnyAction): any {
    try {
        const ids = action.payload.ids
        const dictionary: Dictionaries = action.payload.dictionary
        const endpointsGetter = getEndpointsForDictionaryGetter(dictionary)
        const url = endpointsGetter.endpointDelete()
        const result: IAppResult = yield postman.post(url, ids)

        yield put(deleteDictionaryItemSuccess(result))
    } catch (ex: any) {
        yield put(deleteDictionaryItemFailure(ex.response.data))
    }
}
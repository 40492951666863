import { Area } from 'react-easy-crop/types'

export const readFile = (file: File) => {
    return new Promise<string>((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result as string), false)
        reader.readAsDataURL(file)
    })
}

export const blobToFile = (theBlob: Blob, fileName:string): File => {
    return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
}

export const createImage = (url: string) => {
    return new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', (error) => reject(error))
        image.src = url
    })
}

export const getCroppedImg = async ( imageSrc: string, pixelCrop: Area)  => {
    const image = (await createImage(imageSrc)) as HTMLImageElement
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (!ctx) {
        return null
    }

    canvas.width = image.width
    canvas.height = image.height

    ctx.drawImage(image, 0, 0)

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height
    )

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0)

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise<Blob>((resolve) => {
        canvas.toBlob((file) =>  resolve(file as Blob) , 'image/jpeg')
    })
}
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Accordion, AccordionTitleProps, CheckboxProps, DropdownItemProps, DropdownProps, Form, Header, Icon, Message, Segment } from 'semantic-ui-react'
import { BRANCHES_RESOURCE, PRODUCTS_PROMOTION_RESOURCE, PROMOMCODE_CONDITION_TYPES_RESOURCE, PROMOMCODE_REWARD_TYPES_RESOURCE } from '../../../api'
import { FooterMenu } from '../../../components/footerMenu/FooterMenu'
import { ILookUpDto } from '../../../domain/general/ILookUpDto'
import { IIdMatchParams } from '../../../domain/navigation/IGoodsMatchParams'
import { Pages } from '../../../domain/navigation/pages'
import { IPromocodeDto, PromocodeConditionType, PromocodeRewardType } from '../../../domain/pages/promotion/promocodes/IPromocodeDto'
import { useDispatchAction, useSelectorState } from '../../../hooks/reduxHook'
import { changeNavigatePage } from '../../../store/navigation/actions'
import { getResourcesRequest, resourcesClear } from '../../../store/resources/actions'
import ru from 'date-fns/locale/ru'

import './index.css'
import { DatePeriod } from '../../../components/DateTime/DatePeriod/DatePeriod'
import SelectControl from '../../../components/form/dictionaries/SelectControl'
import MultiSelectControl from '../../../components/form/dictionaries/MultiSelectControl'
import { clearPromocodeFormSavingResult, getByIdPromocodeRequest, savePromocodeFormRequest } from '../../../store/promotion/promocodes/actions'

const fieldNames = {
    branchId: 'branchId',
    name: "name",
    numberActivations: "numberActivations",
    code: 'code',
    isSingleActivation: 'isSingleActivation',
    isActive: 'isActive',
    conditionType: 'conditionType',
    conditionProducts: 'conditionProducts',
    rewardType: 'rewardType',
    rewardProducts: 'rewardProducts',
    numberProducts: 'numberProducts',
    discount: 'discount',
    cost: 'cost',
    startPeriod: 'startPeriod',
    endPeriod: 'endPeriod',
}

const productsPromotionResourceName = 'productsPromotion'

export const PromocodesFormPage: React.FC<RouteComponentProps<IIdMatchParams>> = props => {
    const dispatch = useDispatchAction()
    const history = useHistory()
    const { t } = useTranslation()

    const resources = useSelectorState(state => state.resources.resources)
    const userBranchId = useSelectorState(state => state.appConfig.userBranchId)
    const saveResult = useSelectorState(state => state.promocodes.promocodeFormSavingResult)
    const isGetResourcesFetching = useSelectorState(state => state.resources.isGetResourcesFetching)
    const isGetPromocodeByIdFetching = useSelectorState(state => state.promocodes.isGetByIdPromocodeFetching)
    const isPromocodeFormSaveFetching = useSelectorState(state => state.promocodes.isPromocodeFormSaveFetching)
    const form = useSelectorState(state => state.promocodes.form)
    const [formState, setFormState] = useState<IPromocodeDto>()

    useEffect(() => {
        setFormState(form || undefined)
    }, [form])

    useEffect(() => {
        dispatch(changeNavigatePage(Pages.Promocodes))
        dispatch(resourcesClear())
        dispatch(getResourcesRequest({ propName: fieldNames.branchId, route: BRANCHES_RESOURCE }))
        dispatch(getResourcesRequest({ propName: fieldNames.conditionType, route: PROMOMCODE_CONDITION_TYPES_RESOURCE }))

        loadProductPromotion(userBranchId)

        dispatch(getResourcesRequest({ propName: fieldNames.rewardType, route: PROMOMCODE_REWARD_TYPES_RESOURCE }))
        dispatch(getByIdPromocodeRequest(props.match.params.id))

        return function () {
            clearSavingValidationResult()
        }
    }, [dispatch])

    const loadProductPromotion = (branchId: string | undefined) => {
        if (branchId) {
            dispatch(getResourcesRequest({
                propName: productsPromotionResourceName,
                route: PRODUCTS_PROMOTION_RESOURCE,
                id: branchId
            }))
        }
    }

    useEffect(() => {
        if (saveResult && saveResult.isError)
            toast.error(saveResult.message)
        else if (saveResult && !saveResult.isError) {
            toast.success(saveResult.message, { autoClose: 5000 })
            goBackPromocodes()
        }
    }, [saveResult])

    const goBackPromocodes = () => history.push(`/${Pages.Promocodes}`)

    const clearSavingValidationResult = () => dispatch(clearPromocodeFormSavingResult())

    const getHeaderText = () => {
        if (props.match.params.id)
            return `${t(Pages.Promocodes)}: ${t('dictionaryEditRecordHeader')}`
        else
            return `${t(Pages.Promocodes)}: ${t('dictionaryNewRecordHeader')}`
    }

    const isLoadingForm = () => isGetResourcesFetching || isGetPromocodeByIdFetching || isPromocodeFormSaveFetching

    const saveHandler = () => {
        let formForSave = formState as IPromocodeDto

        dispatch(savePromocodeFormRequest(formForSave))
    }
    const cancelHandler = () => goBackPromocodes()

    const getError = (propName: string) => {
        if (!saveResult)
            return

        const message = saveResult.errors.find(p => p.name === propName)?.message

        return message
    }

    const changeInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormState((prev: any) => ({ ...prev, [event.target.name]: event.target.value || null }))
    }

    const changeSelectHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        if (event.type !== 'click')
            return

        const item: ILookUpDto | null = resources[data.name]
            ?.find(p => p.value === data.value)
            ?? null
        setFormState((prev: any) => ({ ...prev, [data.name as string]: item }))
    }

    useEffect(() => {
        loadProductPromotion(formState?.branchId?.value)
    }, [formState?.branchId?.value])

    const setDateRangeHandler = (startPeriod: string | null, endPeriod: string | null) => {
        setFormState((prev: any) => ({ ...prev, startPeriod, endPeriod }))
    }

    const changeRadioHandler = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        setFormState((prev: any) => ({ ...prev, [data.name as string]: data.checked }))
    }

    const changeConditionSelectHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        if (event.type !== 'click')
            return

        const item: ILookUpDto | null = promocodeConditionTypeResource
            ?.find(p => p.value === data.value)
            ?? null
        setFormState((prev: any) => ({
            ...prev,
            condition: prev?.condition
                ? { ...prev.condition, conditionType: item }
                : {
                    id: null,
                    conditionType: item,
                    conditionProducts: null,
                    cost: null
                }
        }))
    }

    const changeRewardSelectHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        if (event.type !== 'click')
            return

        const item: ILookUpDto | null = promocodeRewardTypeResource
            ?.find(p => p.value === data.value)
            ?? null
        setFormState((prev: any) => ({
            ...prev,
            reward: prev?.reward
                ? { ...prev.reward, rewardType: item }
                : {
                    id: null,
                    rewardType: item,
                    rewardProducts: null,
                    numberProducts: null,
                    discount: null
                }
        }))
    }

    const changeConditionProductsMultiSelectHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const items: Array<ILookUpDto> = productsPromotionResource
            ?.map(p => ({ name: p.name, value: p.value }))
            .filter(p => (data.value as Array<string>).includes(p.value))
        setFormState((prev: any) => ({
            ...prev,
            condition: prev?.condition
                ? { ...prev.condition, conditionProducts: items }
                : {
                    id: null,
                    conditionType: null,
                    conditionProducts: items,
                    cost: null
                }
        }))
    }

    const changeRewardProductsMultiSelectHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const items: Array<ILookUpDto> = productsPromotionResource
            ?.map(p => ({ name: p.name, value: p.value }))
            .filter(p => (data.value as Array<string>).includes(p.value))
        setFormState((prev: any) => ({
            ...prev,
            reward: prev?.reward
                ? { ...prev.reward, rewardProducts: items }
                : {
                    id: null,
                    rewardType: null,
                    rewardProducts: items,
                    numberProducts: null,
                    discount: null
                }
        }))
    }

    const changeConditionInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormState((prev: any) => ({
            ...prev,
            condition: prev?.condition
                ? { ...prev.condition, cost: event.target.value || null }
                : {
                    id: null,
                    conditionType: null,
                    conditionProducts: null,
                    cost: event.target.value
                }
        }))
    }

    const changeRewardInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormState((prev: any) => ({
            ...prev,
            reward: prev?.reward
                ? { ...prev.reward, [event.target.name]: event.target.value || null }
                : {
                    ...{
                        id: null,
                        rewardType: null,
                        rewardProducts: null,
                        numberProducts: null,
                        discount: null
                    },
                    [event.target.name]: event.target.value
                }
        }))
    }

    const getMainFormItems = (): Array<JSX.Element> => {
        const items: Array<JSX.Element> = []

        if (!userBranchId) {
            items.push(
                <Form.Dropdown
                    key={'mainForm-0'}
                    required
                    clearable
                    selection fluid search
                    placeholder={t('branch.placeholder')}
                    value={formState?.branchId?.value || undefined}
                    options={branchOptions}
                    onChange={changeSelectHandler}
                    name={fieldNames.branchId}
                    label={t('branch')}
                    noResultsMessage={t('dropdownNotResultMessage')}
                    error={getError(fieldNames.branchId)}
                />)
        }

        items.push(
            <Form.Input
                key={'mainForm-1'}
                required
                value={formState?.name || ''}
                onChange={changeInputHandler}
                label={t('promocode.name')}
                name={fieldNames.name}
                placeholder={t('promocode.name.placeholder')}
                error={getError(fieldNames.name)}
            />)

        items.push(
            <DatePeriod
                key={'mainForm-2'}
                required
                startPeriod={formState?.startPeriod}
                endPeriod={formState?.endPeriod}
                label={t('promocode.validity.period')}
                placeholder={t('promocode.validity.period.placeholder')}
                locale={ru}
                setDateRangeHandler={setDateRangeHandler}
                error={getError(fieldNames.startPeriod) || getError(fieldNames.endPeriod)}
            />)

        items.push(
            <Form.Input
                key={'mainForm-3'}
                required
                value={formState?.numberActivations || ''}
                type='number'
                onChange={changeInputHandler}
                label={t('promocode.numberActivations')}
                name={fieldNames.numberActivations}
                placeholder={t('promocode.numberActivations.placeholder')}
                error={getError(fieldNames.numberActivations)}
            />)

        items.push(
            <Form.Input
                key={'mainForm-4'}
                required
                value={formState?.code || ''}
                onChange={changeInputHandler}
                label={t('promocode.code')}
                name={fieldNames.code}
                placeholder={t('promocode.code.placeholder')}
                error={getError(fieldNames.code)}
            />)
        items.push(
            <Form.Group grouped key={'mainForm-5'}>
                <label>{t('promocode.isSingleActivation')}</label>
                <Form.Radio
                    name={fieldNames.isSingleActivation}
                    toggle
                    className={'mt-03'}
                    checked={formState?.isSingleActivation}
                    onChange={changeRadioHandler}
                />
            </Form.Group>)

        items.push(
            <Form.Group grouped key={'mainForm-6'}>
                <label>{t(fieldNames.isActive)}</label>
                <Form.Radio
                    name={fieldNames.isActive}
                    toggle
                    className={'mt-03'}
                    checked={formState?.isActive}
                    onChange={changeRadioHandler}
                />
            </Form.Group>)

        return items
    }

    const getConditionFormItems = (): Array<JSX.Element> => {
        const items: Array<JSX.Element> = []

        items.push(
            <SelectControl
                key={'conditionForm-0'}
                required
                label={t('promocode.conditionType')}
                name={fieldNames.conditionType}
                placeholder={t('promocode.conditionType.placeholder')}
                value={formState?.condition?.conditionType?.value || ''}
                options={promocodeConditionTypeOptions}
                onChange={changeConditionSelectHandler}
                error={getError(fieldNames.conditionType)}
            />)

        if (isConditionProductsType()) {
            items.push(
                <MultiSelectControl
                    key={'conditionForm-1'}
                    required
                    label={t('promocode.conditionProducts')}
                    name={fieldNames.conditionProducts}
                    placeholder={t('promocode.conditionProducts.placeholder')}
                    value={formState?.condition?.conditionProducts?.map(p => p.value) || []}
                    options={productsPromotionOptions}
                    onChange={changeConditionProductsMultiSelectHandler}
                    error={getError(fieldNames.conditionProducts)}
                />)
        }

        if (isConditionCostType()) {
            items.push(
                <Form.Input
                    key={'conditionForm-2'}
                    required
                    value={formState?.condition?.cost || ''}
                    type='number'
                    onChange={changeConditionInputHandler}
                    label={t('promocode.cost')}
                    placeholder={t('promocode.cost.placeholder')}
                    error={getError(fieldNames.cost)}
                />)
        }

        return items
    }

    const getRewardFormItems = (): Array<JSX.Element> => {
        const items: Array<JSX.Element> = []

        items.push(
            <SelectControl
                key={'rewardForm-0'}
                required
                label={t('promocode.rewardType')}
                name={fieldNames.rewardType}
                placeholder={t('promocode.rewardType.placeholder')}
                value={formState?.reward?.rewardType?.value || ''}
                options={promocodeRewardTypeOptions}
                onChange={changeRewardSelectHandler}
                error={getError(fieldNames.rewardType)}
            />)

        if (isRewardProductsType()) {
            items.push(
                <MultiSelectControl
                    key={'rewardForm-1'}
                    required
                    label={t('promocode.rewardProducts')}
                    name={fieldNames.rewardProducts}
                    placeholder={t('promocode.rewardProducts.placeholder')}
                    value={formState?.reward?.rewardProducts?.map(p => p.value) || []}
                    options={productsPromotionOptions}
                    onChange={changeRewardProductsMultiSelectHandler}
                    error={getError(fieldNames.rewardProducts)}
                />)

            items.push(
                <Form.Input
                    key={'rewardForm-2'}
                    required
                    value={formState?.reward?.numberProducts || ''}
                    type='number'
                    name={fieldNames.numberProducts}
                    onChange={changeRewardInputHandler}
                    label={t('promocode.numberProducts')}
                    placeholder={t('promocode.numberProducts.placeholder')}
                    error={getError(fieldNames.numberProducts)}
                />)
        }

        if (isRewardDiscountType()) {
            items.push(
                <Form.Input
                    key={'rewardForm-3'}
                    required
                    value={formState?.reward?.discount || ''}
                    type='number'
                    name={fieldNames.discount}
                    onChange={changeRewardInputHandler}
                    label={t('promocode.discount')}
                    placeholder={t('promocode.discount.placeholder')}
                    error={getError(fieldNames.discount)}
                />)
        }

        return items
    }

    const branchResource = resources[fieldNames.branchId]
    const branchOptions: Array<DropdownItemProps> = useMemo(() => branchResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [branchResource])

    const promocodeConditionTypeResource = resources[fieldNames.conditionType]
    const promocodeConditionTypeOptions: Array<DropdownItemProps> = useMemo(() => promocodeConditionTypeResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [promocodeConditionTypeResource])

    const productsPromotionResource = resources[productsPromotionResourceName]
    const productsPromotionOptions: Array<DropdownItemProps> = useMemo(() => productsPromotionResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [productsPromotionResource])

    const promocodeRewardTypeResource = resources[fieldNames.rewardType]
    const promocodeRewardTypeOptions: Array<DropdownItemProps> = useMemo(() => promocodeRewardTypeResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [promocodeRewardTypeResource])

    type AccordionToggleType = [boolean, boolean, boolean]
    const [accordionToggleState, setAccordionToggleState] = useState<AccordionToggleType>([true, true, true])
    const accordionToggleHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, data: AccordionTitleProps) => {
        setAccordionToggleState(prev => {
            const toggles: AccordionToggleType = [...prev]
            const idx: number = data.index as number
            toggles[idx] = !toggles[idx]

            return toggles
        })
    }

    const isConditionProductsType = () => formState?.condition?.conditionType?.value == PromocodeConditionType.ProductsCondition.toString()
    const isConditionCostType = () => formState?.condition?.conditionType?.value == PromocodeConditionType.CostCondition.toString()
    const getConditionInfoContent = () => {

        switch (formState?.condition?.conditionType?.value) {
            case PromocodeConditionType.WithoutCondition.toString(): return t('promocode.conditionProducts.info.withoutCondition')
            case PromocodeConditionType.ProductsCondition.toString(): return t('promocode.conditionProducts.info.productsCondition')
            case PromocodeConditionType.CostCondition.toString(): return t('promocode.conditionProducts.info.costCondition')
        }
    }

    const isRewardDiscountType = () => formState?.reward?.rewardType?.value == PromocodeRewardType.Discount.toString()
    const isRewardProductsType = () => formState?.reward?.rewardType?.value == PromocodeRewardType.Products.toString()

    return (
        <div className='containerWithFooterMenu'>
            <Segment attached='top'>
                <Header as='h3' className={'segmentHeader'}>
                    {getHeaderText()}
                </Header>
            </Segment>
            <Segment
                attached='bottom'
                loading={isLoadingForm()}>
                <Accordion styled fluid>
                    <Accordion.Title
                        active={accordionToggleState[0]}
                        index={0}
                        onClick={accordionToggleHandler}
                    >
                        <Icon name='dropdown' />
                        {t('promocode.main.segment')}
                    </Accordion.Title>
                    <Accordion.Content active={accordionToggleState[0]}>
                        <Form>
                            <div className={'container-grid-auto-fit'}>
                                {getMainFormItems()}
                            </div>
                        </Form>
                    </Accordion.Content>

                    <Accordion.Title

                        active={accordionToggleState[1]}
                        index={1}
                        onClick={accordionToggleHandler}
                    >
                        <Icon name='dropdown' />
                        {t('promocode.condition.segment')}
                    </Accordion.Title>
                    <Accordion.Content active={accordionToggleState[1]}>
                        <Form>
                            {
                                formState?.condition?.conditionType != undefined &&
                                <Message
                                    info
                                    size='mini'
                                    icon='info'
                                    header={t('promocode.conditionProducts.info.header')}
                                    content={getConditionInfoContent()} />
                            }
                            <div className={'container-grid-auto-fit'}>
                                {getConditionFormItems()}
                            </div>
                        </Form>
                    </Accordion.Content>

                    <Accordion.Title
                        active={accordionToggleState[2]}
                        index={2}
                        onClick={accordionToggleHandler}
                    >
                        <Icon name='dropdown' />
                        {t('promocode.reward.segment')}
                    </Accordion.Title>
                    <Accordion.Content active={accordionToggleState[2]}>
                        <Form>
                            <div className={'container-grid-auto-fit'}>
                                {getRewardFormItems()}
                            </div>
                        </Form>
                    </Accordion.Content>
                </Accordion>
            </Segment>
            <FooterMenu
                onCancel={cancelHandler}
                onSave={saveHandler}
                saveDisabled={isLoadingForm()}
                cancelDisabled={isLoadingForm()}
            />
        </div>)
}
import { IAppConfigState } from './../../domain/appConfig/IAppConfigState'
import { AnyAction } from '@reduxjs/toolkit'
import { DEFAULT_STATE, FETCH_APP_CONFIG_FAILURE, FETCH_APP_CONFIG_REQUEST, FETCH_APP_CONFIG_SUCCESS, SET_DYNAMIC_BRANCH_ID } from './actions'

const initialState: IAppConfigState = {
    isFetching: false,
    error: '',
    pages: [],
    dictionaries: [],
    userName: '',
    userLogin: '',
    roleName: '',
    userBranchId: undefined,
    userDynamicBranchId: undefined,
    promotions: []
}

export const appConfigReducer = (state = initialState, action: AnyAction): IAppConfigState => {
    switch (action.type) {
        case FETCH_APP_CONFIG_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: ''
            }
        case FETCH_APP_CONFIG_SUCCESS:
            return {
                ...state,
                isFetching: false,
                ...action.payload,
            }
        case FETCH_APP_CONFIG_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload
            }
        case DEFAULT_STATE:
            return {
                ...initialState
            }
        case SET_DYNAMIC_BRANCH_ID:
            return {
                ...state,
                userDynamicBranchId: action.payload
            }
        default: return state
    }
}
import { AnyAction } from '@reduxjs/toolkit'
import { IPushNotificationState } from '../../../domain/pages/promotion/pushNotification/IPushNotificationState'

import {
    CLEAR_SEND_PUSH_NOTIFICATION_RESULT,
    FETCH_SEND_PUSH_NOTIFICATION_FAILURE,
    FETCH_SEND_PUSH_NOTIFICATION_REQUEST,
    FETCH_SEND_PUSH_NOTIFICATION_SUCCESS
} from './actions'

const initialState: IPushNotificationState = {
    error: '',
    sendPushNotificationResult: null,
    isSendPushNotificationFetching: false
}

export const pushNotificationReducer = (state = initialState, action: AnyAction): IPushNotificationState => {
    switch (action.type) {
        case FETCH_SEND_PUSH_NOTIFICATION_REQUEST:
            return {
                ...state,
                isSendPushNotificationFetching: true,
                error: '',
                sendPushNotificationResult: null
            }
        case FETCH_SEND_PUSH_NOTIFICATION_SUCCESS:
            return {
                ...state,
                sendPushNotificationResult: action.payload,
                isSendPushNotificationFetching: false,
            }
        case FETCH_SEND_PUSH_NOTIFICATION_FAILURE:
            return {
                ...state,
                isSendPushNotificationFetching: false,
                error: action.payload
            }
        case CLEAR_SEND_PUSH_NOTIFICATION_RESULT: {
            return {
                ...state,
                sendPushNotificationResult: null,
                error: ''
            }
        }
        default: return state
    }
}
import React from 'react'
import { Media } from '../../utils/media'
import { ProfileBasePage } from './ProfileBasePage'

export const ProfilePageMobile: React.FC = () => {
    return (
        <Media at='mobile'>
            <ProfileBasePage />
        </Media>
    )
}
import { ILookUpDto } from './../domain/general/ILookUpDto'
import { TFunction } from "react-i18next"
import { FieldType } from "../domain/general/fieldType"
import { createDateAsUTC, parseDateTime, formatDateTime } from "./dateTimeHelper"

export const convertValueForTable = (value: any, type: FieldType, t: TFunction<"translation">): string | Array<any> => {
    switch (type) {
        case FieldType.Boolean:
            return convertForTableBoolean(!!value, t)
        case FieldType.LocalDateTime:
            return convertForTableLocalDateTime(value)
        case FieldType.Select:
            return convertForTableSelect(value)
        default:
            return value
    }
}

export const convertForTableBoolean = (value: boolean, t: TFunction<"translation">): string => {
    return t(value.toString())
}

export const convertForTableLocalDateTime = (value: string): string => {
    const dateTime = parseDateTime(value)
    const localDateTime = createDateAsUTC(dateTime)

    return formatDateTime(localDateTime)
}

export const convertForTableSelect = (value: ILookUpDto | undefined) => value?.name || ''
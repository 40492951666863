import { MediaContextProvider } from "../../../utils/media"
import { PushNotificationsPageDesktop } from "./PushNotificationsPageDesktop"
import { PushNotificationsPageMobile } from "./PushNotificationsPageMobile"
import { PushNotificationsPageTablet } from "./PushNotificationsPageTablet"

export const PushNotificationsPage: React.FC = () => {
    return (
        <MediaContextProvider>
            <PushNotificationsPageDesktop/>
            <PushNotificationsPageMobile/>
            <PushNotificationsPageTablet/>
        </MediaContextProvider>)
}
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Accordion, AccordionTitleProps, CheckboxProps, DropdownItemProps, DropdownProps, Form, Header, Icon, Message, Segment } from 'semantic-ui-react'
import { BRANCHES_RESOURCE, DELIVERY_ORDER_TYPES_RESOURCE, PRODUCTS_PROMOTION_RESOURCE, STOCK_CONDITION_TYPES_RESOURCE, STOCK_DURATION_TYPES_RESOURCE, STOCK_REWARD_TYPES_RESOURCE } from '../../../api'
import { FooterMenu } from '../../../components/footerMenu/FooterMenu'
import { ILookUpDto } from '../../../domain/general/ILookUpDto'
import { IIdMatchParams } from '../../../domain/navigation/IGoodsMatchParams'
import { Pages } from '../../../domain/navigation/pages'
import { PromocodeConditionType, PromocodeRewardType } from '../../../domain/pages/promotion/promocodes/IPromocodeDto'
import { useDispatchAction, useSelectorState } from '../../../hooks/reduxHook'
import { changeNavigatePage } from '../../../store/navigation/actions'
import { getResourcesRequest, resourcesClear } from '../../../store/resources/actions'
import ru from 'date-fns/locale/ru'
import { DatePeriod } from '../../../components/DateTime/DatePeriod/DatePeriod'
import SelectControl from '../../../components/form/dictionaries/SelectControl'
import MultiSelectControl from '../../../components/form/dictionaries/MultiSelectControl'
import { IStockDto, StockConditionType, StockDurationType } from '../../../domain/pages/promotion/stocks/IStockDto'
import { clearStockFormSavingResult, getByIdStockRequest, saveStockFormRequest } from '../../../store/promotion/stocks/actions'
import { IFileDto } from '../../../domain/general/IFileDto'
import { ImageInput } from '../../../components/ImageInput/ImageInput'
import TimePeriodControl from '../../../components/form/dictionaries/TimePeriodControl'

import './index.css'

const fieldNames = {
    branchId: 'branchId',
    name: "name",
    numberActivations: "numberActivations",
    code: 'code',
    isSingleActivation: 'isSingleActivation',
    isActive: 'isActive',
    conditionType: 'conditionType',
    conditionProducts: 'conditionProducts',
    rewardType: 'rewardType',
    rewardProducts: 'rewardProducts',
    numberProducts: 'numberProducts',
    discount: 'discount',
    cost: 'cost',
    description: 'description',
    durationType: 'durationType',
    monday: 'monday',
    tuesday: 'tuesday',
    wednesday: 'wednesday',
    thursday: 'thursday',
    friday: 'friday',
    saturday: 'saturday',
    sunday: 'sunday',
    numberRequiredProducts: 'numberRequiredProducts',
    deliveryOrderType: 'deliveryOrderType',
    imageId: 'imageId',
    startPeriod: 'startPeriod',
    endPeriod: 'endPeriod'
}

const productsPromotionResourceName = 'productsPromotion'

export const StocksFormPage: React.FC<RouteComponentProps<IIdMatchParams>> = props => {
    const dispatch = useDispatchAction()
    const history = useHistory()
    const { t } = useTranslation()

    const resources = useSelectorState(state => state.resources.resources)
    const userBranchId = useSelectorState(state => state.appConfig.userBranchId)
    const saveResult = useSelectorState(state => state.stocks.stockFormSavingResult)
    const isGetResourcesFetching = useSelectorState(state => state.resources.isGetResourcesFetching)
    const isGetByIdStockFetching = useSelectorState(state => state.stocks.isGetByIdStockFetching)
    const isStockFormSaveFetching = useSelectorState(state => state.stocks.isStockFormSaveFetching)
    const form = useSelectorState(state => state.stocks.form)
    const [formState, setFormState] = useState<IStockDto>()

    useEffect(() => {
        setFormState(form || undefined)
    }, [form])

    useEffect(() => {
        dispatch(changeNavigatePage(Pages.Stocks))
        dispatch(resourcesClear())
        dispatch(getResourcesRequest({ propName: fieldNames.branchId, route: BRANCHES_RESOURCE }))
        dispatch(getResourcesRequest({ propName: fieldNames.conditionType, route: STOCK_CONDITION_TYPES_RESOURCE }))
        
        loadProductPromotion(userBranchId)

        dispatch(getResourcesRequest({ propName: fieldNames.rewardType, route: STOCK_REWARD_TYPES_RESOURCE }))
        dispatch(getResourcesRequest({ propName: fieldNames.durationType, route: STOCK_DURATION_TYPES_RESOURCE }))
        dispatch(getResourcesRequest({ propName: fieldNames.deliveryOrderType, route: DELIVERY_ORDER_TYPES_RESOURCE }))
        dispatch(getByIdStockRequest(props.match.params.id))

        return function () {
            clearSavingValidationResult()
        }
    }, [dispatch])

    const loadProductPromotion = (branchId: string | undefined) => {
        if (branchId) {
            dispatch(getResourcesRequest({
                propName: productsPromotionResourceName,
                route: PRODUCTS_PROMOTION_RESOURCE,
                id: branchId
            }))
        }
    }

    useEffect(() => {
        loadProductPromotion(formState?.branchId?.value)
    }, [formState?.branchId?.value])

    useEffect(() => {
        if (saveResult && saveResult.isError)
            toast.error(saveResult.message)
        else if (saveResult && !saveResult.isError) {
            toast.success(saveResult.message, { autoClose: 5000 })
            goBackStocks()
        }
    }, [saveResult])

    const goBackStocks = () => history.push(`/${Pages.Stocks}`)

    const clearSavingValidationResult = () => dispatch(clearStockFormSavingResult())

    const getHeaderText = () => {
        if (props.match.params.id)
            return `${t(Pages.Stocks)}: ${t('dictionaryEditRecordHeader')}`
        else
            return `${t(Pages.Stocks)}: ${t('dictionaryNewRecordHeader')}`
    }

    const isLoadingForm = () => isGetResourcesFetching || isGetByIdStockFetching || isStockFormSaveFetching

    const saveHandler = () => {
        let formForSave = formState as IStockDto

        dispatch(saveStockFormRequest(formForSave))
    }
    const cancelHandler = () => goBackStocks()

    const getError = (propName: string) => {
        if (!saveResult)
            return

        const message = saveResult.errors.find(p => p.name === propName)?.message

        return message
    }

    const changeInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormState((prev: any) => ({ ...prev, [event.target.name]: event.target.value || null }))
    }

    const changeActivityHoursInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        var baseActivityHours = {
            monday: null,
            tuesday: null,
            wednesday: null,
            thursday: null,
            friday: null,
            saturday: null,
            sunday: null,
        }
        setFormState((prev: any) => ({
            ...prev,
            stockDuration: prev?.stockDuration
                ? {
                    ...prev.stockDuration,
                    activityHours: prev.stockDuration.activityHours
                        ? { ...prev.stockDuration.activityHours, [event.target.name]: event.target.value || null }
                        : {
                            ...baseActivityHours,
                            [event.target.name]: event.target.value || null
                        }
                }
                : {
                    stockDurationType: null,
                    startPeriod: null,
                    endPeriod: null,
                    activityHours: {
                        ...baseActivityHours,
                        [event.target.name]: event.target.value || null
                    },
                }
        }))
    }

    const changeTextAreaHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFormState((prev: any) => ({ ...prev, [event.target.name]: event.target.value || null }))
    }

    const changeSelectHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        if (event.type !== 'click')
            return

        const item: ILookUpDto | null = resources[data.name]
            ?.find(p => p.value === data.value)
            ?? null
        setFormState((prev: any) => ({ ...prev, [data.name as string]: item }))
    }

    const setDurationPeriodHandler = (startPeriod: string | null, endPeriod: string | null) => {
        setFormState((prev: any) => ({
            ...prev,
            stockDuration: prev?.stockDuration
                ? { ...prev.stockDuration, startPeriod, endPeriod }
                : {
                    stockDurationType: null,
                    startPeriod,
                    endPeriod,
                    activityHours: null,
                }
        }))
    }

    const changeRadioHandler = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        setFormState((prev: any) => ({ ...prev, [data.name as string]: data.checked }))
    }

    const changeConditionSelectHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        if (event.type !== 'click')
            return

        const blankConditionObj = {
            conditionType: null,
            conditionProducts: null,
            numberRequiredProducts: null,
            deliveryOrderType: null,
            cost: null
        }
        const item: ILookUpDto | null = stockConditionTypeResource
            ?.find(p => p.value === data.value)
            ?? null
        setFormState((prev: any) => ({
            ...prev,
            condition: prev?.condition
                ? {
                    ...prev.condition,
                    ...blankConditionObj,
                    conditionType: item
                }
                : {
                    ...blankConditionObj,
                    id: null,
                    conditionType: item,
                }
        }))
    }

    const changeConditionDeliveryTypeSelectHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        if (event.type !== 'click')
            return

        const item: ILookUpDto | null = stockConditionTypeResource
            ?.find(p => p.value === data.value)
            ?? null
        setFormState((prev: any) => ({
            ...prev,
            condition: prev?.condition
                ? { ...prev.condition, deliveryOrderType: item }
                : {
                    id: null,
                    conditionType: null,
                    conditionProducts: null,
                    numberRequiredProducts: null,
                    deliveryOrderType: item,
                    cost: null
                }
        }))
    }

    const changeRewardSelectHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        if (event.type !== 'click')
            return

        const blankRewardObj = {
            rewardType: null,
            rewardProducts: null,
            numberProducts: null,
            discount: null
        }
        const item: ILookUpDto | null = stockRewardTypeResource
            ?.find(p => p.value === data.value)
            ?? null
        setFormState((prev: any) => ({
            ...prev,
            reward: prev?.reward
                ? {
                    ...prev.reward,
                    ...blankRewardObj,
                    rewardType: item
                }
                : {
                    ...blankRewardObj,
                    id: null,
                    rewardType: item,
                }
        }))
    }

    const changeDurationTypeSelectHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        if (event.type !== 'click')
            return

        const item: ILookUpDto | null = stockDurationTypeResource
            ?.find(p => p.value === data.value)
            ?? null
        setFormState((prev: any) => ({
            ...prev,
            stockDuration: { // при смене типа всегда обнуляем всю настройку
                durationType: item,
                startPeriod: null,
                endPeriod: null,
                activityHours: null,
            }
        }))
    }

    const changeConditionProductsMultiSelectHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const items: Array<ILookUpDto> = productsPromotionResource
            ?.map(p => ({ name: p.name, value: p.value }))
            .filter(p => (data.value as Array<string>).includes(p.value))
        setFormState((prev: any) => ({
            ...prev,
            condition: prev?.condition
                ? { ...prev.condition, conditionProducts: items }
                : {
                    id: null,
                    conditionType: null,
                    conditionProducts: items,
                    numberRequiredProducts: null,
                    deliveryOrderType: null,
                    cost: null
                }
        }))
    }

    const changeRewardProductsMultiSelectHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const items: Array<ILookUpDto> = productsPromotionResource
            ?.map(p => ({ name: p.name, value: p.value }))
            .filter(p => (data.value as Array<string>).includes(p.value))
        setFormState((prev: any) => ({
            ...prev,
            reward: prev?.reward
                ? { ...prev.reward, rewardProducts: items }
                : {
                    id: null,
                    rewardType: null,
                    rewardProducts: items,
                    numberProducts: null,
                    discount: null
                }
        }))
    }

    const changeConditionInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormState((prev: any) => ({
            ...prev,
            condition: prev?.condition
                ? { ...prev.condition, [event.target.name]: event.target.value || null }
                : {
                    ...{
                        id: null,
                        conditionType: null,
                        conditionProducts: null,
                        numberRequiredProducts: null,
                        deliveryOrderType: null,
                        cost: null
                    },
                    [event.target.name]: event.target.value
                }
        }))
    }

    const changeRewardInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormState((prev: any) => ({
            ...prev,
            reward: prev?.reward
                ? { ...prev.reward, [event.target.name]: event.target.value || null }
                : {
                    ...{
                        id: null,
                        rewardType: null,
                        rewardProducts: null,
                        numberProducts: null,
                        discount: null
                    },
                    [event.target.name]: event.target.value
                }
        }))
    }

    const changeImageHandler = (image: IFileDto | null) => {
        setFormState((prev: any) => ({ ...prev, imageId: image }))
    }

    const getMainFormItems = (): Array<JSX.Element> => {
        const items: Array<JSX.Element> = []

        if (!userBranchId) {
            items.push(
                <Form.Dropdown
                    key={"mainForm-0"}
                    required
                    clearable
                    selection fluid search
                    placeholder={t('branch.placeholder')}
                    value={formState?.branchId?.value || undefined}
                    options={branchOptions}
                    onChange={changeSelectHandler}
                    name={fieldNames.branchId}
                    label={t('branch')}
                    noResultsMessage={t('dropdownNotResultMessage')}
                    error={getError(fieldNames.branchId)}
                />)
        }

        items.push(
            <Form.Input
                key={"mainForm-1"}
                required
                value={formState?.name || ''}
                onChange={changeInputHandler}
                label={t('stock.name')}
                name={fieldNames.name}
                placeholder={t('stock.name.placeholder')}
                error={getError(fieldNames.name)}
            />)

        items.push(
            <ImageInput
                required
                key={"mainForm-2"}
                value={formState?.imageId || null}
                aspectRatio={115.0 / 37}
                onChange={changeImageHandler}
                error={getError(fieldNames.imageId)}
            />
        )

        items.push(
            <Form.TextArea
                key={"mainForm-3"}
                value={formState?.description || ''}
                onChange={changeTextAreaHandler}
                label={t('stock.description')}
                name={fieldNames.description}
                placeholder={t('stock.description.placeholder')}
                error={getError(fieldNames.description)}
            />)

        items.push(
            <Form.Group grouped key={"mainForm-4"}>
                <label>{t(fieldNames.isActive)}</label>
                <Form.Radio
                    name={fieldNames.isActive}
                    toggle
                    className={'mt-03'}
                    checked={formState?.isActive}
                    onChange={changeRadioHandler}
                />
            </Form.Group>)

        return items
    }

    const isDurationTypePeriodType = () => formState?.stockDuration?.durationType?.value == StockDurationType.period.toString()
    const isInfinityTypePeriodType = () => formState?.stockDuration?.durationType?.value == StockDurationType.infinity.toString()
    const isShowActivityHours = () => isDurationTypePeriodType() || isInfinityTypePeriodType()

    const getDurationFormItems = (): JSX.Element => {
        const items: Array<JSX.Element> = []

        items.push(
            <SelectControl
                required
                key={'durationForm-0'}
                label={t('stock.durationType')}
                name={fieldNames.durationType}
                placeholder={t('stock.durationType.placeholder')}
                value={formState?.stockDuration?.durationType?.value || ''}
                options={stockDurationTypeOptions}
                onChange={changeDurationTypeSelectHandler}
                error={getError(fieldNames.durationType)}
            />)

        if (isDurationTypePeriodType()) {
            items.push(
                <DatePeriod
                    required
                    key={'durationForm-1'}
                    startPeriod={formState?.stockDuration?.startPeriod || ''}
                    endPeriod={formState?.stockDuration?.endPeriod || ''}
                    label={t('stock.validity.period')}
                    placeholder={t('stock.validity.period.placeholder')}
                    locale={ru}
                    setDateRangeHandler={setDurationPeriodHandler}
                    error={getError(fieldNames.startPeriod) || getError(fieldNames.endPeriod)}
                />)
        }

        const activityHours = [
            {
                key: 'accordion-activityHours',
                title: t('stock.duration.activityHours'),
                content: {
                    content: (
                        <Form>
                            <Message
                                info
                                size='mini'
                                icon='info'
                                header={t('stock.duration.activityHours.info.header')}
                                content={t('stock.duration.activityHours.info.content')} />

                            <div className={'container-grid-auto-fit'}>
                                <TimePeriodControl
                                    required={false}
                                    label={t(`${fieldNames.monday}`)}
                                    name={fieldNames.monday}
                                    value={formState?.stockDuration?.activityHours?.monday || ''}
                                    error={getError(fieldNames.monday)}
                                    onChange={changeActivityHoursInputHandler}
                                />
                                <TimePeriodControl
                                    required={false}
                                    label={t(`${fieldNames.tuesday}`)}
                                    name={fieldNames.tuesday}
                                    value={formState?.stockDuration?.activityHours?.tuesday || ''}
                                    error={getError(fieldNames.tuesday)}
                                    onChange={changeActivityHoursInputHandler}
                                />
                                <TimePeriodControl
                                    required={false}
                                    label={t(`${fieldNames.wednesday}`)}
                                    name={fieldNames.wednesday}
                                    value={formState?.stockDuration?.activityHours?.wednesday || ''}
                                    error={getError(fieldNames.wednesday)}
                                    onChange={changeActivityHoursInputHandler}
                                />
                                <TimePeriodControl
                                    required={false}
                                    label={t(`${fieldNames.thursday}`)}
                                    name={fieldNames.thursday}
                                    value={formState?.stockDuration?.activityHours?.thursday || ''}
                                    error={getError(fieldNames.thursday)}
                                    onChange={changeActivityHoursInputHandler}
                                />
                                <TimePeriodControl
                                    required={false}
                                    label={t(`${fieldNames.friday}`)}
                                    name={fieldNames.friday}
                                    value={formState?.stockDuration?.activityHours?.friday || ''}
                                    error={getError(fieldNames.friday)}
                                    onChange={changeActivityHoursInputHandler}
                                />
                                <TimePeriodControl
                                    required={false}
                                    label={t(`${fieldNames.saturday}`)}
                                    name={fieldNames.saturday}
                                    value={formState?.stockDuration?.activityHours?.saturday || ''}
                                    error={getError(fieldNames.saturday)}
                                    onChange={changeActivityHoursInputHandler}
                                />
                                <TimePeriodControl
                                    required={false}
                                    label={t(`${fieldNames.sunday}`)}
                                    name={fieldNames.sunday}
                                    value={formState?.stockDuration?.activityHours?.sunday || ''}
                                    error={getError(fieldNames.sunday)}
                                    onChange={changeActivityHoursInputHandler}
                                />
                            </div>
                        </Form>
                    )
                }
            }
        ]

        return (
            <div>
                <Form>
                    <div className={'container-grid-auto-fit'}>
                        {items}
                    </div>
                </Form>
                {
                    isShowActivityHours() &&
                    <Accordion.Accordion panels={activityHours} />
                }
            </div>)
    }

    const getConditionFormItems = (): Array<JSX.Element> => {
        const items: Array<JSX.Element> = []

        items.push(
            <SelectControl
                required
                key={'conditionForm-0'}
                label={t('stock.conditionType')}
                name={fieldNames.conditionType}
                placeholder={t('stock.conditionType.placeholder')}
                value={formState?.condition?.conditionType?.value || ''}
                options={stockConditionTypeOptions}
                onChange={changeConditionSelectHandler}
                error={getError(fieldNames.conditionType)}
            />)

        if (isConditionProductsType()) {
            items.push(
                <Form.Input
                    required
                    key={'conditionForm-1'}
                    value={formState?.condition?.numberRequiredProducts || ''}
                    type='number'
                    onChange={changeConditionInputHandler}
                    label={t('stock.numberRequiredProducts')}
                    name={fieldNames.numberRequiredProducts}
                    placeholder={t('stock.numberRequiredProducts.placeholder')}
                    error={getError(fieldNames.numberRequiredProducts)}
                />)

            items.push(
                <MultiSelectControl
                    required
                    key={'conditionForm-2'}
                    label={t('stock.conditionProducts')}
                    name={fieldNames.conditionProducts}
                    placeholder={t('stock.conditionProducts.placeholder')}
                    value={formState?.condition?.conditionProducts?.map(p => p.value) || []}
                    options={productsPromotionOptions}
                    onChange={changeConditionProductsMultiSelectHandler}
                    error={getError(fieldNames.conditionProducts)}
                />)
        }

        if (isConditionCostType()) {
            items.push(
                <Form.Input
                    required
                    key={'conditionForm-3'}
                    value={formState?.condition?.cost || ''}
                    type='number'
                    onChange={changeConditionInputHandler}
                    label={t('stock.cost')}
                    name={fieldNames.cost}
                    placeholder={t('stock.cost.placeholder')}
                    error={getError(fieldNames.cost)}
                />)
        }

        if (isConditionDeliveryType()) {
            items.push(
                <SelectControl
                    required
                    key={'conditionForm-4'}
                    label={t('stock.deliveryOrderType')}
                    name={fieldNames.deliveryOrderType}
                    placeholder={t('stock.deliveryOrderType.placeholder')}
                    value={formState?.condition?.deliveryOrderType?.value || ''}
                    options={deliveryOrderTypeOptions}
                    onChange={changeConditionDeliveryTypeSelectHandler}
                    error={getError(fieldNames.deliveryOrderType)}
                />)
        }

        return items
    }

    const getRewardFormItems = (): Array<JSX.Element> => {
        const items: Array<JSX.Element> = []

        items.push(
            <SelectControl
                required
                key={'rewardForm-0'}
                label={t('stock.rewardType')}
                name={fieldNames.rewardType}
                placeholder={t('stock.rewardType.placeholder')}
                value={formState?.reward?.rewardType?.value || ''}
                options={stockRewardTypeOptions}
                onChange={changeRewardSelectHandler}
                error={getError(fieldNames.rewardType)}
            />)

        if (isRewardProductsType()) {
            items.push(
                <MultiSelectControl
                    required
                    key={'rewardForm-1'}
                    label={t('stock.rewardProducts')}
                    name={fieldNames.rewardProducts}
                    placeholder={t('stock.rewardProducts.placeholder')}
                    value={formState?.reward?.rewardProducts?.map(p => p.value) || []}
                    options={productsPromotionOptions}
                    onChange={changeRewardProductsMultiSelectHandler}
                    error={getError(fieldNames.rewardProducts)}
                />)

            items.push(
                <Form.Input
                    required
                    key={'rewardForm-2'}
                    value={formState?.reward?.numberProducts || ''}
                    type='number'
                    name={fieldNames.numberProducts}
                    onChange={changeRewardInputHandler}
                    label={t('stock.numberProducts')}
                    placeholder={t('stock.numberProducts.placeholder')}
                    error={getError(fieldNames.numberProducts)}
                />)
        }

        if (isRewardDiscountType()) {
            items.push(
                <Form.Input
                    required
                    key={'rewardForm-3'}
                    value={formState?.reward?.discount || ''}
                    type='number'
                    name={fieldNames.discount}
                    onChange={changeRewardInputHandler}
                    label={t('stock.discount')}
                    placeholder={t('stock.discount.placeholder')}
                    error={getError(fieldNames.discount)}
                />)
        }

        return items
    }

    const branchResource = resources[fieldNames.branchId]
    const branchOptions: Array<DropdownItemProps> = useMemo(() => branchResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [branchResource])

    const stockConditionTypeResource = resources[fieldNames.conditionType]
    const stockConditionTypeOptions: Array<DropdownItemProps> = useMemo(() => stockConditionTypeResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [stockConditionTypeResource])

    const productsPromotionResource = resources[productsPromotionResourceName]
    const productsPromotionOptions: Array<DropdownItemProps> = useMemo(() => productsPromotionResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [productsPromotionResource])

    const stockRewardTypeResource = resources[fieldNames.rewardType]
    const stockRewardTypeOptions: Array<DropdownItemProps> = useMemo(() => stockRewardTypeResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [stockRewardTypeResource])

    const stockDurationTypeResource = resources[fieldNames.durationType]
    const stockDurationTypeOptions: Array<DropdownItemProps> = useMemo(() => stockDurationTypeResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [stockDurationTypeResource])

    const deliveryOrderTypeResource = resources[fieldNames.deliveryOrderType]
    const deliveryOrderTypeOptions: Array<DropdownItemProps> = useMemo(() => deliveryOrderTypeResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [deliveryOrderTypeResource])

    // indexes:
    // 0: основные данные акции
    // 1: настройка продолжительности
    // 2: настройка условия активации
    // 3: настройка вознаграждения
    type AccordionToggleType = [boolean, boolean, boolean, boolean]
    const [accordionToggleState, setAccordionToggleState] = useState<AccordionToggleType>([true, true, true, true])
    const accordionToggleHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, data: AccordionTitleProps) => {
        setAccordionToggleState(prev => {
            const toggles: AccordionToggleType = [...prev]
            const idx: number = data.index as number
            toggles[idx] = !toggles[idx]

            return toggles
        })
    }

    const isConditionProductsType = () => formState?.condition?.conditionType?.value == StockConditionType.ProductsCondition.toString()
    const isConditionCostType = () => formState?.condition?.conditionType?.value == StockConditionType.CostCondition.toString()
    const isConditionDeliveryType = () => formState?.condition?.conditionType?.value == StockConditionType.DeliveryOrderCondition.toString()

    const isRewardDiscountType = () => formState?.reward?.rewardType?.value == PromocodeRewardType.Discount.toString()
    const isRewardProductsType = () => formState?.reward?.rewardType?.value == PromocodeRewardType.Products.toString()

    return (
        <div className='containerWithFooterMenu'>
            <Segment attached='top'>
                <Header as='h3' className={'segmentHeader'}>
                    {getHeaderText()}
                </Header>
            </Segment>
            <Segment
                attached='bottom'
                loading={isLoadingForm()}>
                <Accordion styled fluid>
                    <Accordion.Title
                        active={accordionToggleState[0]}
                        index={0}
                        onClick={accordionToggleHandler}
                    >
                        <Icon name='dropdown' />
                        {t('stock.main.segment')}
                    </Accordion.Title>
                    <Accordion.Content active={accordionToggleState[0]}>
                        <Form>
                            <div className={'container-grid-auto-fit'}>
                                {getMainFormItems()}
                            </div>
                        </Form>
                    </Accordion.Content>

                    <Accordion.Title
                        active={accordionToggleState[1]}
                        index={1}
                        onClick={accordionToggleHandler}
                    >
                        <Icon name='dropdown' />
                        {t('stock.duration.segment')}
                    </Accordion.Title>
                    <Accordion.Content active={accordionToggleState[1]}>
                        {getDurationFormItems()}
                    </Accordion.Content>

                    <Accordion.Title
                        active={accordionToggleState[2]}
                        index={2}
                        onClick={accordionToggleHandler}
                    >
                        <Icon name='dropdown' />
                        {t('stock.condition.segment')}
                    </Accordion.Title>
                    <Accordion.Content active={accordionToggleState[2]}>
                        <Form>
                            <div className={'container-grid-auto-fit'}>
                                {getConditionFormItems()}
                            </div>
                        </Form>
                    </Accordion.Content>

                    <Accordion.Title
                        active={accordionToggleState[3]}
                        index={3}
                        onClick={accordionToggleHandler}
                    >
                        <Icon name='dropdown' />
                        {t('stock.reward.segment')}
                    </Accordion.Title>
                    <Accordion.Content active={accordionToggleState[3]}>
                        <Form>
                            <div className={'container-grid-auto-fit'}>
                                {getRewardFormItems()}
                            </div>
                        </Form>
                    </Accordion.Content>
                </Accordion>
            </Segment>
            <FooterMenu
                onCancel={cancelHandler}
                onSave={saveHandler}
                saveDisabled={isLoadingForm()}
                cancelDisabled={isLoadingForm()}
            />
        </div>)
}
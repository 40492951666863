import { AnyAction } from 'redux'
import { IGetDictionaryPayload } from '../../domain/pages/dictionaries/IGetDictionaryPayload'
import { IValidationResult } from '../../domain/validationResult/IValidationResult';
import { IGetByIdDictionaryPayload } from '../../domain/pages/dictionaries/IGetByIdDictionaryPayload'
import { IAppResult } from '../../domain/appResult/IAppResult'
import { IDeleteDictionaryPayload } from '../../domain/pages/dictionaries/IDeleteDictionaryPayload'

export const FETCH_GET_DICTIONARY_REQUEST = 'FETCH_GET_DICTIONARY_REQUEST'
export const FETCH_GET_DICTIONARY_SUCCESS = 'FETCH_GET_DICTIONARY_SUCCESS'
export const FETCH_GET_DICTIONARY_FAILURE = 'FETCH_GET_DICTIONARY_FAILURE'

export const FETCH_GET_BY_ID_DICTIONARY_REQUEST = 'FETCH_GET_BY_ID_DICTIONARY_REQUEST'
export const FETCH_GET_BY_ID_DICTIONARY_SUCCESS = 'FETCH_GET_BY_ID_DICTIONARY_SUCCESS'
export const FETCH_GET_BY_ID_DICTIONARY_FAILURE = 'FETCH_GET_BY_ID_DICTIONARY_FAILURE'

export const FETCH_SAVE_FORM_DICTIONARY_REQUEST = 'FETCH_SAVE_FORM_DICTIONARY_REQUEST'
export const FETCH_SAVE_FORM_DICTIONARY_SUCCESS = 'FETCH_SAVE_FORM_DICTIONARY_SUCCESS'
export const FETCH_SAVE_FORM_DICTIONARY_FAILURE = 'FETCH_SAVE_FORM_DICTIONARY_FAILURE'
export const CLEAR_DICTIONARY_FORM_SAVING_RESULT = 'CLEAR_DICTIONARY_FORM_SAVING_RESULT'

export const FETCH_DELETE_DICTIONARY_ITEMS_REQUEST = 'FETCH_DELETE_DICTIONARY_ITEMS_REQUEST'
export const FETCH_DELETE_DICTIONARY_ITEMS_SUCCESS = 'FETCH_DELETE_DICTIONARY_ITEMS_SUCCESS'
export const FETCH_DELETE_DICTIONARY_ITEMS_FAILURE = 'FETCH_DELETE_DICTIONARY_ITEMS_FAILURE'

export const getDictionaryRequest = (payload: IGetDictionaryPayload): AnyAction => {
    return {
        type: FETCH_GET_DICTIONARY_REQUEST,
        payload
    }
}

export const getDictionarySuccess = (payload: Array<any>): AnyAction => {
    return {
        type: FETCH_GET_DICTIONARY_SUCCESS,
        payload
    }
}

export const getDictionaryFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_GET_DICTIONARY_FAILURE,
        payload
    }
}

export const getByIdDictionaryRequest = (payload: IGetByIdDictionaryPayload): AnyAction => {
    return {
        type: FETCH_GET_BY_ID_DICTIONARY_REQUEST,
        payload
    }
}

export const getByIdDictionarySuccess = (payload: any): AnyAction => {
    return {
        type: FETCH_GET_BY_ID_DICTIONARY_SUCCESS,
        payload
    }
}

export const getByIdDictionaryFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_GET_BY_ID_DICTIONARY_FAILURE,
        payload
    }
}

export const saveDictionaryFormRequest = (payload: any): AnyAction => {
    return {
        type: FETCH_SAVE_FORM_DICTIONARY_REQUEST,
        payload
    }
}

export const saveDictionaryFormSuccess = (payload: IValidationResult): AnyAction => {
    return {
        type: FETCH_SAVE_FORM_DICTIONARY_SUCCESS,
        payload
    }
}

export const saveDictionaryFormFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_SAVE_FORM_DICTIONARY_FAILURE,
        payload
    }
}

export const clearDictionaryFormSavingResult = (): AnyAction => {
    return {
        type: CLEAR_DICTIONARY_FORM_SAVING_RESULT
    }
}

export const deleteDictionaryItemRequest = (payload: IDeleteDictionaryPayload): AnyAction => {
    return {
        type: FETCH_DELETE_DICTIONARY_ITEMS_REQUEST,
        payload
    }
}

export const deleteDictionaryItemSuccess = (payload: IAppResult): AnyAction => {
    return {
        type: FETCH_DELETE_DICTIONARY_ITEMS_SUCCESS,
        payload
    }
}

export const deleteDictionaryItemFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_DELETE_DICTIONARY_ITEMS_FAILURE,
        payload
    }
}
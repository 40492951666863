import { all, takeEvery } from 'redux-saga/effects'
import {
    FETCH_DELETE_DICTIONARY_ITEMS_REQUEST,
    FETCH_GET_BY_ID_DICTIONARY_REQUEST,
    FETCH_GET_DICTIONARY_REQUEST,
    FETCH_SAVE_FORM_DICTIONARY_REQUEST
} from '../../../store/dictionaries/actions'
import { dictionaryDeleteItemsSaga } from './dictionaryDeleteItemsSaga'
import { getByIdDictionarySaga } from './getByIdDictionarySaga'
import { getDictionarySaga } from './getDictionarySaga'
import { saveDictionaryFormSaga } from './saveDictionaryFormSaga'

export function* saga() {
    yield all([
        takeEvery(FETCH_GET_DICTIONARY_REQUEST, getDictionarySaga),
        takeEvery(FETCH_GET_BY_ID_DICTIONARY_REQUEST, getByIdDictionarySaga),
        takeEvery(FETCH_SAVE_FORM_DICTIONARY_REQUEST, saveDictionaryFormSaga),
        takeEvery(FETCH_DELETE_DICTIONARY_ITEMS_REQUEST, dictionaryDeleteItemsSaga)
    ])
}
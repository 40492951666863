import { IValidationResult } from '../../domain/validationResult/IValidationResult';
import { IProfileForm } from './../../domain/pages/profile/IProfileForm';
import { AnyAction } from 'redux'

export const FETCH_LOAD_PROFILE_REQUEST = 'FETCH_LOAD_PROFILE_REQUEST'
export const FETCH_LOAD_PROFILE_SUCCESS = 'FETCH_LOAD_PROFILE_SUCCESS'
export const FETCH_LOAD_PROFILE_FAILURE = 'FETCH_LOAD_PROFILE_FAILURE'

export const FETCH_SAVE_PROFILE_REQUEST = 'FETCH_SAVE_PROFILE_REQUEST'
export const FETCH_SAVE_PROFILE_SUCCESS = 'FETCH_SAVE_PROFILE_SUCCESS'
export const FETCH_SAVE_PROFILE_FAILURE = 'FETCH_SAVE_PROFILE_FAILURE'

export const CLEAR_PROFILE_SAVING_RESULT = 'CLEAR_PROFILE_SAVING_RESULT'

export const loadProfileRequest = (): AnyAction => {
    return {
        type: FETCH_LOAD_PROFILE_REQUEST
    }
}

export const loadProfileSuccess = (payload: IProfileForm): AnyAction => {
    return {
        type: FETCH_LOAD_PROFILE_SUCCESS,
        payload
    }
}

export const loadProfileFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_LOAD_PROFILE_FAILURE,
        payload
    }
}

export const saveProfileRequest = (payload: IProfileForm): AnyAction => {
    return {
        type: FETCH_SAVE_PROFILE_REQUEST,
        payload
    }
}

export const saveProfileSuccess = (payload: IValidationResult): AnyAction => {
    return {
        type: FETCH_SAVE_PROFILE_SUCCESS,
        payload
    }
}

export const saveProfileFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_SAVE_PROFILE_FAILURE,
        payload
    }
}

export const clearProfileSavingResult = (): AnyAction => {
    return {
        type: CLEAR_PROFILE_SAVING_RESULT
    }
}
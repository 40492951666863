import { Dictionaries } from './../../domain/navigation/dictionaries';
import { AnyAction } from 'redux'
import { Pages } from '../../domain/navigation/pages'

export const CHANGE_NAVIGATE_PAGE = 'CHANGE_NAVIGATE_PAGE'
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'

export const changeNavigatePage = (payload: Pages | Dictionaries): AnyAction => {
    return {
        type: CHANGE_NAVIGATE_PAGE,
        payload
    }
}

export const toggleSidebar = (payload: boolean): AnyAction => {
    return {
        type: TOGGLE_SIDEBAR,
        payload
    }
}
import { appConfigRequest } from '../../../store/appConfig/actions';
import { IAuth } from '../../../domain/pages/auth/IAuth'
import { put } from '@redux-saga/core/effects'
import { AnyAction } from "@reduxjs/toolkit"
import { loginFailure, loginSuccess } from "../../../store/auth/actions"
import { postman, setAccessToken } from '../../../utils/postman'
import { LOGIN_REQUEST } from '../../../api'
import { ACCESS_TOKEN } from '../../../domain/constants'

export function* loginSaga(action: AnyAction): any {    
    try {
        const form: IAuth = action.payload
        const token = yield postman.post(LOGIN_REQUEST, form)
        
        localStorage.setItem(ACCESS_TOKEN, token);
        setAccessToken(token)
        
        yield put(loginSuccess())
        yield put(appConfigRequest())
    } catch (ex: any) {
        yield put(loginFailure(ex.response.data))
    }
}

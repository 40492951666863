import { LOGOUT } from '../../../store/auth/actions';
import {
    all,
    takeEvery
} from 'redux-saga/effects'
import { FETCH_LOGIN_REQUEST } from '../../../store/auth/actions'
import { loginSaga } from './loginSaga'
import { logoutSaga } from './logoutSaga'

export function* saga() {
    yield all([
        takeEvery(FETCH_LOGIN_REQUEST, loginSaga),
        takeEvery(LOGOUT, logoutSaga)
    ])
}

import { saveAffiliateProgramFormSuccess, saveAffiliateProgramFormFailure } from './../../../../store/promotion/affiliatePrograms/actions';
import { SAVE_AFFILIATE_PROGRAMS } from './../../../../api/requestEndpoints';
import { AnyAction } from "@reduxjs/toolkit"
import { put } from '@redux-saga/core/effects'
import { IValidationResult } from "../../../../domain/validationResult/IValidationResult"
import { postman } from "../../../../utils/postman"

export function* saveFormSaga(action: AnyAction): any {    
    try {
        const form = action.payload
        const url = SAVE_AFFILIATE_PROGRAMS
        const result: IValidationResult = yield postman.post(url, form)
        
        yield put(saveAffiliateProgramFormSuccess(result))
    } catch (ex: any) {
        yield put(saveAffiliateProgramFormFailure(ex.response.data))
    }
}
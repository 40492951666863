import { AnyAction } from '@reduxjs/toolkit'
import { put } from '@redux-saga/core/effects'
import { postman } from '../../../utils/postman'
import { GOODS_GET_REQUEST, GOODS_GET_WITHOUT_PARENT_REQUEST } from '../../../api'
import { getGoodsFailure, getGoodsSuccess } from '../../../store/goods/actions'

export function* getGoodsSaga(action: AnyAction): any {
    try {
        const { branchId, categoryId } = action.payload

        if(branchId || categoryId) {
            const url = categoryId
                ? `${GOODS_GET_REQUEST}/${categoryId}`
                : `${GOODS_GET_WITHOUT_PARENT_REQUEST}/${branchId}`
            const goods = yield postman.get(url)
    
            yield put(getGoodsSuccess(goods))
        } else {
            yield put(getGoodsFailure(''))
        }
    } catch (ex: any) {
        yield put(getGoodsFailure(ex.response.data))
    }
}
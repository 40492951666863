import { Dictionaries } from './../../../domain/navigation/dictionaries'
import { AnyAction } from '@reduxjs/toolkit'
import { put } from '@redux-saga/core/effects'
import { postman } from '../../../utils/postman'
import { getDictionaryFailure, getDictionarySuccess } from '../../../store/dictionaries/actions'
import { getEndpointsForDictionaryGetter } from '../../../api/endpointsHelper'

export function* getDictionarySaga(action: AnyAction): any {
    try {
        const dictionary: Dictionaries = action.payload.dictionary
        const endpointsGetter = getEndpointsForDictionaryGetter(dictionary)
        const url = endpointsGetter.endpointGet()
        const items = yield postman.post(url)

        yield put(getDictionarySuccess(items))
    } catch (ex: any) {
        yield put(getDictionaryFailure(ex.response.data))
    }
}
import React from "react"
import { DesktopContainer } from "./DesktopContainer"
import './index.css'
import { MediaContextProvider } from "../../utils/media"
import { MobileContainer } from "./MobileContainer"

export const AuthPage: React.FC = () => {
    return (
        <MediaContextProvider>
            <DesktopContainer/>
            <MobileContainer/>
        </MediaContextProvider>
    )
}
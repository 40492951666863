import { AnyAction } from 'redux'
import { IPushNotificationDto } from '../../../domain/pages/promotion/pushNotification/IPushNotificationDto'
import { IValidationResult } from '../../../domain/validationResult/IValidationResult'

export const FETCH_SEND_PUSH_NOTIFICATION_REQUEST = 'FETCH_SEND_PUSH_NOTIFICATION_REQUEST'
export const FETCH_SEND_PUSH_NOTIFICATION_SUCCESS = 'FETCH_SEND_PUSH_NOTIFICATION_SUCCESS'
export const FETCH_SEND_PUSH_NOTIFICATION_FAILURE = 'FETCH_SEND_PUSH_NOTIFICATION_FAILURE'
export const CLEAR_SEND_PUSH_NOTIFICATION_RESULT = 'CLEAR_SEND_PUSH_NOTIFICATION_RESULT'

export const sendPushNotificationRequest = (payload: IPushNotificationDto): AnyAction => {
    return {
        type: FETCH_SEND_PUSH_NOTIFICATION_REQUEST,
        payload
    }
}

export const sendPushNotificationSuccess = (payload: IValidationResult): AnyAction => {
    return {
        type: FETCH_SEND_PUSH_NOTIFICATION_SUCCESS,
        payload
    }
}

export const sendPushNotificationFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_SEND_PUSH_NOTIFICATION_FAILURE,
        payload
    }
}

export const clearSendPushNotificationResult = (): AnyAction => {
    return {
        type: CLEAR_SEND_PUSH_NOTIFICATION_RESULT
    }
}
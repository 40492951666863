import React from "react"
import { Form } from "semantic-ui-react"
import { useTranslation } from 'react-i18next'
import { ISelectControl } from "../../../domain/components/form/dictionaries/ISelectControl"

const SelectControl: React.FC<ISelectControl> = ({
    required,
    label,
    name,
    value,
    options,
    error,
    placeholder,
    onChange,
    disabled
}) => {
    const { t } = useTranslation()

    return <Form.Dropdown
        disabled = {disabled}
        clearable
        selection fluid search
        required={required}
        placeholder={placeholder}
        value={value}
        name={name}
        options={options}
        label={label}
        onChange={onChange}
        noResultsMessage={t('dropdownNotResultMessage')}
        error={error}
    />
}

export default SelectControl
import React from "react"
import { Route, Redirect, Switch } from 'react-router-dom'
import { AuthPage } from "../pages/auth/AuthPage"

export const AuthRouts: React.FC = () => (
    <Switch>
        <Route path="/" exact>
            <AuthPage />
        </Route>
        <Redirect to="/" />
    </Switch>
)
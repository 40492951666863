import { AnyAction } from 'redux'
import { IAuth } from '../../domain/pages/auth/IAuth'

export const FETCH_LOGIN_REQUEST = 'FETCH_LOGIN_REQUEST'
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS'
export const FETCH_LOGIN_FAILURE = 'FETCH_LOGIN_FAILURE'

export const LOGOUT = 'LOGOUT'
export const DEFAULT_STATE = 'DEFAULT_STATE'
export const REDIRECT_FROM_FORBIDDEN = 'REDIRECT_FROM_FORBIDDEN'

export const login = (payload: IAuth): AnyAction => {
    return {
        type: FETCH_LOGIN_REQUEST,
        payload
    }
}

export const loginSuccess = (): AnyAction => {
    return {
        type: FETCH_LOGIN_SUCCESS
    }
}

export const loginFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_LOGIN_FAILURE,
        payload
    }
}

export const logout = (): AnyAction => {
    return {
        type: LOGOUT,
    }
}

export const setDefState = (): AnyAction => {
    return {
        type: DEFAULT_STATE,
    }
}
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, Image, Input, Icon, Form, Popup, Modal, Segment, Dimmer, Loader } from 'semantic-ui-react'
import Cropper from 'react-easy-crop'
import { Point, Area } from 'react-easy-crop/types'
import './style.css'
import { readFile, getCroppedImg, blobToFile } from '../../utils/fileHelper'
import { useDispatchAction, useSelectorState } from '../../hooks/reduxHook'
import { setDefaultFileState, uploadFileRequest } from '../../store/file/actions'
import { toast } from 'react-toastify'
import { IFileDto } from '../../domain/general/IFileDto'
import { IImageInputProps } from '../../domain/components/ImageInput/IImageInputProps'

export const ImageInput: React.FC<IImageInputProps> = (props) => {
    const dispatch = useDispatchAction()
    const { t } = useTranslation()
    const isFetching = useSelectorState(state => state.file.isFetching)
    const fetchError = useSelectorState(state => state.file.error)
    const fileDto = useSelectorState(state => state.file.file)

    const fileInputRef = useRef<HTMLInputElement>(null)
    const [isImageProcessing, setImageProcessing] = useState(false)
    const [imageSrc, setImageSrc] = useState<string>()

    const fileChangeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        const file = event.currentTarget.files?.length ? event.currentTarget.files[0] : null

        if (!file)
            return

        const imageDataUrl: string = await readFile(file)
        setImageSrc(imageDataUrl)

        if (fileInputRef.current)
            fileInputRef.current.value = '' // clear file input
    }

    useEffect(() => {
        if (imageSrc)
            setToggleCropDialog(true)
    }, [imageSrc])

    useEffect(() => {
        if (fileDto) {
            changeFileHandler(fileDto)
            toast.success(t('image.upload.success'))
            closeCropDialogHandler()
        }

    }, [fileDto])

    useEffect(() => {
        if (fetchError) {
            toast.error(fetchError)
            closeCropDialogHandler()
        }
    }, [fetchError])

    const changeFileHandler = (file: IFileDto | null) => props.onChange(file)

    const openFileDialogHandler = () => fileInputRef.current?.click()

    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>()

    const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const [isOpenCropDialog, setToggleCropDialog] = React.useState(false)

    const uploadImageHandler = async () => {
        setImageProcessing(true)
        const blob = await getCroppedImg(imageSrc as string, croppedAreaPixels as Area)
        setImageProcessing(false)

        if (blob) {
            const file = blobToFile(blob, 'image.jpg')

            dispatch(uploadFileRequest(file))
        } else {
            toast.error(t('errorSaveFile'))

            closeCropDialogHandler()
        }
    }

    const closeCropDialogHandler = () => {
        setToggleCropDialog(false)
        setImageSrc(undefined)
        setCrop({ x: 0, y: 0 })
        setZoom(1)
        dispatch(setDefaultFileState())
    }

    return (
        <>
            <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                hidden
                onChange={fileChangeHandler}
            />
            <Form.Input
                required={props.required}
                readOnly={true}
                value={props.value ? t('loaded') : t('notLoaded')}
                label={t('image')}
                error={props.error}
                action
            >
                <input />
                <Popup
                    content={t('uploadImageDescription')}
                    position='bottom right'
                    trigger={
                        <Button
                            icon
                            type='button'
                            onClick={openFileDialogHandler}
                        >
                            <Icon name='upload' />
                        </Button>
                    }
                />
                <Popup
                    content={t('deleteImageDescription')}
                    position='bottom right'
                    trigger={
                        <Button
                            icon
                            type='button'
                            disabled={!props.value}
                            onClick={() => changeFileHandler(null)}
                        >
                            <Icon name='trash' />
                        </Button>
                    }
                />
            </Form.Input>
            <Modal open={isOpenCropDialog}>
                <Modal.Header>{t('image.crop.prepare')}</Modal.Header>
                <Modal.Content className='crop-container'>
                    {
                        (isFetching || isImageProcessing) &&
                        <Dimmer active inverted>
                            <Loader inverted />
                        </Dimmer>
                    }
                    <Cropper
                        image={imageSrc}
                        crop={crop}
                        zoom={zoom}
                        aspect={props.aspectRatio}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={closeCropDialogHandler}>{t('button.close')}</Button>
                    <Button onClick={uploadImageHandler} positive disabled={isFetching || isImageProcessing}>
                        {t('image.crop.save')}
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    )
}
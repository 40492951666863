import { AnyAction } from 'redux'
import { IAppResult } from '../../../domain/appResult/IAppResult'
import { IAffiliateProgramDto } from '../../../domain/pages/promotion/affiliatePrograms/IAffiliateProgramDto'
import { IValidationResult } from '../../../domain/validationResult/IValidationResult'


export const FETCH_GET_AFFILIATE_PROGRAMS_REQUEST = 'FETCH_GET_AFFILIATE_PROGRAMS_REQUEST'
export const FETCH_GET_AFFILIATE_PROGRAMS_SUCCESS = 'FETCH_GET_AFFILIATE_PROGRAMS_SUCCESS'
export const FETCH_GET_AFFILIATE_PROGRAMS_FAILURE = 'FETCH_GET_AFFILIATE_PROGRAMS_FAILURE'

export const FETCH_GET_BY_ID_AFFILIATE_PROGRAM_REQUEST = 'FETCH_GET_BY_ID_AFFILIATE_PROGRAM_REQUEST'
export const FETCH_GET_BY_ID_AFFILIATE_PROGRAM_SUCCESS = 'FETCH_GET_BY_ID_AFFILIATE_PROGRAM_SUCCESS'
export const FETCH_GET_BY_ID_AFFILIATE_PROGRAM_FAILURE = 'FETCH_GET_BY_ID_AFFILIATE_PROGRAM_FAILURE'

export const FETCH_SAVE_FORM_AFFILIATE_PROGRAM_REQUEST = 'FETCH_SAVE_FORM_AFFILIATE_PROGRAM_REQUEST'
export const FETCH_SAVE_FORM_AFFILIATE_PROGRAM_SUCCESS = 'FETCH_SAVE_FORM_AFFILIATE_PROGRAM_SUCCESS'
export const FETCH_SAVE_FORM_AFFILIATE_PROGRAM_FAILURE = 'FETCH_SAVE_FORM_AFFILIATE_PROGRAM_FAILURE'
export const CLEAR_AFFILIATE_PROGRAM_FORM_SAVING_RESULT = 'CLEAR_AFFILIATE_PROGRAM_FORM_SAVING_RESULT'

export const FETCH_DELETE_AFFILIATE_PROGRAM_ITEMS_REQUEST = 'FETCH_DELETE_AFFILIATE_PROGRAM_ITEMS_REQUEST'
export const FETCH_DELETE_AFFILIATE_PROGRAM_ITEMS_SUCCESS = 'FETCH_DELETE_AFFILIATE_PROGRAM_ITEMS_SUCCESS'
export const FETCH_DELETE_AFFILIATE_PROGRAM_ITEMS_FAILURE = 'FETCH_DELETE_AFFILIATE_PROGRAM_ITEMS_FAILURE'

export const getAffiliateProgramsRequest = (): AnyAction => {
    return {
        type: FETCH_GET_AFFILIATE_PROGRAMS_REQUEST
    }
}

export const getAffiliateProgramsSuccess = (payload: Array<IAffiliateProgramDto>): AnyAction => {
    return {
        type: FETCH_GET_AFFILIATE_PROGRAMS_SUCCESS,
        payload
    }
}

export const getAffiliateProgramsFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_GET_AFFILIATE_PROGRAMS_FAILURE,
        payload
    }
}

/**
 * 
 * @param payload promocode id
 * @returns 
 */
export const getByIdAffiliateProgramRequest = (payload: string | undefined): AnyAction => {
    return {
        type: FETCH_GET_BY_ID_AFFILIATE_PROGRAM_REQUEST,
        payload
    }
}

export const getByIdAffiliateProgramSuccess = (payload: IAffiliateProgramDto): AnyAction => {
    return {
        type: FETCH_GET_BY_ID_AFFILIATE_PROGRAM_SUCCESS,
        payload
    }
}

export const getByIdAffiliateProgramFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_GET_BY_ID_AFFILIATE_PROGRAM_FAILURE,
        payload
    }
}

export const saveAffiliateProgramFormRequest = (payload: IAffiliateProgramDto): AnyAction => {
    return {
        type: FETCH_SAVE_FORM_AFFILIATE_PROGRAM_REQUEST,
        payload
    }
}

export const saveAffiliateProgramFormSuccess = (payload: IValidationResult): AnyAction => {
    return {
        type: FETCH_SAVE_FORM_AFFILIATE_PROGRAM_SUCCESS,
        payload
    }
}

export const saveAffiliateProgramFormFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_SAVE_FORM_AFFILIATE_PROGRAM_FAILURE,
        payload
    }
}

export const clearAffiliateProgramFormSavingResult = (): AnyAction => {
    return {
        type: CLEAR_AFFILIATE_PROGRAM_FORM_SAVING_RESULT
    }
}

/**
 * 
 * @param payload список индентификаторов для удаления
 * @returns 
 */
export const deleteAffiliateProgramItemRequest = (payload: Array<string>): AnyAction => {
    return {
        type: FETCH_DELETE_AFFILIATE_PROGRAM_ITEMS_REQUEST,
        payload
    }
}

export const deleteAffiliateProgramItemSuccess = (payload: IAppResult): AnyAction => {
    return {
        type: FETCH_DELETE_AFFILIATE_PROGRAM_ITEMS_SUCCESS,
        payload
    }
}

export const deleteAffiliateProgramItemFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_DELETE_AFFILIATE_PROGRAM_ITEMS_FAILURE,
        payload
    }
}
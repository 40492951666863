import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Accordion, AccordionTitleProps, CheckboxProps, DropdownItemProps, DropdownProps, Form, Header, Icon, Message, Segment } from 'semantic-ui-react'
import { AFFILIATE_REWARD_TYPES_RESOURCE, BRANCHES_RESOURCE, PRODUCTS_PROMOTION_RESOURCE, PROMOMCODE_CONDITION_TYPES_RESOURCE, PROMOMCODE_REWARD_TYPES_RESOURCE } from '../../../api'
import { FooterMenu } from '../../../components/footerMenu/FooterMenu'
import { ILookUpDto } from '../../../domain/general/ILookUpDto'
import { IIdMatchParams } from '../../../domain/navigation/IGoodsMatchParams'
import { Pages } from '../../../domain/navigation/pages'
import { useDispatchAction, useSelectorState } from '../../../hooks/reduxHook'
import { changeNavigatePage } from '../../../store/navigation/actions'
import { getResourcesRequest, resourcesClear } from '../../../store/resources/actions'
import ru from 'date-fns/locale/ru'

import './index.css'
import { DatePeriod } from '../../../components/DateTime/DatePeriod/DatePeriod'
import SelectControl from '../../../components/form/dictionaries/SelectControl'
import MultiSelectControl from '../../../components/form/dictionaries/MultiSelectControl'
import { AffiliateRewardType, IAffiliateProgramDto } from '../../../domain/pages/promotion/affiliatePrograms/IAffiliateProgramDto'
import { clearAffiliateProgramFormSavingResult, getByIdAffiliateProgramRequest, saveAffiliateProgramFormRequest } from '../../../store/promotion/affiliatePrograms/actions'

const fieldNames = {
    branchId: 'branchId',
    isActive: 'isActive',
    referrerRewardType: 'referrer.rewardType',
    referralRewardType: 'referral.rewardType',
    referrerRewardProduct: 'referrer.rewardProduct',
    referralRewardProduct: 'referral.rewardProduct',
    referrerRewardDiscount: 'referrer.discount',
    referralRewardDiscount: 'referral.discount',
    rewardType: 'rewardType'
}

const productsPromotionResourceName = 'productsPromotion'

export const AffiliateProgramsFormPage: React.FC<RouteComponentProps<IIdMatchParams>> = props => {
    const dispatch = useDispatchAction()
    const history = useHistory()
    const { t } = useTranslation()

    const resources = useSelectorState(state => state.resources.resources)
    const userBranchId = useSelectorState(state => state.appConfig.userBranchId)
    const saveResult = useSelectorState(state => state.affiliatePrograms.formSavingResult)
    const isGetResourcesFetching = useSelectorState(state => state.resources.isGetResourcesFetching)
    const isGetAffiliateProgramByIdFetching = useSelectorState(state => state.affiliatePrograms.isGetByIdFetching)
    const isAffiliateProgramFormSaveFetching = useSelectorState(state => state.affiliatePrograms.isFormSaveFetching)
    const form = useSelectorState(state => state.affiliatePrograms.form)
    const [formState, setFormState] = useState<IAffiliateProgramDto>()

    useEffect(() => {
        setFormState(form || undefined)
    }, [form])

    useEffect(() => {
        dispatch(changeNavigatePage(Pages.AffiliatePrograms))
        dispatch(resourcesClear())
        dispatch(getResourcesRequest({ propName: fieldNames.branchId, route: BRANCHES_RESOURCE }))

        loadProductPromotion(userBranchId)

        dispatch(getResourcesRequest({ propName: fieldNames.rewardType, route: AFFILIATE_REWARD_TYPES_RESOURCE }))
        dispatch(getByIdAffiliateProgramRequest(props.match.params.id))

        return function () {
            clearSavingValidationResult()
        }
    }, [dispatch])

    const loadProductPromotion = (branchId: string | undefined) => {
        if (branchId) {
            dispatch(getResourcesRequest({
                propName: productsPromotionResourceName,
                route: PRODUCTS_PROMOTION_RESOURCE,
                id: branchId
            }))
        }
    }

    useEffect(() => {
        if (saveResult && saveResult.isError)
            toast.error(saveResult.message)
        else if (saveResult && !saveResult.isError) {
            toast.success(saveResult.message, { autoClose: 5000 })
            goBackAffiliatePrograms()
        }
    }, [saveResult])

    const goBackAffiliatePrograms = () => history.push(`/${Pages.AffiliatePrograms}`)

    const clearSavingValidationResult = () => dispatch(clearAffiliateProgramFormSavingResult())

    const getHeaderText = () => {
        if (props.match.params.id)
            return `${t(Pages.AffiliatePrograms)}: ${t('dictionaryEditRecordHeader')}`
        else
            return `${t(Pages.AffiliatePrograms)}: ${t('dictionaryNewRecordHeader')}`
    }

    const isLoadingForm = () => isGetResourcesFetching || isGetAffiliateProgramByIdFetching || isAffiliateProgramFormSaveFetching

    const saveHandler = () => {
        let formForSave = formState as IAffiliateProgramDto

        dispatch(saveAffiliateProgramFormRequest(formForSave))
    }
    const cancelHandler = () => goBackAffiliatePrograms()

    const getError = (propName: string) => {
        if (!saveResult)
            return

        const message = saveResult.errors.find(p => p.name === propName)?.message

        return message
    }

    const changeSelectHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        if (event.type !== 'click')
            return

        const item: ILookUpDto | null = resources[data.name]
            ?.find(p => p.value === data.value)
            ?? null
        setFormState((prev: any) => ({ ...prev, [data.name as string]: item }))
    }

    useEffect(() => {
        loadProductPromotion(formState?.branchId?.value)
    }, [formState?.branchId?.value])

    const changeRadioHandler = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        setFormState((prev: any) => ({ ...prev, [data.name as string]: data.checked }))
    }

    const changeRewardTypeSelectHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        if (event.type !== 'click')
            return

        const item: ILookUpDto | null = rewardTypeResource
            ?.find(p => p.value === data.value)
            ?? null

        const rewardPropName = data.name == fieldNames.referrerRewardType
            ? 'referrerReward'
            : 'referralReward'
        const blankRewardData = {
            rewardType: null,
            rewardProduct: null,
            discount: null
        }
        setFormState((prev: any) => ({
            ...prev,
            [rewardPropName]: prev != null && prev[rewardPropName]
                ? { ...prev[rewardPropName], ...blankRewardData, rewardType: item }
                : {
                    ...blankRewardData,
                    id: null,
                    rewardType: item
                }
        }))
    }

    const changeRewardProductSelectHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        if (event.type !== 'click')
            return

        const item: ILookUpDto | null = productsPromotionResource
            ?.find(p => p.value === data.value)
            ?? null

        const rewardPropName = data.name == fieldNames.referrerRewardProduct
            ? 'referrerReward'
            : 'referralReward'

        setFormState((prev: any) => ({
            ...prev,
            [rewardPropName]: prev != null && prev[rewardPropName]
                ? { ...prev[rewardPropName], rewardProduct: item }
                : {
                    id: null,
                    rewardType: null,
                    rewardProduct: item,
                    discount: null
                }
        }))
    }

    const changeRewardDiscountInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const rewardPropName = event.target.name == fieldNames.referrerRewardDiscount
            ? 'referrerReward'
            : 'referralReward'

        setFormState((prev: any) => ({
            ...prev,
            [rewardPropName]: prev != null && prev[rewardPropName]
                ? { ...prev[rewardPropName], discount: event.target.value || null }
                : {
                    id: null,
                    rewardType: null,
                    rewardProduct: null,
                    discount: event.target.value
                }
        }))
    }

    const getMainFormItems = (): Array<JSX.Element> => {
        const items: Array<JSX.Element> = []

        if (!userBranchId) {
            items.push(
                <Form.Dropdown
                    key={'mainForm-0'}
                    required
                    clearable
                    selection fluid search
                    placeholder={t('branch.placeholder')}
                    value={formState?.branchId?.value || undefined}
                    options={branchOptions}
                    onChange={changeSelectHandler}
                    name={fieldNames.branchId}
                    label={t('branch')}
                    noResultsMessage={t('dropdownNotResultMessage')}
                    error={getError(fieldNames.branchId)}
                />)
        }

        items.push(
            <Form.Group grouped key={'mainForm-6'}>
                <label>{t(fieldNames.isActive)}</label>
                <Form.Radio
                    name={fieldNames.isActive}
                    toggle
                    className={'mt-03'}
                    checked={formState?.isActive}
                    onChange={changeRadioHandler}
                />
            </Form.Group>)

        return items
    }

    const getReferrerFormItems = (): Array<JSX.Element> => {
        const items: Array<JSX.Element> = []

        items.push(
            <SelectControl
                key={'referrerForm-0'}
                required
                label={t('affiliateProgram.rewardType')}
                name={fieldNames.referrerRewardType}
                placeholder={t('affiliateProgram.rewardType.placeholder')}
                value={formState?.referrerReward?.rewardType?.value || ''}
                options={rewardTypeOptions}
                onChange={changeRewardTypeSelectHandler}
                error={getError(fieldNames.referrerRewardType)}
            />)

        if (isRewardProductsType(formState?.referrerReward?.rewardType)) {
            items.push(
                <SelectControl
                    key={'referrerForm-1'}
                    required
                    label={t('affiliateProgram.rewardProducts')}
                    name={fieldNames.referrerRewardProduct}
                    placeholder={t('affiliateProgram.rewardProducts.placeholder')}
                    value={formState?.referrerReward?.rewardProduct?.value || ''}
                    options={productsPromotionOptions}
                    onChange={changeRewardProductSelectHandler}
                    error={getError(fieldNames.referrerRewardProduct)}
                />)

        }

        if (isRewardDiscountType(formState?.referrerReward?.rewardType)) {
            items.push(
                <Form.Input
                    key={'referrerForm-3'}
                    required
                    value={getDiscountValue(formState?.referrerReward?.discount)}
                    type='number'
                    name={fieldNames.referrerRewardDiscount}
                    onChange={changeRewardDiscountInputHandler}
                    label={t('affiliateProgram.discount')}
                    placeholder={t('affiliateProgram.discount.placeholder')}
                    error={getError(fieldNames.referrerRewardDiscount)}
                />)
        }

        return items
    }

    const getDiscountValue = (value: number | null | undefined) => {
        return value === null || value === undefined
            ? ''
            : value
    }

    const getReferralFormItems = (): Array<JSX.Element> => {
        const items: Array<JSX.Element> = []

        items.push(
            <SelectControl
                key={'referralForm-0'}
                required
                label={t('affiliateProgram.rewardType')}
                name={fieldNames.referralRewardType}
                placeholder={t('affiliateProgram.rewardType.placeholder')}
                value={formState?.referralReward?.rewardType?.value || ''}
                options={rewardTypeOptions}
                onChange={changeRewardTypeSelectHandler}
                error={getError(fieldNames.referralRewardType)}
            />)

        if (isRewardProductsType(formState?.referralReward?.rewardType)) {
            items.push(
                <SelectControl
                    key={'referralForm-1'}
                    required
                    label={t('affiliateProgram.rewardProducts')}
                    name={fieldNames.referralRewardProduct}
                    placeholder={t('affiliateProgram.rewardProducts.placeholder')}
                    value={formState?.referralReward?.rewardProduct?.value || ''}
                    options={productsPromotionOptions}
                    onChange={changeRewardProductSelectHandler}
                    error={getError(fieldNames.referralRewardProduct)}
                />)

        }

        if (isRewardDiscountType(formState?.referralReward?.rewardType)) {
            items.push(
                <Form.Input
                    key={'referralForm-3'}
                    required
                    value={getDiscountValue(formState?.referralReward?.discount)}
                    type='number'
                    name={fieldNames.referralRewardDiscount}
                    onChange={changeRewardDiscountInputHandler}
                    label={t('affiliateProgram.discount')}
                    placeholder={t('affiliateProgram.discount.placeholder')}
                    error={getError(fieldNames.referralRewardDiscount)}
                />)
        }

        return items
    }

    const branchResource = resources[fieldNames.branchId]
    const branchOptions: Array<DropdownItemProps> = useMemo(() => branchResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [branchResource])

    const productsPromotionResource = resources[productsPromotionResourceName]
    const productsPromotionOptions: Array<DropdownItemProps> = useMemo(() => productsPromotionResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [productsPromotionResource])

    const rewardTypeResource = resources[fieldNames.rewardType]
    const rewardTypeOptions: Array<DropdownItemProps> = useMemo(() => rewardTypeResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [rewardTypeResource])

    type AccordionToggleType = [boolean, boolean, boolean]
    const [accordionToggleState, setAccordionToggleState] = useState<AccordionToggleType>([true, true, true])
    const accordionToggleHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, data: AccordionTitleProps) => {
        setAccordionToggleState(prev => {
            const toggles: AccordionToggleType = [...prev]
            const idx: number = data.index as number
            toggles[idx] = !toggles[idx]

            return toggles
        })
    }

    const isRewardDiscountType = (rewardType: ILookUpDto | undefined) => rewardType?.value == AffiliateRewardType.Discount.toString()
    const isRewardProductsType = (rewardType: ILookUpDto | undefined) => rewardType?.value == AffiliateRewardType.Products.toString()

    return (
        <div className='containerWithFooterMenu'>
            <Segment attached='top'>
                <Header as='h3' className={'segmentHeader'}>
                    {getHeaderText()}
                </Header>
            </Segment>
            <Segment
                attached='bottom'
                loading={isLoadingForm()}>
                <Accordion styled fluid>
                    <Accordion.Title
                        active={accordionToggleState[0]}
                        index={0}
                        onClick={accordionToggleHandler}
                    >
                        <Icon name='dropdown' />
                        {t('affiliateProgram.main.segment')}
                    </Accordion.Title>
                    <Accordion.Content active={accordionToggleState[0]}>
                        <Form>
                            <div className={'container-grid-auto-fit'}>
                                {getMainFormItems()}
                            </div>
                        </Form>
                    </Accordion.Content>

                    <Accordion.Title

                        active={accordionToggleState[1]}
                        index={1}
                        onClick={accordionToggleHandler}
                    >
                        <Icon name='dropdown' />
                        {t('affiliateProgram.referrer.segment')}
                    </Accordion.Title>
                    <Accordion.Content active={accordionToggleState[1]}>
                        <Form>
                            <div className={'container-grid-auto-fit'}>
                                {getReferrerFormItems()}
                            </div>
                        </Form>
                    </Accordion.Content>

                    <Accordion.Title
                        active={accordionToggleState[2]}
                        index={2}
                        onClick={accordionToggleHandler}
                    >
                        <Icon name='dropdown' />
                        {t('affiliateProgram.referral.segment')}
                    </Accordion.Title>
                    <Accordion.Content active={accordionToggleState[2]}>
                        <Form>
                            <div className={'container-grid-auto-fit'}>
                                {getReferralFormItems()}
                            </div>
                        </Form>
                    </Accordion.Content>
                </Accordion>
            </Segment>
            <FooterMenu
                onCancel={cancelHandler}
                onSave={saveHandler}
                saveDisabled={isLoadingForm()}
                cancelDisabled={isLoadingForm()}
            />
        </div>)
}
import { IValidationResult } from '../../../domain/validationResult/IValidationResult';
import { AnyAction } from "@reduxjs/toolkit"
import { IProfileForm } from '../../../domain/pages/profile/IProfileForm';
import { put } from '@redux-saga/core/effects'
import { postman } from '../../../utils/postman'
import { PROFILE_SAVE_REQUEST } from '../../../api'
import { saveProfileFailure, saveProfileSuccess } from '../../../store/profile/actions'
import { appConfigRequest } from '../../../store/appConfig/actions'

export function* saveProfileSaga(action: AnyAction): any {    
    try {
        const form: IProfileForm = action.payload
        const result: IValidationResult = yield postman.post(PROFILE_SAVE_REQUEST, form)
        
        yield put(saveProfileSuccess(result))
        yield put(appConfigRequest())
    } catch (ex: any) {
        yield put(saveProfileFailure(ex.response.data))
    }
}
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Menu } from 'semantic-ui-react'
import { IFooterMenu } from '../../domain/components/footerMenu/IFooterMenu'
import './index.css'

export const FooterMenu: React.FC<IFooterMenu> = props => {
    const { t } = useTranslation()

    return (
        <Menu fixed='bottom' size='large' className='menu-footer' borderless>
            <Menu.Menu  className="menu-footer-position">
                <Menu.Item fitted>
                    <Button
                        onClick={props.onCancel}
                        loading={props.cancelProcessing}
                        disabled={!!props.cancelDisabled}
                    >
                        {t('cancel')}
                    </Button>
                </Menu.Item>
                <Menu.Item fitted>
                    <Button
                        primary
                        onClick={props.onSave}
                        loading={props.saveProcessing}
                        disabled={!!props.saveDisabled}
                    >
                        {t('save')}
                    </Button>
                </Menu.Item>
            </Menu.Menu>
        </Menu>)
}
import React from "react"
import { Link } from "react-router-dom"
import { Menu, Dropdown, Icon, Label, Popup } from 'semantic-ui-react'
import { Pages } from "../../domain/navigation/pages"
import { useDispatchAction, useSelectorState } from "../../hooks/reduxHook"
import { useTranslation } from 'react-i18next'
import { logout } from "../../store/auth/actions"
import './index.css'
import { INavbar } from "../../domain/components/navbar/INavbar"
import { Dictionaries } from "../../domain/navigation/dictionaries"

const dictionariesExclude = [
    Dictionaries.Cashback.toString(),
    Dictionaries.InformationPosts.toString(),
    Dictionaries.AffiliatePrograms.toString()]

export const MenuItems: React.FC<INavbar> = (props) => {
    const { t } = useTranslation()
    const dispatch = useDispatchAction()
    const activeItem = useSelectorState(state => state.navigation.activeItemName)
    const { userName, userLogin, roleName, pages, dictionaries, promotions } = useSelectorState(state => state.appConfig)
    const dictionariesForRender = dictionaries?.filter(p => !dictionariesExclude.includes(p.name)) ?? []
    const cashbackPage = dictionaries?.find(p => p.name == Dictionaries.Cashback.toString())
    const informationPostsPage = dictionaries?.find(p => p.name == Dictionaries.InformationPosts.toString())
    const affiliateProgramsPostsPage = dictionaries?.find(p => p.name == Dictionaries.AffiliatePrograms.toString())
    const logoutHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (props.onLogout) {
            props.onLogout(event)
        } else {
            dispatch(logout())
        }
    }

    const existPromotionPages = () => {
        return affiliateProgramsPostsPage
            || informationPostsPage
            || cashbackPage
            || promotions.length != 0
            || pages.includes(Pages.PushNotifications)
    }

    return (
        <>
            {
                pages.includes(Pages.Orders) &&
                <Menu.Item
                    as={Link}
                    name={Pages.Orders}
                    to={`/${Pages.Orders}`}
                    active={activeItem === Pages.Orders}
                >
                    <Icon name='shopping basket' />
                    {t(Pages.Orders)}
                    {props.numberOrdersSimpleText && " (12)"}
                    {
                        !props.numberOrdersSimpleText &&
                        <Label color='blue' floating circular content={12} />
                    }

                </Menu.Item>
            }
            {
                pages.includes(Pages.Goods) &&
                <Menu.Item
                    as={Link}
                    name={Pages.Goods}
                    to={`/${Pages.Goods}`}
                    active={activeItem === Pages.Goods}
                >
                    <Icon name='shop' />
                    {t(Pages.Goods)}
                </Menu.Item>
            }
            {
                existPromotionPages() &&
                <Dropdown item
                    text={props.useInSidebar ? t(Pages.Promotion) : undefined}
                    trigger={!props.useInSidebar ? <span><Icon name='rocket' />{t(Pages.Promotion)}</span> : null}>
                    <Dropdown.Menu>
                        {
                            informationPostsPage &&
                            <Dropdown.Item
                                as={Link}
                                name={Pages.InformationPosts}
                                to={`/${Pages.InformationPosts}`}
                                active={activeItem === Pages.InformationPosts}
                            >
                                {t(informationPostsPage.name)}
                            </Dropdown.Item>
                        }
                        {
                            cashbackPage &&
                            <Dropdown.Item
                                as={Link}
                                to={`/dictionaries/${cashbackPage.name}`}
                                active={activeItem === cashbackPage.name}
                            >
                                {t(cashbackPage.name)}
                            </Dropdown.Item>
                        }
                        {
                            promotions.length != 0 &&
                            promotions.map((p, index) => (
                                <Dropdown.Item
                                    key={index.toString()}
                                    as={Link}
                                    name={p.page}
                                    to={`/${p.page}`}
                                    active={activeItem == p.page}
                                >
                                    {t(p.page)}
                                </Dropdown.Item>
                            ))

                        }
                        {
                            affiliateProgramsPostsPage &&
                            <Dropdown.Item
                                as={Link}
                                name={Pages.AffiliatePrograms}
                                to={`/${Pages.AffiliatePrograms}`}
                                active={activeItem === Pages.AffiliatePrograms}
                            >
                                {t(affiliateProgramsPostsPage.name)}
                            </Dropdown.Item>
                        }
                        {
                            pages.includes(Pages.PushNotifications) &&
                            <Dropdown.Item
                                as={Link}
                                name={Pages.PushNotifications}
                                to={`/${Pages.PushNotifications}`}
                                active={activeItem === Pages.PushNotifications}
                            >
                                {t(Pages.PushNotifications)}
                            </Dropdown.Item>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            }
            {
                pages.includes(Pages.Analytics) &&
                <Menu.Item
                    as={Link}
                    name={Pages.Analytics}
                    to={`/${Pages.Analytics}`}
                    active={activeItem === Pages.Analytics}
                >
                    <Icon name='pie chart' />
                    {t(Pages.Analytics)}
                </Menu.Item>
            }
            {
                pages.includes(Pages.Settings) &&
                <Menu.Item
                    as={Link}
                    name={Pages.Settings}
                    to={`/${Pages.Settings}`}
                    active={activeItem === Pages.Settings}
                >
                    <Icon name='settings' />
                    {t(Pages.Settings)}
                </Menu.Item>
            }
            {
                !!dictionariesForRender.length &&
                <Dropdown item
                    text={props.useInSidebar ? t('dictionaries') : undefined}
                    trigger={!props.useInSidebar ? <span><Icon name='table' />{t('dictionaries')}</span> : null}>
                    <Dropdown.Menu>
                        {
                            dictionariesForRender.map(p => (
                                <Dropdown.Item
                                    key={`dictionary_${p.name}`}
                                    as={Link}
                                    to={`/dictionaries/${p.name}`}
                                    active={activeItem === p.name}
                                >
                                    {t(p.name)}
                                </Dropdown.Item>
                            ))

                        }
                    </Dropdown.Menu>
                </Dropdown>
            }
            {
                props.useInSidebar &&
                pages.includes(Pages.Profile) &&
                <Menu.Item
                    as={Link}
                    name={Pages.Profile}
                    to={`/${Pages.Profile}`}
                    active={activeItem === Pages.Profile}
                >
                    <Icon name='user' />
                    {t(Pages.Profile)}
                </Menu.Item>
            }
            {
                props.useInSidebar &&
                <Menu.Item onClick={logoutHandler}>
                    <Icon name='sign-out' />
                    {t('logout')}
                </Menu.Item>
            }
            {
                !props.useInSidebar &&
                <Menu.Menu position='right'>
                    {
                        pages.includes(Pages.Profile) &&
                        <Popup
                            header={`${userName}(${userLogin})`}
                            content={roleName}
                            trigger={
                                <Menu.Item
                                    as={Link}
                                    name={Pages.Profile}
                                    to={`/${Pages.Profile}`}
                                    active={activeItem === Pages.Profile}
                                >

                                    <Icon name='user' />
                                    {t(Pages.Profile)}
                                </Menu.Item>
                            }
                        />
                    }
                    <Menu.Item onClick={logoutHandler}>
                        <Icon name='sign-out' />
                        {t('logout')}
                    </Menu.Item>
                </Menu.Menu>
            }
        </>
    )
}
import { AnyAction } from "@reduxjs/toolkit"
import { put } from '@redux-saga/core/effects'
import { DELETE_STOCKS } from '../../../../api'
import { postman } from "../../../../utils/postman"
import { IAppResult } from "../../../../domain/appResult/IAppResult"
import { deleteStockSuccess, deleteStockFailure } from "../../../../store/promotion/stocks/actions"

export function* stocksDeleteSaga(action: AnyAction): any {
    try {
        const ids = [action.payload]
        const url = DELETE_STOCKS
        const result: IAppResult = yield postman.post(url, ids)

        yield put(deleteStockSuccess(result))
    } catch (ex: any) {
        yield put(deleteStockFailure(ex.response.data))
    }
}
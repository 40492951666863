import { ILookUpDto } from "../../../general/ILookUpDto"

export enum AffiliateRewardType {
    Discount = 0,
    Products = 1
}

export type AffiliateRewardDto  = {
    id: string,
    rewardType: ILookUpDto,
    rewardProduct:ILookUpDto | null | undefined,
    discount: number | null | undefined
}

export interface IAffiliateProgramDto{
    id: string,
    branchId: ILookUpDto | undefined,
    referrerReward: AffiliateRewardDto,
    referralReward: AffiliateRewardDto,
    createDate: string,
    modifiedDate: string,
    isActive: boolean,
}
import { ILookUpDto } from "../../../general/ILookUpDto"

export enum PromocodeConditionType {
    WithoutCondition = 0,
    ProductsCondition = 1,
    CostCondition = 2
}

export type PromocodeConditionDto = {
    id: string,
    conditionType: ILookUpDto,
    conditionProducts: Array<ILookUpDto> | null | undefined,
    cost: number | null | undefined
}

export enum PromocodeRewardType {
    Discount = 0,
    Products = 1
}

export type PromocodeRewardDto = {
    id: string,
    rewardType: ILookUpDto,
    rewardProducts: Array<ILookUpDto> | null | undefined,
    numberProducts: number | null | undefined
    discount: number | null | undefined
}

export interface IPromocodeDto {
    id: string,
    branchId: ILookUpDto | undefined,
    name: string,
    startPeriod: string,
    endPeriod: string,
    code: string,
    numberActivations: number | undefined,
    isSingleActivation: boolean,
    isActive: boolean,
    createDate: string,
    modifiedDate: string,
    condition: PromocodeConditionDto,
    reward: PromocodeRewardDto
}
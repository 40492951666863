import { AnyAction } from '@reduxjs/toolkit'
import { put } from '@redux-saga/core/effects'
import { postman } from '../../../../utils/postman'
import { getByIdStockSuccess, getByIdStockFailure } from '../../../../store/promotion/stocks/actions'
import { GET_STOCKS } from '../../../../api'

export function* getByIdStockSaga(action: AnyAction): any {
    try {
        const id = action.payload || 'default'
        const url = `${GET_STOCKS}/${id}`
        const dto = yield postman.get(url)

        yield put(getByIdStockSuccess(dto))
    } catch (ex: any) {
        yield put(getByIdStockFailure(ex.response.data))
    }
}
import React from 'react'
import { Form } from 'semantic-ui-react'
import { IBigTextControl } from '../../../domain/components/form/dictionaries/IBigTextControl'

const BigTextControl: React.FC<IBigTextControl> = ({
    required,
    label,
    name,
    value,
    error,
    onChange,
}) => {
    return <Form.TextArea
        required = {required}
        label={label}
        name={name}
        value={value}
        error={error}
        onChange={onChange}
    />
}

export default BigTextControl
import React from "react"
import { Grid } from "semantic-ui-react"
import './index.css'
import { Media } from "../../utils/media"
import { LoginForm } from "./LoginForm"
import { LoginHeader } from "./LoginHeader"

export const DesktopContainer: React.FC = () => {
    return (
        <Media greaterThan='mobile'>
            <div className='auth-wrapper'>
                <Grid columns={2} centered divided relaxed='very' stackable>
                    <LoginForm />
                    <LoginHeader />
                </Grid>
            </div>
        </Media>
    )
}
import React from "react"
import MaskedInput from "react-text-mask"
import { Form } from "semantic-ui-react"
import { IInputControl } from "../../../domain/components/form/dictionaries/IInputControl"

const TimePeriodControl: React.FC<IInputControl> = ({
    required,
    label,
    name,
    value,
    error,
    onChange,
}) => {
    const getTimePeriodMask = (value: string) => {
        const startsFirstPeriod = value[0] === '2'
        const startsSecondPeriod = value[8] === '2'

        return [
            /[0-2]/,
            startsFirstPeriod ? /[0-3]/ : /[0-9]/,
            ':',
            /[0-5]/,
            /[0-9]/,
            ' ',
            '\u2013',
            ' ',
            /[0-2]/,
            startsSecondPeriod ? /[0-3]/ : /[0-9]/,
            ':',
            /[0-5]/,
            /[0-9]/
        ]
    }

    return <Form.Input
        required={required}
        label={label}
        error={error}
    >
        <MaskedInput
            name={name}
            mask={getTimePeriodMask}
            onChange={onChange}
            value={value}
            placeholder={`00:00 ${'\u2013'} 00:00`}
            placeholderChar={'-'}
            keepCharPositions={true}
        />
    </Form.Input>
}

export default TimePeriodControl
export enum TargetOpenType {
    /// <summary>
    /// Просто открыть приложение
    /// </summary>
    OpenApplication = 0,

    /// <summary>
    /// Открыть приложение и показать выбранную категорию товаров
    /// </summary>
    OpenCategory = 1,

    /// <summary>
    /// Открыть приложение и показать карточку выбранного продукта
    /// </summary>
    OpenProduct = 2,

    /// <summary>
    /// Открыть приложение и показать карточку выбранной акции
    /// </summary>
    OpenStock = 3,

    /// <summary>
    /// Открыть приложение и показать карточку выбранной новости
    /// </summary>
    OpenInformationPost = 4
}
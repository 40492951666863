import React from "react"
import { Card, Form, Header, Label } from "semantic-ui-react"
import { useTranslation } from 'react-i18next'
import { SortableContainer, SortableElement, SortEnd } from 'react-sortable-hoc'
import { ILookUpDto } from "../../../domain/general/ILookUpDto"
import { IMultiSelectWithOrder } from "../../../domain/components/form/dictionaries/IMultiSelectWithOrder"

const MultiSelectWithOrderControl: React.FC<IMultiSelectWithOrder> = ({
    required,
    label,
    name,
    value,
    draggableValue,
    options,
    error,
    disabled,
    onChange,
    onDragEnd
}) => {
    const { t } = useTranslation()

    const SortableLabel = SortableElement(({ name }: { name: string }) => (
        <Label className={'label-orderable margin-label'}>
            {name}
        </Label>
    ))

    const SortableCardContent = SortableContainer(({ items }: { items: Array<ILookUpDto> }) => (
        <Card.Content className={'card-content'}>
            {items.map((item, index) => (
                <SortableLabel key={`label-${index}`} index={index} name={item.name as string} />
            ))}
        </Card.Content>
    ))

    return (
        <>
            <Form.Dropdown
                disabled={disabled}
                clearable
                multiple selection fluid search
                required={required}
                value={value}
                name={name}
                options={options}
                label={label}
                onChange={onChange}
                noResultsMessage={t('dropdownNotResultMessage')}
                error={error}
            />
            {
                draggableValue && draggableValue.length > 1 &&
                <Card fluid>
                    <Card.Content>
                        <Header as='h5'>{`${t('multiSelectOrder')} "${label}"`}</Header>
                    </Card.Content>
                    <SortableCardContent
                        items={draggableValue}
                        onSortEnd={(sort: SortEnd) => onDragEnd(sort, name)}
                        axis='xy'
                        distance={1}
                    />
                </Card>
            }

        </>
    )
}

export default MultiSelectWithOrderControl
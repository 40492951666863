import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { IIdMatchParams } from '../../domain/navigation/IGoodsMatchParams'
import { Media } from '../../utils/media'
import { GoodsPageBase } from './GoodsPageBase'

export const GoodsPageMobile: React.FC<RouteComponentProps<IIdMatchParams>> = props  => {
    return (
        <Media at='mobile'>
            <GoodsPageBase {...props} itemsPerRow={1}/>
        </Media>
    )
}
import { AnyAction } from '@reduxjs/toolkit'
import { put } from '@redux-saga/core/effects'
import { postman } from '../../../utils/postman'
import { PRODUCT_MODIFICATIONS_GET_REQUEST } from '../../../api'
import { getProductModificationsFailure, getProductModificationsSuccess } from '../../../store/product/form/actions'
import { ModificationDto } from '../../../domain/pages/goods/product/IProductDto'

export function* getProductModificationsSaga(action: AnyAction): any {
    try {
        const { id, modificationIds } = action.payload
        const url = id ? `${PRODUCT_MODIFICATIONS_GET_REQUEST}/${id}` : PRODUCT_MODIFICATIONS_GET_REQUEST
        const modifications: Array<ModificationDto> | null = yield postman.post(url, modificationIds)

        yield put(getProductModificationsSuccess(modifications))
    } catch (ex: any) {
        yield put(getProductModificationsFailure(ex.response.data))
    }
}
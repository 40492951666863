import { toggleStockSuccess, toggleStockFailure } from './../../../../store/promotion/stocks/actions';
import { AnyAction } from '@reduxjs/toolkit'
import { put } from '@redux-saga/core/effects'
import { STOCK_TOGGLE_REQUEST } from '../../../../api'
import { postman } from '../../../../utils/postman'

export function* toggleStockSaga(action: AnyAction): any {
    try {
        const url = `${STOCK_TOGGLE_REQUEST}/${action.payload}`
        const deleteResult = yield postman.post(url)

        yield put(toggleStockSuccess(deleteResult))
    } catch (ex: any) {
        yield put(toggleStockFailure(ex.response.data))
    }
}
import { ICategoryDto } from '../../../domain/pages/goods/category/ICategoryDto';
import { AnyAction } from 'redux'
import { IValidationResult } from '../../../domain/validationResult/IValidationResult'

export const FETCH_GET_BY_ID_CATEGORY_REQUEST = 'FETCH_GET_BY_ID_CATEGORY_REQUEST'
export const FETCH_GET_BY_ID_CATEGORY_SUCCESS = 'FETCH_GET_BY_ID_CATEGORY_SUCCESS'
export const FETCH_GET_BY_ID_CATEGORY_FAILURE = 'FETCH_GET_BY_ID_CATEGORY_FAILURE'

export const FETCH_SAVE_CATEGORY_REQUEST = 'FETCH_SAVE_CATEGORY_REQUEST'
export const FETCH_SAVE_CATEGORY_SUCCESS = 'FETCH_SAVE_CATEGORY_SUCCESS'
export const FETCH_SAVE_CATEGORY_FAILURE = 'FETCH_SAVE_CATEGORY_FAILURE'
export const CLEAR_CATEGORY_SAVING_RESULT = 'CLEAR_CATEGORY_SAVING_RESULT'

export const getCategoryByIdRequest = (payload: string | undefined): AnyAction => {
    return {
        type: FETCH_GET_BY_ID_CATEGORY_REQUEST,
        payload
    }
}

export const getCategoryByIdSuccess = (payload: any): AnyAction => {
    return {
        type: FETCH_GET_BY_ID_CATEGORY_SUCCESS,
        payload
    }
}

export const getCategoryByIdFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_GET_BY_ID_CATEGORY_FAILURE,
        payload
    }
}

export const saveCategoryRequest = (payload: ICategoryDto): AnyAction => {
    return {
        type: FETCH_SAVE_CATEGORY_REQUEST,
        payload
    }
}

export const saveCategorySuccess = (payload: IValidationResult): AnyAction => {
    return {
        type: FETCH_SAVE_CATEGORY_SUCCESS,
        payload
    }
}

export const saveCategoryFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_SAVE_CATEGORY_FAILURE,
        payload
    }
}

export const clearCategorySavingResult = (): AnyAction => {
    return {
        type: CLEAR_CATEGORY_SAVING_RESULT
    }
}
import { all, takeEvery, takeLatest } from 'redux-saga/effects'
import { getStocksSaga } from './getStocksSaga'
import { stocksDeleteSaga } from './stocksDeleteSaga'
import { saveStockFormSaga } from './saveStockFormSaga'
import { FETCH_GET_STOCKS_REQUEST, FETCH_GET_BY_ID_STOCK_REQUEST, FETCH_SAVE_FORM_STOCK_REQUEST, FETCH_DELETE_STOCK_REQUEST, FETCH_TOGGLE_STOCK_REQUEST } from '../../../../store/promotion/stocks/actions'
import { getByIdStockSaga } from './getByIdStockSaga'
import { toggleStockSaga } from './toggleStockSaga'

export function* saga() {
    yield all([
        takeLatest(FETCH_GET_STOCKS_REQUEST, getStocksSaga),
        takeEvery(FETCH_GET_BY_ID_STOCK_REQUEST, getByIdStockSaga),
        takeEvery(FETCH_SAVE_FORM_STOCK_REQUEST, saveStockFormSaga),
        takeEvery(FETCH_DELETE_STOCK_REQUEST, stocksDeleteSaga),
        takeEvery(FETCH_TOGGLE_STOCK_REQUEST, toggleStockSaga)
    ])
}
import { put } from "@redux-saga/core/effects"
import { AnyAction } from "@reduxjs/toolkit"
import { postman } from '../../utils/postman'
import { ILookUpDto } from '../../domain/general/ILookUpDto'
import { getResourcesFailure, getResourcesSuccess } from '../../store/resources/actions'

export function* getResourcesSaga(action: AnyAction): any {
    try {
        let url = `/${action.payload.route}/lookups`

        if (action.payload.id)
            url = `${url}/${action.payload.id}`

        const resources: Array<ILookUpDto> = yield postman.get(url)

        yield put(getResourcesSuccess({
            propName: action.payload.propName,
            resources
        }))
    } catch (ex: any) {
        yield put(getResourcesFailure(ex.response.data))
    }
}

import { all, takeEvery, takeLatest } from 'redux-saga/effects'
import { FETCH_GET_BY_ID_CATEGORY_REQUEST, FETCH_SAVE_CATEGORY_REQUEST } from '../../../store/category/form/actions'
import { FETCH_DELETE_GOODS_REQUEST, FETCH_GET_GOODS_REQUEST, FETCH_TOGGLE_ACTIVE_GOODS_REQUEST, UPDATE_PRODUCTS_ORDER_NUMBER } from '../../../store/goods/actions'
import { FETCH_GET_BY_ID_PRODUCT_REQUEST, FETCH_GET_PRODUCT_MODIFICATIONS_REQUEST, FETCH_SAVE_PRODUCT_REQUEST } from '../../../store/product/form/actions'
import { deleteGoodsSaga } from './deleteGoodsSaga'
import { getCategoryFormSaga } from './getCategoryFormSaga'
import { getGoodsSaga } from './getGoodsSaga'
import { getProductFormSaga } from './getProductFormSaga'
import { getProductModificationsSaga } from './getProductModificationsSaga'
import { saveCategorySaga } from './saveCategorySaga'
import { saveProductSaga } from './saveProductSaga'
import { toggleActiveGoodsSaga } from './toggleActiveGoodsSaga'
import { updateGoodsOrderNumberSaga } from './updateGoodsOrderNumberSaga'

export function* saga() {
    yield all([
        takeLatest(FETCH_GET_GOODS_REQUEST, getGoodsSaga),
        takeEvery(FETCH_GET_BY_ID_CATEGORY_REQUEST, getCategoryFormSaga),
        takeEvery(FETCH_SAVE_CATEGORY_REQUEST, saveCategorySaga),
        takeEvery(FETCH_DELETE_GOODS_REQUEST, deleteGoodsSaga),
        takeEvery(FETCH_TOGGLE_ACTIVE_GOODS_REQUEST, toggleActiveGoodsSaga),
        takeEvery(UPDATE_PRODUCTS_ORDER_NUMBER, updateGoodsOrderNumberSaga),
        takeEvery(FETCH_GET_BY_ID_PRODUCT_REQUEST, getProductFormSaga),
        takeEvery(FETCH_SAVE_PRODUCT_REQUEST, saveProductSaga),
        takeLatest(FETCH_GET_PRODUCT_MODIFICATIONS_REQUEST, getProductModificationsSaga)
    ])
}
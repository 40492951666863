import { FETCH_DELETE_NEWS_ITEM_REQUEST, FETCH_TOGGLE_NEWS_ITEM_REQUEST } from './../../../../store/promotion/news/actions';
import { all, takeLatest } from 'redux-saga/effects'
import { FETCH_GET_NEWS_REQUEST } from '../../../../store/promotion/news/actions'
import { getInformationPostsSaga } from './getInformationPostsSaga'
import { deleteInformationPostsSaga } from './deleteInformationPostsSaga'
import { toggleInformationPostsSaga } from './toggleInformationPostsSaga'

export function* saga() {
    yield all([
        takeLatest(FETCH_GET_NEWS_REQUEST, getInformationPostsSaga),
        takeLatest(FETCH_DELETE_NEWS_ITEM_REQUEST, deleteInformationPostsSaga),
        takeLatest(FETCH_TOGGLE_NEWS_ITEM_REQUEST, toggleInformationPostsSaga),
    ])
}
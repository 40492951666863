import { AnyAction } from "@reduxjs/toolkit"
import { put } from '@redux-saga/core/effects'
import { DELETE_PROMOCODES } from '../../../../api'
import { deletePromocodeItemFailure, deletePromocodeItemSuccess } from '../../../../store/promotion/promocodes/actions'
import { postman } from "../../../../utils/postman"
import { IAppResult } from "../../../../domain/appResult/IAppResult"

export function* promocodeDeleteItemsSaga(action: AnyAction): any {
    try {
        const ids = action.payload
        const url = DELETE_PROMOCODES
        const result: IAppResult = yield postman.post(url, ids)

        yield put(deletePromocodeItemSuccess(result))
    } catch (ex: any) {
        yield put(deletePromocodeItemFailure(ex.response.data))
    }
}
import { IFileDto } from './../../domain/general/IFileDto';
import { IValidationResult } from './../../domain/validationResult/IValidationResult';
import { AnyAction } from 'redux'

export const FETCH_UPLOAD_FILE_REQUEST = 'FETCH_UPLOAD_FILE_REQUEST'
export const FETCH_UPLOAD_FILE_FAILURE = 'FETCH_UPLOAD_FILE_FAILURE'

export const FETCH_GET_FILE_DTO_REQUEST = 'FETCH_GET_FILE_DTO_REQUEST'
export const FETCH_GET_FILE_DTO_SUCCESS = 'FETCH_GET_FILE_DTO_SUCCESS'
export const FETCH_GET_FILE_DTO_FAILURE = 'FETCH_GET_FILE_DTO_FAILURE'

export const DEFAULT_FILE_STATE = 'DEFAULT_FILE_STATE'

export const uploadFileRequest = (payload: File): AnyAction => {
    return {
        type: FETCH_UPLOAD_FILE_REQUEST,
        payload
    }
}

export const uploadFileFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_UPLOAD_FILE_FAILURE,
        payload
    }
}

/**
 * 
 * @param payload file id
 * @returns 
 */
export const getFileDtoRequest = (payload: string): AnyAction => {
    return {
        type: FETCH_GET_FILE_DTO_REQUEST,
        payload
    }
}

export const getFileDtoSuccess = (payload: IFileDto): AnyAction => {
    return {
        type: FETCH_GET_FILE_DTO_SUCCESS,
        payload
    }
}

export const getFileDtoFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_GET_FILE_DTO_FAILURE,
        payload
    }
}

export const setDefaultFileState = (): AnyAction => {
    return {
        type: DEFAULT_FILE_STATE,
    }
}
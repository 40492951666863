import React from 'react'
import { MediaContextProvider } from '../../utils/media'
import { ProfilePageDesktop } from './ProfilePageDesktop'
import { ProfilePageMobile } from './ProfilePageMobile'
import { ProfilePageTablet } from './ProfilePageTablet'

export const ProfilePage: React.FC = () => {
    return (
        <MediaContextProvider>
            <ProfilePageDesktop />
            <ProfilePageTablet />
            <ProfilePageMobile />
        </MediaContextProvider>)
}
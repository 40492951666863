import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Checkbox, CheckboxProps, Header, Label, Modal, Popup, Segment, Table } from 'semantic-ui-react'
import { Pages } from '../../../domain/navigation/pages'
import { IPromocodeDto } from '../../../domain/pages/promotion/promocodes/IPromocodeDto'
import { useDispatchAction, useSelectorState } from '../../../hooks/reduxHook'
import { changeNavigatePage } from '../../../store/navigation/actions'
import { deletePromocodeItemRequest, getPromocodesRequest } from '../../../store/promotion/promocodes/actions'
import { convertForTableBoolean, convertForTableLocalDateTime, convertForTableSelect } from '../../../utils/converter'

export const PromocodesPage: React.FC = () => {
    var dispatch = useDispatchAction()
    const history = useHistory()
    const { t } = useTranslation()

    const config = useSelectorState(state => state.appConfig.promotions.find(p => p.page == Pages.Promocodes))
    const tableData = useSelectorState(state => state.promocodes.items)

    const [selectedRows, setSelectedRows] = useState<Array<string>>([])

    const isGetPromocodesFetching = useSelectorState(state => state.promocodes.isGetPromocodesFetching)
    const isPromocodeDeleteItemsFetching = useSelectorState(state => state.promocodes.isPromocodeDeleteItemsFetching)
    const promocodeDeleteItemsResult = useSelectorState(state => state.promocodes.promocodeDeleteItemsResult)

    const [openModal, setOpenModal] = React.useState(false)
    const openModalHandler = () => setOpenModal(true)
    const closeModalHandler = () => setOpenModal(false)
    const confirmedActionHandler = () => {
        dispatch(deletePromocodeItemRequest(selectedRows))
        closeModalHandler()
    }

    useEffect(() => {
        if (promocodeDeleteItemsResult && promocodeDeleteItemsResult.isError)
            toast.error(promocodeDeleteItemsResult.message)
        else if (promocodeDeleteItemsResult && !promocodeDeleteItemsResult.isError) {
            toast.success(promocodeDeleteItemsResult.message, { autoClose: 5000 })
            loadPromocodes()
            setSelectedRows([])
        }
    }, [promocodeDeleteItemsResult])

    const loadPromocodes = () => dispatch(getPromocodesRequest())

    useEffect(() => {
        dispatch(changeNavigatePage(Pages.Promocodes))
        loadPromocodes()
    }, [dispatch])

    const rowClickHandler = (route: string) => {
        history.push(route)
    }

    const selectAllRowsHandler = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        if (data.checked)
            setSelectedRows(tableData.map(p => p.id))
        else
            setSelectedRows([])
    }

    const selectRowsHandler = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        if (data.checked)
            setSelectedRows(prev => [...prev, data.value as string])
        else
            setSelectedRows(prev => prev.filter(p => p !== data.value))
    }

    const isSelectAll = () => tableData && !!tableData.length && tableData.length === selectedRows.length

    const getRows = () => {
        const rows = []

        for (const index in tableData) {
            const value = tableData[index]
            rows.push(
                <Table.Row key={`row_${index}`}>
                    {getCells(value)}
                </Table.Row>)
        }

        return rows
    }

    const getCells = (data: IPromocodeDto) => {
        const cells = []

        cells.push(
            <Table.Cell key={`header_cell_cbx_${data.id}`} className={"cbx-first-cell"}>
                <Checkbox
                    checked={selectedRows.includes(data.id)}
                    onChange={selectRowsHandler}
                    value={data.id}
                />
            </Table.Cell>
        )

        cells.push(getCell(data.id, convertForTableSelect(data.branchId)))
        cells.push(getCell(data.id, data.name))
        cells.push(getCell(data.id, data.code))
        cells.push(getCell(data.id, data.startPeriod))
        cells.push(getCell(data.id, data.endPeriod))
        cells.push(getCell(data.id, data.numberActivations))
        cells.push(getCell(data.id, convertForTableBoolean(data.isSingleActivation, t)))
        cells.push(getCell(data.id, convertForTableBoolean(data.isActive, t)))
        cells.push(getCell(data.id, convertForTableLocalDateTime(data.createDate)))
        cells.push(getCell(data.id, convertForTableLocalDateTime(data.modifiedDate)))

        return cells
    }

    const getCell = (id: string, value: string | number | any[] | undefined) => {
        return (
            <Table.Cell
                className={'cursor-cell-pointer'}
                onClick={() => config?.editAllowed && rowClickHandler(`/promocodes/${id}`)}
            >
                {value}
            </Table.Cell>
        )
    }

    return (
        <div className='container'>
            <Segment attached='top'>
                <div className='segmentHeaderWithAdditionalBlock'>
                    <Header as='h3' className={'segmentHeader'}>{t(Pages.Promocodes)}</Header>
                    <div className={'segmentHeaderButtonsWrapper'}>
                        {
                            config?.editAllowed &&
                            <Button.Group basic size='small'>
                                <Popup
                                    content={t('dictionaryAddNewRecord')}
                                    position='bottom right'
                                    trigger={
                                        <Button
                                            icon='add'
                                            disabled={isPromocodeDeleteItemsFetching}
                                            as={Link}
                                            to={`/promocodes/new`}
                                        />}
                                />
                                <Popup
                                    content={t('dictionaryDeleteRecords')}
                                    position='bottom right'
                                    trigger={
                                        <Button
                                            icon='trash'
                                            disabled={!selectedRows.length || isPromocodeDeleteItemsFetching}
                                            onClick={openModalHandler}
                                        />}
                                />
                            </Button.Group>
                        }
                    </div>
                </div>
            </Segment>
            <Segment
                attached='bottom'
                loading={isGetPromocodesFetching || isPromocodeDeleteItemsFetching}
                className={'containerWrapper'}>
                <Table unstackable celled selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell className={"cbx-first-cell"}>
                                <Checkbox
                                    indeterminate={!!selectedRows.length && !isSelectAll()}
                                    checked={isSelectAll()}
                                    onChange={selectAllRowsHandler}
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell>{t('branch')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('promocode.name')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('promocode.code')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('promocode.startPeriod')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('promocode.endPeriod')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('promocode.numberActivations')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('promocode.isSingleActivation')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('isActive')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('createDate')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('modifiedDate')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {getRows()}
                    </Table.Body>
                </Table>
            </Segment>
            <Modal
                size='mini'
                dimmer='blurring'
                open={openModal}
                onClose={closeModalHandler}
            >
                <Modal.Header>{t('dictionary.delete.records.confirm.header')}</Modal.Header>
                <Modal.Content>
                    <p>{t('dictionary.delete.records.confirm.question')}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={closeModalHandler}>
                        {t('false')}
                    </Button>
                    <Button positive onClick={confirmedActionHandler}>
                        {t('true')}
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>)
}
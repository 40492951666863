import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CheckboxProps, DropdownItemProps, DropdownProps, Form, Header, Segment } from 'semantic-ui-react'
import { FooterMenu } from '../../components/footerMenu/FooterMenu'
import { FieldType } from '../../domain/general/fieldType'
import { ILookUpDto } from '../../domain/general/ILookUpDto'
import { IDictionaryMatchParams } from '../../domain/navigation/IDictionaryMatchParams'
import { Pages } from '../../domain/navigation/pages'
import { useDispatchAction, useSelectorState } from '../../hooks/reduxHook'
import { clearDictionaryFormSavingResult, getByIdDictionaryRequest, saveDictionaryFormRequest } from '../../store/dictionaries/actions'
import { changeNavigatePage } from '../../store/navigation/actions'
import { getResourcesRequest } from '../../store/resources/actions'
import { reorder } from '../../utils/arrayHelper'
import './index.css'
import TextControl from '../../components/form/dictionaries/TextControl'
import PasswordControl from '../../components/form/dictionaries/PasswordControl'
import TimePeriodControl from '../../components/form/dictionaries/TimePeriodControl'
import BooleanControl from '../../components/form/dictionaries/BooleanControl'
import NumberControl from '../../components/form/dictionaries/NumberControl'
import TimeControl from '../../components/form/dictionaries/TimeControl'
import SelectControl from '../../components/form/dictionaries/SelectControl'
import MultiSelectControl from '../../components/form/dictionaries/MultiSelectControl'
import MultiSelectWithOrderControl from '../../components/form/dictionaries/MultiSelectWithOrderControl'
import BigTextControl from '../../components/form/dictionaries/BigTextControl'
import { ImageInput } from '../../components/ImageInput/ImageInput'
import { IFileDto } from '../../domain/general/IFileDto'

type controlDictType = { [index in FieldType]: React.FC<any> }

const controlsDict: controlDictType = {
    [FieldType.Text]: TextControl,
    [FieldType.Password]: PasswordControl,
    [FieldType.Number]: NumberControl,
    [FieldType.DateTime]: TextControl,
    [FieldType.Boolean]: BooleanControl,
    [FieldType.Select]: SelectControl,
    [FieldType.MultiSelect]: MultiSelectControl,
    [FieldType.EnumSelect]: SelectControl,
    [FieldType.MultiEnumSelect]: MultiSelectControl,
    [FieldType.TimePeriod]: TimePeriodControl,
    [FieldType.Time]: TimeControl,
    [FieldType.LocalDateTime]: TextControl,
    [FieldType.MultiSelectWithOrder]: MultiSelectWithOrderControl,
    [FieldType.Date]: TextControl, //TODO реализовать свой контролл
    [FieldType.BigText]: BigTextControl,
    [FieldType.Image]: ImageInput, //TODO реализовать свой контролл
}

const Control = (props: any) => {
    const { type } = props
    const Element = controlsDict[type as FieldType]
    const element = <Element />

    return React.cloneElement(element, { ...props })
}

export const DictionaryFormPage: React.FC<RouteComponentProps<IDictionaryMatchParams>> = props => {
    const dispatch = useDispatchAction()
    const history = useHistory()
    const { t } = useTranslation()
    const isGetByIdDictionaryFetching = useSelectorState(state => state.dictionaries.isGetByIdDictionaryFetching)
    const isGetResourcesFetching = useSelectorState(state => state.resources.isGetResourcesFetching)
    const resources = useSelectorState(state => state.resources.resources)
    const form = useSelectorState(state => state.dictionaries.form)
    const config = useSelectorState(state => state.appConfig.dictionaries.find(p => p.name === props.match.params.dictionaryName))
    const [formState, setFormState] = useState<any>({})
    const error = useSelectorState(state => state.dictionaries.error)
    const isDictionaryFormSaveFetching = useSelectorState(state => state.dictionaries.isDictionaryFormSaveFetching)
    const saveResult = useSelectorState(state => state.dictionaries.dictionaryFormSavingResult)

    const loadDictionaryForm = () => {
        dispatch(getByIdDictionaryRequest({
            id: props.match.params.id,
            dictionary: props.match.params.dictionaryName
        }))
    }

    const setFormStateFromStore = () => setFormState(form || {})

    useEffect(() => {
        setFormStateFromStore()
    }, [form])

    useEffect(() => {
        dispatch(changeNavigatePage(props.match.params.id ? Pages.DictionaryEditRecord : Pages.DictionaryAddNewRecord))
        loadDictionaryForm()

        return function()
        {
            dispatch(clearDictionaryFormSavingResult())
        }
    }, [])

    useEffect(() => {
        if (saveResult && saveResult.isError)
            toast.error(saveResult.message)
        else if (saveResult && !saveResult.isError) {
            toast.success(saveResult.message, { autoClose: 5000 })
            history.goBack()
        }
    }, [saveResult])

    useEffect(() => {
        if (error)
            toast.error(error)
    }, [error])

    useEffect(() => {
        if (!config)
            return

        for (const propName in config!.propsResourceRoute) {
            dispatch(getResourcesRequest({ propName, route: config!.propsResourceRoute[propName] }))
        }
    }, [config])

    const getHeaderText = () => {
        const dictionaryName = t(`${props.match.params.dictionaryName}`)
        if (props.match.params.id)
            return `${dictionaryName}: ${t('dictionaryEditRecordHeader')}`
        else
            return `${dictionaryName}: ${t('dictionaryNewRecordHeader')}`
    }

    const saveHandler = () => dispatch(saveDictionaryFormRequest({
        dictionary: props.match.params.dictionaryName,
        form: formState
    }))
    const cancelHandler = () => history.goBack()

    const getChangeHandlerForControl = (type: FieldType, propName: string) => {
        switch (type) {
            case FieldType.Boolean:
                return changeRadioHandler
            case FieldType.MultiSelect:
            case FieldType.MultiEnumSelect:
            case FieldType.MultiSelectWithOrder:
                return changeMultiSelectHandler
            case FieldType.Select:
            case FieldType.EnumSelect:
                return changeSelectHandler
            case FieldType.Image:
                return getChangeImageHandler(propName)
            default:
                return changeInputHandler
        }
    }

    const getDragEndHandlerForControl = (type: FieldType) => {
        switch (type) {
            case FieldType.MultiSelectWithOrder:
                return dragEndHandler
            default:
                return () => { }
        }
    }

    const getValueForControl = (type: FieldType, propName: string) => {
        switch (type) {
            case FieldType.MultiSelect:
            case FieldType.MultiEnumSelect:
            case FieldType.MultiSelectWithOrder:
                return formState[propName] && formState[propName].map((p: any) => p.value.toString()) || []
            case FieldType.Select:
            case FieldType.EnumSelect:
                return formState[propName] && formState[propName].value !== null && formState[propName].value !== undefined
                    ? formState[propName].value
                    : ''
            case FieldType.Image:
                return formState[propName] || null
            default:
                return formState[propName] !== null && formState[propName] !== undefined ? formState[propName] : ''
        }
    }

    const getDraggableValueForValue = (type: FieldType, propName: string) => {
        switch (type) {
            case FieldType.MultiSelectWithOrder:
                return formState[propName] || []
            default:
                return []
        }
    }

    const changeInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormState((prev: any) => ({ ...prev, [event.target.name]: event.target.value || null }))
    }

    const changeRadioHandler = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        setFormState((prev: any) => ({ ...prev, [data.name as string]: data.checked }))
    }

    const changeMultiSelectHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const items: Array<ILookUpDto> = resources[data.name]
            ?.map(p => ({ name: p.name, value: p.value }))
            .filter(p => (data.value as Array<string>).includes(p.value))
        setFormState((prev: any) => ({ ...prev, [data.name as string]: items.length ? items : null }))
    }

    const changeSelectHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        if (event.type !== 'click')
            return

        const item: ILookUpDto | null = resources[data.name]
            ?.find(p => p.value === data.value) ?? null
        setFormState((prev: any) => ({ ...prev, [data.name as string]: item }))
    }

    const getError = (propName: string) => {
        if (!saveResult)
            return

        const message = saveResult.errors.find(p => p.name === propName)?.message

        return message
    }

    const dragEndHandler = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }, propName: string) => {
        const selectedItems = reorder(
            formState[propName],
            oldIndex,
            newIndex
        )
        setFormState((prev: any) => ({ ...prev, [propName]: selectedItems }))
    }

    const getAspectRatio = (type: FieldType, propName: string) => {
        switch (type) {
            case FieldType.Image: return Number.parseFloat(config!.propsTypeValues[propName])
            default: return undefined
        }
    }

    const getChangeImageHandler = (propName: string) => {
        return (image: IFileDto | null) => {
            setFormState((prev: any) => ({ ...prev, [propName]: image }))
        }
    }

    const getFormItems = (): Array<any> => {
        const items = []

        if (!config)
            return []

        for (const propName of config!.formProps) {
            const fieldType = config!.propsType[propName]
            const propLocalizationKey = config!.propsLocalizationKey[propName]
            const isRequired = config.requiredProps.includes(propName)
                || (!props.match.params.id
                    && config.requiredIfNewProps.includes(propName))
            const errorMessage = getError(propName)

            const options: Array<DropdownItemProps> = resources[propName]
                ?.map(p => ({ text: p.name, value: p.value }))
                ?? []

            items.push(
                <Control
                    type={fieldType}
                    required={isRequired}
                    label={t(propLocalizationKey)}
                    name={propName}
                    value={getValueForControl(fieldType, propName)}
                    draggableValue={getDraggableValueForValue(fieldType, propName)}
                    options={options}
                    error={errorMessage}
                    onChange={getChangeHandlerForControl(fieldType, propName)}
                    onDragEnd={getDragEndHandlerForControl(fieldType)}
                    aspectRatio={getAspectRatio(fieldType, propName)}
                    key={`form_${propName}`}
                />)
        }

        return items
    }

    return (
        <div className='containerWithFooterMenu'>
            <Segment attached='top'>
                <Header as='h3' className={'segmentHeader'}>
                    {getHeaderText()}
                </Header>
            </Segment>
            <Segment
                attached='bottom'
                loading={isGetByIdDictionaryFetching || isGetResourcesFetching || isDictionaryFormSaveFetching}>
                <Form>
                    <div className={'container-grid-auto-fit'}>
                        {getFormItems()}
                    </div>
                </Form>
            </Segment>
            <FooterMenu
                onCancel={cancelHandler}
                onSave={saveHandler}
                saveDisabled={isGetByIdDictionaryFetching || isGetResourcesFetching || isDictionaryFormSaveFetching}
                cancelDisabled={isGetByIdDictionaryFetching || isGetResourcesFetching || isDictionaryFormSaveFetching}
            />
        </div>)
}
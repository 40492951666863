import { all, takeEvery, takeLatest } from 'redux-saga/effects'
import { FETCH_GET_PROMOCODES_REQUEST, FETCH_GET_BY_ID_PROMOCODE_REQUEST, FETCH_SAVE_FORM_PROMOCODE_REQUEST, FETCH_DELETE_PROMOCODE_ITEMS_REQUEST } from '../../../../store/promotion/promocodes/actions'
import { getByIdPromocodeSaga } from './getByIdPromocodeSaga'
import { getPromocodesSaga } from './getPromocodesSaga'
import { promocodeDeleteItemsSaga } from './promocodeDeleteItemsSaga'
import { savePromocodeFormSaga } from './savePromocodeFormSaga'


export function* saga() {
    yield all([
        takeLatest(FETCH_GET_PROMOCODES_REQUEST, getPromocodesSaga),
        takeEvery(FETCH_GET_BY_ID_PROMOCODE_REQUEST, getByIdPromocodeSaga),
        takeEvery(FETCH_SAVE_FORM_PROMOCODE_REQUEST, savePromocodeFormSaga),
        takeEvery(FETCH_DELETE_PROMOCODE_ITEMS_REQUEST, promocodeDeleteItemsSaga)
    ])
}
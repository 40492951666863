import { all } from 'redux-saga/effects'
import {saga as authSaga} from './pages/auth'
import {saga as appConfigSaga} from './appConfig'
import {saga as profileSage} from './pages/profile'
import {saga as getDictionarySaga} from './pages/dictionaries'
import {saga as getResourcesSaga} from './resources'
import {saga as getGoodsSaga} from './pages/goods'
import {saga as fileSaga} from './file'
import {saga as promocodeSaga} from './pages/promotion/promocodes'
import {saga as newsSaga} from './pages/promotion/news'
import {saga as stocksSaga} from './pages/promotion/stocks'
import {saga as affiliateProgramsSaga} from './pages/promotion/affiliatePrograms'
import {saga as pushNotificationSaga} from './pages/promotion/pushNotification'

export function* mainSaga() {
    yield all([
        authSaga(),
        appConfigSaga(),
        profileSage(),
        getDictionarySaga(),
        getResourcesSaga(),
        getGoodsSaga(),
        fileSaga(),
        promocodeSaga(),
        newsSaga(),
        stocksSaga(),
        affiliateProgramsSaga(),
        pushNotificationSaga()
    ])
}
import React from "react"
import { Menu } from 'semantic-ui-react'
import { Media } from "../../utils/media"
import './index.css'
import { MenuItems } from "./MenuItems"

export const DesktopNavbar: React.FC = () => {
    return (
        <Media greaterThan='tablet'>
            <Menu size={'large'} color={'blue'} pointing className="mb-1">
                <MenuItems/>
            </Menu>
        </Media>
    )
}
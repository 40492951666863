import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import 'semantic-ui-css/semantic.min.css'
import 'react-toastify/dist/ReactToastify.css'
import { store } from './store'
import { Provider } from 'react-redux'
import './locale'
import { Dimmer, Loader } from 'semantic-ui-react'

ReactDOM.render(
  <Provider store={store}>
    <Suspense
            fallback={
                <Dimmer active inverted className="table-loader">
                    <Loader size="huge"/>
                </Dimmer>
            }
        >
            <App/>
        </Suspense>
  </Provider>,
  document.getElementById('root')
)

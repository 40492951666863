import { AnyAction } from '@reduxjs/toolkit'
import { put } from '@redux-saga/core/effects'
import { GET_STOCKS } from '../../../../api'
import { postman } from '../../../../utils/postman'
import { getStocksSuccess, getStocksFailure } from '../../../../store/promotion/stocks/actions'

export function* getStocksSaga(action: AnyAction): any {
    try {
        const url = GET_STOCKS
        const items = yield postman.post(url)

        yield put(getStocksSuccess(items))
    } catch (ex: any) {
        yield put(getStocksFailure(ex.response.data))
    }
}
import { ICategoryDto } from '../../../domain/pages/goods/category/ICategoryDto'
import { CATEGORY_SAVE_REQUEST } from './../../../api/requestEndpoints'
import { IValidationResult } from '../../../domain/validationResult/IValidationResult'
import { AnyAction } from "@reduxjs/toolkit"
import { put } from '@redux-saga/core/effects'
import { postman } from '../../../utils/postman'
import { saveCategoryFailure, saveCategorySuccess } from '../../../store/category/form/actions'

export function* saveCategorySaga(action: AnyAction): any {    
    try {
        const form: ICategoryDto = action.payload
        const result: IValidationResult = yield postman.post(CATEGORY_SAVE_REQUEST, form)
        
        yield put(saveCategorySuccess(result))
    } catch (ex: any) {
        yield put(saveCategoryFailure(ex.response.data))
    }
}
import { IProductDto } from './../../../domain/pages/goods/product/IProductDto'
import { PRODUCT_SAVE_REQUEST } from '../../../api/requestEndpoints'
import { IValidationResult } from '../../../domain/validationResult/IValidationResult'
import { AnyAction } from "@reduxjs/toolkit"
import { put } from '@redux-saga/core/effects'
import { postman } from '../../../utils/postman'
import { saveProductFailure, saveProductSuccess } from '../../../store/product/form/actions'

export function* saveProductSaga(action: AnyAction): any {    
    try {
        const form: IProductDto = action.payload
        const result: IValidationResult = yield postman.post(PRODUCT_SAVE_REQUEST, form)
        
        yield put(saveProductSuccess(result))
    } catch (ex: any) {
        yield put(saveProductFailure(ex.response.data))
    }
}
/**
 * Перечисление поддерживаемых справочников
 */
export enum Dictionaries {
    Unknown = '',
    Roles = 'roles',
    Users = 'users',
    DeliveryAreas = 'deliveryAreas',
    Branches = 'branches',
    WorkingHours = 'workingHours',
    OnlinePaymentSettings = 'onlinePaymentSettings',
    ProductAdditionalOptions = 'productAdditionalOptions',
    ProductModifications = 'productModifications',
    ProductModificationKeys = 'productModificationKeys',
    OrderCheckoutOptions = 'orderCheckoutOptions',
    Cashback = 'cashback',
    InformationPosts = 'informationPosts',
    AffiliatePrograms = 'affiliatePrograms'
}
import { IProfileForm } from './../../../domain/pages/profile/IProfileForm';
import { put } from '@redux-saga/core/effects'
import { postman } from '../../../utils/postman'
import { PROFILE_GET_REQUEST } from '../../../api'
import { loadProfileFailure, loadProfileSuccess } from '../../../store/profile/actions'

export function* loadProfileSaga(): any {    
    try {
        const profile: IProfileForm = yield postman.get(PROFILE_GET_REQUEST)
        
        yield put(loadProfileSuccess(profile))
    } catch (ex: any) {
        yield put(loadProfileFailure(ex.response.data))
    }
}
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Card, Header, Icon, Message, Modal, Popup, Segment } from 'semantic-ui-react'
import { CardWithTitle } from '../../../components/cardWithTitle/CardWithTitle'
import { IAppResult } from '../../../domain/appResult/IAppResult'
import { Dictionaries } from '../../../domain/navigation/dictionaries'
import { Pages } from '../../../domain/navigation/pages'
import { INewsDto } from '../../../domain/pages/promotion/news/INewsDto'
import { useDispatchAction, useSelectorState } from '../../../hooks/reduxHook'
import { changeNavigatePage } from '../../../store/navigation/actions'
import { clearDeleteNewsResult, clearToggleNewsResult, deleteNewsItemRequest, getNewsRequest, setNews, toggleNewsItemRequest } from '../../../store/promotion/news/actions'

export const NewsPage: React.FC = () => {
    var dispatch = useDispatchAction()
    const history = useHistory()
    const { t } = useTranslation()
    const config = useSelectorState(state => state.appConfig.dictionaries.find(p => p.name === Dictionaries.InformationPosts))
    const [openModal, setOpenModal] = useState(false)
    const openModalHandler = () => setOpenModal(true)
    const closeModalHandler = () => setOpenModal(false)
    const confirmedActionHandler = () => {
        if (deleteNewsId) {
            dispatch(deleteNewsItemRequest(deleteNewsId))
        }

        closeModalHandler()
    }

    const news = useSelectorState(state => state.news.items)
    const isGetNewsFetch = useSelectorState(state => state.news.isGetNewsFetching)
    const loadNews = () => dispatch(getNewsRequest())

    useEffect(() => {
        dispatch(changeNavigatePage(Pages.InformationPosts))
        loadNews()

        return function() {
            dispatch(clearDeleteNewsResult())
            dispatch(clearToggleNewsResult())
        }
    }, [dispatch])

    const isDeleteNewsFetching = useSelectorState(state => state.news.isNewsDeleteItemsFetching)
    const deleteNewsResult = useSelectorState(state => state.news.newsDeleteItemsResult)
    const isToggleActiveNewsFetching = useSelectorState(state => state.news.isNewsToggleItemsFetching)
    const toggleActiveNewsResult = useSelectorState(state => state.news.newsToggleItemsResult)

    const actionChangeResultDispatch = (
        result: IAppResult | null,
        successCallback: () => void) => {
        if (result && result.isError)
            toast.error(result.message)
        else if (result && !result.isError) {
            toast.success(result.message, { autoClose: 5000 })
            successCallback()
        }
    }

    const setNewsStore = (news: Array<INewsDto>) => dispatch(setNews(news))

    useEffect(() => {
        var successCallback = () => {
            if (deleteNewsId) {
                const itemIndex = news.findIndex(p => p.id == deleteNewsId)
                const newsForChange = [...news]
                newsForChange.splice(itemIndex, 1)

                setNewsStore(newsForChange)
                setDeleteNewsId(undefined)
            }
        }
        actionChangeResultDispatch(deleteNewsResult, successCallback)
    }, [deleteNewsResult])

    useEffect(() => {
        const successCallback = () => {
            if (toggleNewsId) {
                const newsForChange = [...news]
                const itemIndex = newsForChange.findIndex(p => p.id == toggleNewsId)
                const item = newsForChange[itemIndex]
                const newItem = { ...item, isActive: !item.isActive }
                newsForChange[itemIndex] = newItem

                setNewsStore(newsForChange)
                setToggleNewsId(undefined)
            }
        }
        actionChangeResultDispatch(toggleActiveNewsResult, successCallback)
    }, [toggleActiveNewsResult])

    const newsEditHandler = (id: string) => history.push(`/dictionaries/${Dictionaries.InformationPosts}/${id}`)

    const [toggleNewsId, setToggleNewsId] = useState<string>()
    const newsToggleHandler = (id: string) => {
        setToggleNewsId(id)
        dispatch(toggleNewsItemRequest(id))
    }

    const [deleteNewsId, setDeleteNewsId] = useState<string>()
    const newsDeleteHandler = (id: string) => {
        setDeleteNewsId(id)
        openModalHandler()
    }

    const isLoading = () => isGetNewsFetch || isDeleteNewsFetching || isToggleActiveNewsFetching

    return (
        <div className='container'>
            <Segment attached='top'>
                <div className='segmentHeaderWithAdditionalBlock'>
                    <Header as='h3' className={'segmentHeader'}>{t(Dictionaries.InformationPosts)}</Header>
                    <div className={'segmentHeaderButtonsWrapper'}>
                        {
                            config?.editAllowed &&
                            <Button.Group basic size='small'>
                                <Popup
                                    content={t('dictionaryAddNewRecord')}
                                    position='bottom right'
                                    trigger={
                                        <Button
                                            icon='add'
                                            disabled={isLoading()}
                                            as={Link}
                                            to={`/dictionaries/${Dictionaries.InformationPosts}/new`}
                                        />}
                                />
                            </Button.Group>
                        }
                    </div>
                </div>
            </Segment>
            {
                ((news && news.length > 0) || isLoading()) &&
                <Segment
                    attached='bottom'
                    loading={isLoading()}
                    className={'containerWrapper containerWrapperWithMinSizeForLoadingIndicator'}>
                    <Card.Group stackable>
                        {
                            news && news.length != 0 &&
                            news.map(post => <CardWithTitle
                                key={post.id}
                                id={post.id}
                                src={post.imageId!.path}
                                title={post.title}
                                isActive={post.isActive}
                                editHandler={newsEditHandler}
                                toggleHandler={newsToggleHandler}
                                deleteHandler={newsDeleteHandler} />)
                        }
                    </Card.Group>
                </Segment>
            }
            {
                ((!news || !news.length) && !isLoading()) &&
                <Message attached='bottom' size='tiny' info style={{ textAlign: 'center' }}>
                    <Icon name='info' />
                    {t('emptyPostInformationInfoMsg')}
                </Message>
            }
            <Modal
                size='mini'
                dimmer='blurring'
                open={openModal}
                onClose={closeModalHandler}
            >
                <Modal.Header>{t('dictionary.delete.records.confirm.header')}</Modal.Header>
                <Modal.Content>
                    <p>{t('dictionary.delete.records.confirm.question')}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={closeModalHandler}>
                        {t('false')}
                    </Button>
                    <Button positive onClick={confirmedActionHandler}>
                        {t('true')}
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>)
}
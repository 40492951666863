import { AnyAction } from '@reduxjs/toolkit'
import { put } from '@redux-saga/core/effects'
import { GET_PROMOCODES } from '../../../../api'
import { getByIdPromocodeFailure, getByIdPromocodeSuccess } from '../../../../store/promotion/promocodes/actions'
import { postman } from '../../../../utils/postman'

export function* getByIdPromocodeSaga(action: AnyAction): any {
    try {
        const id = action.payload || 'default'
        const url = `${GET_PROMOCODES}/${id}`
        const dto = yield postman.get(url)

        yield put(getByIdPromocodeSuccess(dto))
    } catch (ex: any) {
        yield put(getByIdPromocodeFailure(ex.response.data))
    }
}
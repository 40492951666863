import { AnyAction } from 'redux'
import { IResourcesPayload } from '../../domain/resources/IResourcesPayload'
import { IResourcesSuccessPayload } from '../../domain/resources/IResourcesSuccessPayload'

export const FETCH_GET_RESOURCES_REQUEST = 'FETCH_GET_RESOURCES_REQUEST'
export const FETCH_GET_RESOURCES_SUCCESS = 'FETCH_GET_RESOURCES_SUCCESS'
export const FETCH_GET_RESOURCES_FAILURE = 'FETCH_GET_RESOURCES_FAILURE'

export const RESOURCES_CLEAR = 'RESOURCES_CLEAR'

export const getResourcesRequest = (payload: IResourcesPayload): AnyAction => {
    return {
        type: FETCH_GET_RESOURCES_REQUEST,
        payload
    }
}

export const getResourcesSuccess = (payload: IResourcesSuccessPayload): AnyAction => {
    return {
        type: FETCH_GET_RESOURCES_SUCCESS,
        payload
    }
}

export const getResourcesFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_GET_RESOURCES_FAILURE,
        payload
    }
}

export const resourcesClear = (): AnyAction => {
    return { type: RESOURCES_CLEAR }
}
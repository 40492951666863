import {
    all,
    takeEvery
} from 'redux-saga/effects'
import { FETCH_APP_CONFIG_REQUEST } from '../../store/appConfig/actions'
import { getAppConfigSaga } from './getAppConfigSaga'


export function* saga() {
    yield all([
        takeEvery(FETCH_APP_CONFIG_REQUEST, getAppConfigSaga)
    ])
}

import { IDictionaryEndpoint } from '../domain/api/IDictionaryEndpoint'
import { Dictionaries } from '../domain/navigation/dictionaries'

export const getEndpointsForDictionaryGetter = (dictionary: Dictionaries): IDictionaryEndpoint => {
    return {
        endpointGetById: (id) => `dictionaries/${dictionary}/${id || 'default'}`,
        endpointGet: () => `dictionaries/${dictionary}`,
        endpointSave: () => `dictionaries/${dictionary}/save`,
        endpointDelete: () => `dictionaries/${dictionary}/delete`,
    }
}
import axios from 'axios'
import qs from 'qs'
import { toast } from 'react-toastify'
import { logout } from '../store/auth/actions'
import { ACCESS_TOKEN } from '../domain/constants'
import { store } from '../store'
import history from './historyHelper'

export const postman = axios.create({
    baseURL: '/api/panel',
    paramsSerializer: params => qs.stringify(params, { indices: false }),
});

export const downloader = axios.create({
    baseURL: '/api/panel',
});

postman.interceptors.response.use(
    resp => {
        return resp.data;
    },
    error => {
        const { data = {}, status } = error.response;
        const { error: errorText = '', message = '' } = data;
        
        (errorText || message) && toast.error(errorText ? JSON.stringify(errorText) : message || 'Ошибка!');

        if (status === 401) {
            toast.dismiss()
            store.dispatch(logout())
        }

        if (status === 403) {

            toast.error('Нет доступа')
            history.push('/')   
        }

        if (status === 500 && !data.error) {
            toast.error('Что-то пошло не так...', {
                autoClose: false,
            })
        }

        return Promise.reject(error);
    },
);

export const setAccessToken = (token: string | null) => {
    if (token !== null) {
        postman.defaults.headers.common.Authorization = `Bearer ${token}`;
        downloader.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
        delete postman.defaults.headers.common.Authorization;
        delete downloader.defaults.headers.common.Authorization;
    }
};

setAccessToken(localStorage.getItem(ACCESS_TOKEN))
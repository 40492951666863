import React from "react"
import { Form } from "semantic-ui-react"
import { IInputControl } from "../../../domain/components/form/dictionaries/IInputControl"

const TimeControl: React.FC<IInputControl> = ({
    required,
    label,
    name,
    value,
    error,
    onChange,
}) => {
    return <Form.Input
        required = {required}
        label={label}
        name={name}
        value={value}
        error={error}
        onChange={onChange}
        type={'time'}
    />
}

export default TimeControl
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Dictionaries } from '../../../domain/navigation/dictionaries'
import { IDictionaryMatchParams } from '../../../domain/navigation/IDictionaryMatchParams'
import { Pages } from '../../../domain/navigation/pages'
import { DictionaryTablePage } from '../../dictionaries/DictionaryTablePage'

export const AffiliateProgramsPage: React.FC<RouteComponentProps<IDictionaryMatchParams>> = props => {


    return <DictionaryTablePage
        history={props.history}
        location={props.location}
        match={{
            ...props.match,
            params: {
                ...props.match.params,
                dictionaryName: Dictionaries.AffiliatePrograms,
                addNewRecordLink: `/${Pages.AffiliatePrograms}/new`,
                editRecordLink: `/${Pages.AffiliatePrograms}/`
            }
        }} />
}
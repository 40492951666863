import { IPageNavigateState } from './../../domain/navigation/IPageNavigateState';
import { AnyAction } from '@reduxjs/toolkit'
import { CHANGE_NAVIGATE_PAGE, TOGGLE_SIDEBAR } from './actions'
import { Pages } from '../../domain/navigation/pages'

const initialState: IPageNavigateState = {
    activeItemName: Pages.Orders,
    visibleSidebar: false
}

export const pageNavigationReducer = (state = initialState, action: AnyAction): IPageNavigateState => {
    switch (action.type) {
        case CHANGE_NAVIGATE_PAGE:
            return {
                ...state,
                activeItemName: action.payload
            }
        case TOGGLE_SIDEBAR:
            return {
                ...state,
                visibleSidebar: action.payload
            }
        default: return state
    }
}
import { AnyAction } from 'redux'
import { IProductDto, ModificationDto } from '../../../domain/pages/goods/product/IProductDto'
import { ProductModificationsPayload } from '../../../domain/pages/goods/product/IProductFormState'
import { IValidationResult } from '../../../domain/validationResult/IValidationResult'

export const FETCH_GET_BY_ID_PRODUCT_REQUEST = 'FETCH_GET_BY_ID_PRODUCT_REQUEST'
export const FETCH_GET_BY_ID_PRODUCT_SUCCESS = 'FETCH_GET_BY_ID_PRODUCT_SUCCESS'
export const FETCH_GET_BY_ID_PRODUCT_FAILURE = 'FETCH_GET_BY_ID_PRODUCT_FAILURE'

export const FETCH_SAVE_PRODUCT_REQUEST = 'FETCH_SAVE_PRODUCT_REQUEST'
export const FETCH_SAVE_PRODUCT_SUCCESS = 'FETCH_SAVE_PRODUCT_SUCCESS'
export const FETCH_SAVE_PRODUCT_FAILURE = 'FETCH_SAVE_PRODUCT_FAILURE'
export const CLEAR_PRODUCT_SAVING_RESULT = 'CLEAR_PRODUCT_SAVING_RESULT'

export const FETCH_GET_PRODUCT_MODIFICATIONS_REQUEST = 'FETCH_GET_PRODUCT_MODIFICATIONS_REQUEST'
export const FETCH_GET_PRODUCT_MODIFICATIONS_SUCCESS = 'FETCH_GET_PRODUCT_MODIFICATIONS_SUCCESS'
export const FETCH_GET_PRODUCT_MODIFICATIONS_FAILURE = 'FETCH_GET_PRODUCT_MODIFICATIONS_FAILURE'
export const CLEAR_PRODUCT_MODIFICATIONS = 'CLEAR_PRODUCT_MODIFICATIONS'

export const CLEAR_PRODUCT_FORM = 'CLEAR_PRODUCT_FORM'

export const getProductModificationsRequest = (payload: ProductModificationsPayload): AnyAction => {
    return {
        type: FETCH_GET_PRODUCT_MODIFICATIONS_REQUEST,
        payload
    }
}

export const getProductModificationsSuccess = (payload: Array<ModificationDto> | null): AnyAction => {
    return {
        type: FETCH_GET_PRODUCT_MODIFICATIONS_SUCCESS,
        payload
    }
}

export const getProductModificationsFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_GET_PRODUCT_MODIFICATIONS_FAILURE,
        payload
    }
}

export const clearProductModifications = (): AnyAction => {
    return {
        type: CLEAR_PRODUCT_MODIFICATIONS
    }
}

export const clearProductForm = (): AnyAction => {
    return {
        type: CLEAR_PRODUCT_FORM
    }
}

export const getProductByIdRequest = (payload: string | undefined): AnyAction => {
    return {
        type: FETCH_GET_BY_ID_PRODUCT_REQUEST,
        payload
    }
}

export const getProductByIdSuccess = (payload: any): AnyAction => {
    return {
        type: FETCH_GET_BY_ID_PRODUCT_SUCCESS,
        payload
    }
}

export const getProductByIdFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_GET_BY_ID_PRODUCT_FAILURE,
        payload
    }
}

export const saveProductRequest = (payload: IProductDto): AnyAction => {
    return {
        type: FETCH_SAVE_PRODUCT_REQUEST,
        payload
    }
}

export const saveProductSuccess = (payload: IValidationResult): AnyAction => {
    return {
        type: FETCH_SAVE_PRODUCT_SUCCESS,
        payload
    }
}

export const saveProductFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_SAVE_PRODUCT_FAILURE,
        payload
    }
}

export const clearProductSavingResult = (): AnyAction => {
    return {
        type: CLEAR_PRODUCT_SAVING_RESULT
    }
}
import { FETCH_GET_AFFILIATE_PROGRAMS_REQUEST, FETCH_GET_BY_ID_AFFILIATE_PROGRAM_REQUEST, FETCH_SAVE_FORM_AFFILIATE_PROGRAM_REQUEST, FETCH_DELETE_AFFILIATE_PROGRAM_ITEMS_REQUEST } from './../../../../store/promotion/affiliatePrograms/actions';
import { all, takeEvery, takeLatest } from 'redux-saga/effects'
import { deleteItemsSaga } from './deleteItemsSaga'
import { getByIdSaga } from './getByIdSaga'
import { getItemsSaga } from './getItemsSaga'
import { saveFormSaga } from './saveFormSaga'

export function* saga() {
    yield all([
        takeLatest(FETCH_GET_AFFILIATE_PROGRAMS_REQUEST, getItemsSaga),
        takeEvery(FETCH_GET_BY_ID_AFFILIATE_PROGRAM_REQUEST, getByIdSaga),
        takeEvery(FETCH_SAVE_FORM_AFFILIATE_PROGRAM_REQUEST, saveFormSaga),
        takeEvery(FETCH_DELETE_AFFILIATE_PROGRAM_ITEMS_REQUEST, deleteItemsSaga)
    ])
}
import { AnyAction } from '@reduxjs/toolkit'
import { put } from '@redux-saga/core/effects'
import { postman } from '../../../../utils/postman'
import { getNewsFailure, getNewsSuccess } from '../../../../store/promotion/news/actions'
import { getEndpointsForDictionaryGetter } from '../../../../api/endpointsHelper'
import { Dictionaries } from '../../../../domain/navigation/dictionaries'

export function* getInformationPostsSaga(action: AnyAction): any {
    try {
        // т.к новости под капотом это справочник, то юзаем ендпоинты справочника
        const dictUrls = getEndpointsForDictionaryGetter(Dictionaries.InformationPosts);
        const url = dictUrls.endpointGet()
        const items = yield postman.post(url)

        yield put(getNewsSuccess(items))
    } catch (ex: any) {
        yield put(getNewsFailure(ex.response.data))
    }
}
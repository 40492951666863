import { AnyAction } from 'redux'
import { IAppResult } from '../../../domain/appResult/IAppResult'
import { INewsDto } from '../../../domain/pages/promotion/news/INewsDto'

export const FETCH_GET_NEWS_REQUEST = 'FETCH_GET_NEWS_REQUEST'
export const FETCH_GET_NEWS_SUCCESS = 'FETCH_GET_NEWS_SUCCESS'
export const FETCH_GET_NEWS_FAILURE = 'FETCH_GET_NEWS_FAILURE'

export const FETCH_TOGGLE_NEWS_ITEM_REQUEST = 'FETCH_TOGGLE_NEWS_ITEM_REQUEST'
export const FETCH_TOGGLE_NEWS_ITEM_SUCCESS = 'FETCH_TOGGLE_NEWS_ITEM_SUCCESS'
export const FETCH_TOGGLE_NEWS_ITEM_FAILURE = 'FETCH_TOGGLE_NEWS_ITEM_FAILURE'

export const FETCH_DELETE_NEWS_ITEM_REQUEST = 'FETCH_DELETE_NEWS_ITEM_REQUEST'
export const FETCH_DELETE_NEWS_ITEM_SUCCESS = 'FETCH_DELETE_NEWS_ITEM_SUCCESS'
export const FETCH_DELETE_NEWS_ITEM_FAILURE = 'FETCH_DELETE_NEWS_ITEM_FAILURE'

export const SET_NEWS = 'SET_NEWS'

export const CLEAR_DELETE_NEW_RESULT = 'CLEAR_DELETE_NEW_RESULT'
export const CLEAR_TOGGLE_ACTIVE_NEWS_RESULT = 'CLEAR_TOGGLE_ACTIVE_NEWS_RESULT'

export const getNewsRequest = (): AnyAction => {
    return {
        type: FETCH_GET_NEWS_REQUEST
    }
}

export const getNewsSuccess = (payload: Array<INewsDto>): AnyAction => {
    return {
        type: FETCH_GET_NEWS_SUCCESS,
        payload
    }
}

export const getNewsFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_GET_NEWS_FAILURE,
        payload
    }
}

/**
 * 
 * @param payload - news id
 */
 export const toggleNewsItemRequest = (payload: string): AnyAction => {
    return {
        type: FETCH_TOGGLE_NEWS_ITEM_REQUEST,
        payload
    }
}

export const toggleNewsItemSuccess = (payload: IAppResult): AnyAction => {
    return {
        type: FETCH_TOGGLE_NEWS_ITEM_SUCCESS,
        payload
    }
}

export const toggleNewsItemFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_TOGGLE_NEWS_ITEM_FAILURE,
        payload
    }
}

/**
 * 
 * @param payload - news id
 */
export const deleteNewsItemRequest = (payload: string): AnyAction => {
    return {
        type: FETCH_DELETE_NEWS_ITEM_REQUEST,
        payload
    }
}

export const deleteNewsItemSuccess = (payload: IAppResult): AnyAction => {
    return {
        type: FETCH_DELETE_NEWS_ITEM_SUCCESS,
        payload
    }
}

export const deleteNewsItemFailure = (payload: string): AnyAction => {
    return {
        type: FETCH_DELETE_NEWS_ITEM_FAILURE,
        payload
    }
}

export const setNews = (news: Array<INewsDto>): AnyAction => {
    return {
        type: SET_NEWS,
        payload: news
    }
}

export const clearDeleteNewsResult = () => {
    return {
        type: CLEAR_DELETE_NEW_RESULT
    }
}

export const clearToggleNewsResult = () => {
    return {
        type: CLEAR_TOGGLE_ACTIVE_NEWS_RESULT
    }
}
import React, { useEffect, useState } from "react"
import { Form, Grid } from "semantic-ui-react"
import './index.css'
import { IAuth } from '../../domain/pages/auth/IAuth'
import { useDispatchAction, useSelectorState } from "../../hooks/reduxHook"
import { login } from "../../store/auth/actions"
import { useTranslation } from 'react-i18next'
import { toast } from "react-toastify"
import { languageApp } from "../../locale/i18n"

export const LoginForm: React.FC = () => {
    const { t } = useTranslation()
    const [form, setForm] = useState<IAuth>({ login: '', password: '', language: languageApp })
    const isAuthFetching = useSelectorState(state => state.auth.isAuthFetching)
    const error = useSelectorState(state => state.auth.error)
    const dispatch = useDispatchAction()

    useEffect(() => {
        if (error)
            toast.error(t(error))
    }, [error, t])

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm(prev => ({ ...prev, [event.target.name]: event.target.value }))
    }

    const submitHandler = () => {
        dispatch(login(form))
    }

    return (
        <Grid.Column>
            <Form onSubmit={submitHandler}>
                <Form.Input
                    value={form.login}
                    icon='user'
                    iconPosition='left'
                    label={t('loginAuthForm')}
                    name='login'
                    placeholder={t('loginAuthFormPlaceholder')}
                    onChange={changeHandler} />
                <Form.Input
                    value={form.password}
                    icon='lock'
                    iconPosition='left'
                    label={t('passwordAuthForm')}
                    name='password'
                    placeholder={t('passwordAuthFormPlaceholder')}
                    type='password'
                    onChange={changeHandler} />
                <Form.Button
                    content={t('loginAuthAction')}
                    primary
                    floated='right'
                    loading={isAuthFetching}
                />
            </Form>
        </Grid.Column>
    )
}
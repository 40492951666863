import { IFileDto } from './../../domain/general/IFileDto';
import { put } from "@redux-saga/core/effects"
import { AnyAction } from "@reduxjs/toolkit"
import { postman } from '../../utils/postman'
import { GET_FILE_DTO } from '../../api'
import { getFileDtoSuccess, uploadFileFailure } from '../../store/file/actions'

export function* getFileDtoSaga(action: AnyAction): any {
    try {
        const id: string = action.payload
        const result: IFileDto = yield postman.get(`${GET_FILE_DTO}/${id}`)

        yield put(getFileDtoSuccess(result))
    } catch (ex: any) {
        yield put(uploadFileFailure(ex.response.data))
    }
}
import { AnyAction } from '@reduxjs/toolkit'
import { put } from '@redux-saga/core/effects'
import { GET_PROMOCODES } from '../../../../api'
import { getPromocodesFailure, getPromocodesSuccess } from '../../../../store/promotion/promocodes/actions'
import { postman } from '../../../../utils/postman'

export function* getPromocodesSaga(action: AnyAction): any {
    try {
        const url = GET_PROMOCODES
        const items = yield postman.post(url)

        yield put(getPromocodesSuccess(items))
    } catch (ex: any) {
        yield put(getPromocodesFailure(ex.response.data))
    }
}
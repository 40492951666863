import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { IIdMatchParams } from '../../domain/navigation/IGoodsMatchParams'
import { MediaContextProvider } from '../../utils/media'
import { GoodsPageDesktop } from './GoodsPageDesktop'
import { GoodsPageMobile } from './GoodsPageMobile'
import { GoodsPageTablet } from './GoodsPageTablet'

export const GoodsPage: React.FC<RouteComponentProps<IIdMatchParams>> = props => {
    return (
        <MediaContextProvider>
            <GoodsPageDesktop {...props}/>
            <GoodsPageTablet {...props}/>
            <GoodsPageMobile {...props}/>
        </MediaContextProvider>)
}
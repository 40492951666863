import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Form, Header, Segment } from 'semantic-ui-react'
import { FooterMenu } from '../../components/footerMenu/FooterMenu'
import { Pages } from '../../domain/navigation/pages'
import { IProfileForm } from '../../domain/pages/profile/IProfileForm'
import { IProfilePasswordVisible } from '../../domain/pages/profile/IProfilePasswordVisible'
import { ProfileBlocksEnum } from '../../domain/pages/profile/profileBlocksEnum'
import { useDispatchAction, useSelectorState } from '../../hooks/reduxHook'
import { changeNavigatePage } from '../../store/navigation/actions'
import { clearProfileSavingResult, loadProfileRequest, saveProfileRequest } from '../../store/profile/actions'

const fieldNames = {
    name: 'name',
    email: 'email',
    phone: 'phone',
    role: 'role',
    password: 'password',
    newPassword: 'newPassword',
    repeatNewPassword: 'repeatNewPassword'
}

export const ProfileBasePage: React.FC = () => {
    var dispatch = useDispatchAction()
    const { t } = useTranslation()
    const [passwordViewer, setPasswordViewerState] = useState<IProfilePasswordVisible>({
        passwordVisible: false,
        newPasswordVisible: false,
        repeatNewPasswordVisible: false
    })
    const isProfileFetching = useSelectorState(state => state.profile.isProfileFetching)
    const profileDto = useSelectorState(state => state.profile.form)
    const error = useSelectorState(state => state.profile.error)

    const [profileForm, setProfileForm] = useState<IProfileForm>({ ...profileDto })
    const togglePasswordViewHandler = (propName: any) => {
        const propKey = propName as keyof IProfilePasswordVisible
        setPasswordViewerState(prev => ({ ...prev, [propName]: !prev[propKey] }))
    }

    const changeInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProfileForm(prev => ({ ...prev, [event.target.name]: event.target.value }))
    }

    const cancelHandler = () => {
        setStateFormFromStore()
        clearProfileValidationResult()
    }
    const saveHandler = () => {
        dispatch(saveProfileRequest(profileForm))
    }

    const isProfileSaveFetching = useSelectorState(state => state.profile.isProfileSaveFetching)
    const saveResult = useSelectorState(state => state.profile.profileSavingResult)

    const loadProfile = () => dispatch(loadProfileRequest())
    const clearProfileValidationResult = () => dispatch(clearProfileSavingResult())

    useEffect(() => {
        if (saveResult && saveResult.isError)
            toast.error(saveResult.message)
        else if (saveResult && !saveResult.isError) {
            toast.success(saveResult.message, { autoClose: 5000 })
            loadProfile()
        }
    }, [saveResult])

    useEffect(() => {
        if (error)
            toast.error(error)
    }, [error])

    useEffect(() => {
        dispatch(changeNavigatePage(Pages.Profile))
        loadProfile()

        return function cleanup() {
            clearProfileValidationResult()
        }
    }, [])

    useEffect(() => {
        setStateFormFromStore()
    }, [profileDto])

    const setStateFormFromStore = () => setProfileForm({ ...profileDto })
    const getValidValue = (v: string | null) => v || ''

    const getError = (propName: string) => {
        if (!saveResult)
            return

        const message = saveResult.errors.find(p => p.name === propName)?.message

        return message
    }

    return (
        <div className='containerWithFooterMenu'>
            <Segment attached='top' id={ProfileBlocksEnum.General}>
                <Header as='h3'>{t('general')}</Header>
            </Segment>
            <Segment attached='bottom' loading={isProfileFetching || isProfileSaveFetching}>
                <Form>
                    <Form.Input
                        required
                        value={getValidValue(profileForm.name)}
                        icon='user'
                        iconPosition='left'
                        label={t('profileUserName')}
                        name={fieldNames.name}
                        placeholder={t('profileUserNamePlaceholder')}
                        onChange={changeInputHandler}
                        error={getError(fieldNames.name)}
                    />
                    <Form.Input
                        required
                        value={getValidValue(profileForm.email)}
                        icon='mail'
                        iconPosition='left'
                        label={t('profileEmail')}
                        name={fieldNames.email}
                        placeholder={t('profileEmailPlaceholder')}
                        onChange={changeInputHandler}
                        error={getError(fieldNames.email)}
                    />
                    <Form.Input
                        required
                        value={getValidValue(profileForm.phone)}
                        icon='phone'
                        iconPosition='left'
                        label={t('profilePhone')}
                        name={fieldNames.phone}
                        placeholder={t('profilePhonePlaceholder')}
                        onChange={changeInputHandler}
                        error={getError(fieldNames.phone)}
                    />
                    <Form.Input
                        value={getValidValue(profileForm.role)}
                        icon='key'
                        iconPosition='left'
                        label={t('profileRole')}
                        name={fieldNames.role}
                        readOnly
                        placeholder={t('profileRolePlaceholder')}
                        onChange={changeInputHandler}
                        error={getError(fieldNames.role)}
                    />
                </Form>
            </Segment>
            <Segment attached='top' id={ProfileBlocksEnum.ChangePassword}>
                <Header as='h3'>{t('changePassword')}</Header>
            </Segment>
            <Segment attached='bottom' loading={isProfileFetching || isProfileSaveFetching}>
                <Form>
                    <Form.Input
                        value={getValidValue(profileForm.password)}
                        icon='lock'
                        iconPosition='left'
                        label={t('profileOldPassword')}
                        name={fieldNames.password}
                        type={passwordViewer.passwordVisible ? 'text' : 'password'}
                        placeholder={t('profileOldPasswordPlaceholder')}
                        action={{
                            icon: passwordViewer.passwordVisible ? 'eye slash' : 'eye',
                            onClick: () => togglePasswordViewHandler('passwordVisible')
                        }}
                        onChange={changeInputHandler}
                        error={getError(fieldNames.password)}
                    />
                    <Form.Input
                        value={getValidValue(profileForm.newPassword)}
                        icon='lock'
                        iconPosition='left'
                        label={t('profileNewPassword')}
                        name={fieldNames.newPassword}
                        type={passwordViewer.newPasswordVisible ? 'text' : 'password'}
                        placeholder={t('profileNewPasswordPlaceholder')}
                        action={{
                            icon: passwordViewer.newPasswordVisible ? 'eye slash' : 'eye',
                            onClick: () => togglePasswordViewHandler('newPasswordVisible')
                        }}
                        onChange={changeInputHandler}
                        error={getError(fieldNames.newPassword)}
                    />
                    <Form.Input
                        value={getValidValue(profileForm.repeatNewPassword)}
                        icon='lock'
                        iconPosition='left'
                        label={t('profileRepeatNewPassword')}
                        name={fieldNames.repeatNewPassword}
                        type={passwordViewer.repeatNewPasswordVisible ? 'text' : 'password'}
                        placeholder={t('profileNewPasswordPlaceholder')}
                        action={{
                            icon: passwordViewer.repeatNewPasswordVisible ? 'eye slash' : 'eye',
                            onClick: () => togglePasswordViewHandler('repeatNewPasswordVisible')
                        }}
                        onChange={changeInputHandler}
                        error={getError(fieldNames.repeatNewPassword)}
                    />
                </Form>
            </Segment>

            <FooterMenu
                onCancel={cancelHandler}
                onSave={saveHandler}
                cancelDisabled={isProfileFetching || isProfileSaveFetching}
                saveDisabled={isProfileFetching || isProfileSaveFetching}
            />
        </div>)
}
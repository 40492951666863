
import { AnyAction } from '@reduxjs/toolkit'
import { IPromocodeState } from '../../../domain/pages/promotion/promocodes/IPromocodeState'

import {
    CLEAR_PROMOCODE_FORM_SAVING_RESULT,
    FETCH_DELETE_PROMOCODE_ITEMS_FAILURE,
    FETCH_DELETE_PROMOCODE_ITEMS_REQUEST,
    FETCH_DELETE_PROMOCODE_ITEMS_SUCCESS,
    FETCH_GET_BY_ID_PROMOCODE_FAILURE,
    FETCH_GET_BY_ID_PROMOCODE_REQUEST,
    FETCH_GET_BY_ID_PROMOCODE_SUCCESS,
    FETCH_GET_PROMOCODES_FAILURE,
    FETCH_GET_PROMOCODES_REQUEST,
    FETCH_GET_PROMOCODES_SUCCESS,
    FETCH_SAVE_FORM_PROMOCODE_FAILURE,
    FETCH_SAVE_FORM_PROMOCODE_REQUEST,
    FETCH_SAVE_FORM_PROMOCODE_SUCCESS
} from './actions'

const initialState: IPromocodeState = {
    error: '',
    isGetPromocodesFetching: false,
    promocodeFormSavingResult: null,
    isPromocodeFormSaveFetching: false,
    items: [],
    isGetByIdPromocodeFetching: false,
    form: undefined,
    promocodeDeleteItemsResult: null,
    isPromocodeDeleteItemsFetching: false
}

export const promocodesReducer = (state = initialState, action: AnyAction): IPromocodeState => {
    switch (action.type) {
        case FETCH_GET_PROMOCODES_REQUEST:
            return {
                ...initialState,
                isGetPromocodesFetching: true,
                error: '',
                promocodeFormSavingResult: null,
                promocodeDeleteItemsResult: null
            }
        case FETCH_GET_PROMOCODES_SUCCESS:
            return {
                ...state,
                isGetPromocodesFetching: false,
                items: action.payload
            }
        case FETCH_GET_PROMOCODES_FAILURE:
            return {
                ...state,
                isGetPromocodesFetching: false,
                error: action.payload
            }
        case FETCH_GET_BY_ID_PROMOCODE_REQUEST:
            return {
                ...initialState,
                isGetByIdPromocodeFetching: true,
                error: '',
                promocodeFormSavingResult: null,
            }
        case FETCH_GET_BY_ID_PROMOCODE_SUCCESS:
            return {
                ...state,
                isGetByIdPromocodeFetching: false,
                form: action.payload
            }
        case FETCH_GET_BY_ID_PROMOCODE_FAILURE:
            return {
                ...state,
                isGetByIdPromocodeFetching: false,
                error: action.payload
            }
        case FETCH_SAVE_FORM_PROMOCODE_REQUEST:
            return {
                ...state,
                isPromocodeFormSaveFetching: true,
                error: '',
                promocodeFormSavingResult: null
            }
        case FETCH_SAVE_FORM_PROMOCODE_SUCCESS:
            return {
                ...state,
                promocodeFormSavingResult: action.payload,
                isPromocodeFormSaveFetching: false,
            }
        case FETCH_SAVE_FORM_PROMOCODE_FAILURE:
            return {
                ...state,
                isPromocodeFormSaveFetching: false,
                error: action.payload
            }
        case CLEAR_PROMOCODE_FORM_SAVING_RESULT: {
            return {
                ...state,
                promocodeFormSavingResult: null,
                form: undefined
            }
        }
        case FETCH_DELETE_PROMOCODE_ITEMS_REQUEST:
            return {
                ...state,
                isPromocodeDeleteItemsFetching: true,
                error: '',
                promocodeDeleteItemsResult: null
            }
        case FETCH_DELETE_PROMOCODE_ITEMS_SUCCESS:
            return {
                ...state,
                promocodeDeleteItemsResult: action.payload,
                isPromocodeDeleteItemsFetching: false,
            }
        case FETCH_DELETE_PROMOCODE_ITEMS_FAILURE:
            return {
                ...state,
                isPromocodeDeleteItemsFetching: false,
                error: action.payload
            }
        default: return state
    }
}
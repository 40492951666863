
import { AnyAction } from '@reduxjs/toolkit'
import { IAffiliateProgramState } from '../../../domain/pages/promotion/affiliatePrograms/IAffiliateProgramState'

import {
    CLEAR_AFFILIATE_PROGRAM_FORM_SAVING_RESULT,
    FETCH_DELETE_AFFILIATE_PROGRAM_ITEMS_FAILURE,
    FETCH_DELETE_AFFILIATE_PROGRAM_ITEMS_REQUEST,
    FETCH_DELETE_AFFILIATE_PROGRAM_ITEMS_SUCCESS,
    FETCH_GET_BY_ID_AFFILIATE_PROGRAM_FAILURE,
    FETCH_GET_BY_ID_AFFILIATE_PROGRAM_REQUEST,
    FETCH_GET_BY_ID_AFFILIATE_PROGRAM_SUCCESS,
    FETCH_GET_AFFILIATE_PROGRAMS_FAILURE,
    FETCH_GET_AFFILIATE_PROGRAMS_REQUEST,
    FETCH_GET_AFFILIATE_PROGRAMS_SUCCESS,
    FETCH_SAVE_FORM_AFFILIATE_PROGRAM_FAILURE,
    FETCH_SAVE_FORM_AFFILIATE_PROGRAM_REQUEST,
    FETCH_SAVE_FORM_AFFILIATE_PROGRAM_SUCCESS
} from './actions'

const initialState: IAffiliateProgramState = {
    error: '',
    isGetFetching: false,
    formSavingResult: null,
    isFormSaveFetching: false,
    items: [],
    isGetByIdFetching: false,
    form: undefined,
    deleteItemsResult: null,
    isDeleteItemsFetching: false
}

export const affiliateProgramsReducer = (state = initialState, action: AnyAction): IAffiliateProgramState => {
    switch (action.type) {
        case FETCH_GET_AFFILIATE_PROGRAMS_REQUEST:
            return {
                ...initialState,
                isGetFetching: true,
                error: '',
                formSavingResult: null,
                deleteItemsResult: null
            }
        case FETCH_GET_AFFILIATE_PROGRAMS_SUCCESS:
            return {
                ...state,
                isGetFetching: false,
                items: action.payload
            }
        case FETCH_GET_AFFILIATE_PROGRAMS_FAILURE:
            return {
                ...state,
                isGetFetching: false,
                error: action.payload
            }
        case FETCH_GET_BY_ID_AFFILIATE_PROGRAM_REQUEST:
            return {
                ...initialState,
                isGetByIdFetching: true,
                error: '',
                formSavingResult: null,
                form: undefined
            }
        case FETCH_GET_BY_ID_AFFILIATE_PROGRAM_SUCCESS:
            return {
                ...state,
                isGetByIdFetching: false,
                form: action.payload
            }
        case FETCH_GET_BY_ID_AFFILIATE_PROGRAM_FAILURE:
            return {
                ...state,
                isGetByIdFetching: false,
                error: action.payload
            }
        case FETCH_SAVE_FORM_AFFILIATE_PROGRAM_REQUEST:
            return {
                ...state,
                isFormSaveFetching: true,
                error: '',
                formSavingResult: null
            }
        case FETCH_SAVE_FORM_AFFILIATE_PROGRAM_SUCCESS:
            return {
                ...state,
                formSavingResult: action.payload,
                isFormSaveFetching: false,
            }
        case FETCH_SAVE_FORM_AFFILIATE_PROGRAM_FAILURE:
            return {
                ...state,
                isFormSaveFetching: false,
                error: action.payload
            }
        case CLEAR_AFFILIATE_PROGRAM_FORM_SAVING_RESULT: {
            return {
                ...state,
                formSavingResult: null,
                form: undefined
            }
        }
        case FETCH_DELETE_AFFILIATE_PROGRAM_ITEMS_REQUEST:
            return {
                ...state,
                isDeleteItemsFetching: true,
                error: '',
                deleteItemsResult: null
            }
        case FETCH_DELETE_AFFILIATE_PROGRAM_ITEMS_SUCCESS:
            return {
                ...state,
                deleteItemsResult: action.payload,
                isDeleteItemsFetching: false,
            }
        case FETCH_DELETE_AFFILIATE_PROGRAM_ITEMS_FAILURE:
            return {
                ...state,
                isDeleteItemsFetching: false,
                error: action.payload
            }
        default: return state
    }
}
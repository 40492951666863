import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HashLink } from 'react-router-hash-link'
import { Grid, Menu, MenuItemProps } from 'semantic-ui-react'
import { ProfileBlocksEnum } from '../../domain/pages/profile/profileBlocksEnum'
import { Media } from '../../utils/media'
import { ProfileBasePage } from './ProfileBasePage'

export const ProfilePageDesktop: React.FC = () => {
    const { t } = useTranslation()
    const [menuBlockActive, setActiveMenuBlock] = useState<ProfileBlocksEnum>(ProfileBlocksEnum.General)
    const toBlockHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
        setActiveMenuBlock(data.name as ProfileBlocksEnum)
    }

    return (
        <Media at='computer'>
            <Grid>
                <Grid.Column width={3}>
                    <Menu vertical>
                        <Menu.Item
                            as={HashLink}
                            name={ProfileBlocksEnum.General}
                            to={`#${ProfileBlocksEnum.General}`}
                            active={menuBlockActive === ProfileBlocksEnum.General}
                            onClick={toBlockHandler}
                        >
                            {t('general')}
                        </Menu.Item>
                        <Menu.Item
                            as={HashLink}
                            name={ProfileBlocksEnum.ChangePassword}
                            to={`#${ProfileBlocksEnum.ChangePassword}`}
                            active={menuBlockActive === ProfileBlocksEnum.ChangePassword}
                            onClick={toBlockHandler}
                        >
                            {t('changePassword')}
                        </Menu.Item>
                    </Menu>
                </Grid.Column>
                <Grid.Column width={13}>
                    <ProfileBasePage />
                </Grid.Column>
            </Grid>
        </Media>)
}
import React, { useEffect, useMemo, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { Button, Card, Header, Icon, Message, Popup, Segment, Image, Modal, DropdownItemProps, Form, DropdownProps } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { Pages } from '../../domain/navigation/pages'
import { useDispatchAction, useSelectorState } from '../../hooks/reduxHook'
import { changeNavigatePage } from '../../store/navigation/actions'
import { reorder } from '../../utils/arrayHelper'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import './index.css'
import { clearDeleteGoodsResult, clearToggleGoodsResult, deleteGoodsRequest, getGoodsRequest, setGoods, toggleActiveGoodsRequest, updateProductsOrderNumber } from '../../store/goods/actions'
import { GoodsType } from '../../domain/pages/goods/GoodsType'
import { BRANCHES_RESOURCE, DEFAULT_IMAGE_SRC } from '../../api'
import { IIdMatchParams } from '../../domain/navigation/IGoodsMatchParams'
import { IGoodsPayload } from '../../domain/pages/goods/IGoodsPayload'
import { toast } from 'react-toastify'
import { IAppResult } from '../../domain/appResult/IAppResult'
import { IGoodsDto } from '../../domain/pages/goods/IGoodsDto'
import { IGoodsOrderNumberDto } from '../../domain/pages/goods/IGoodsOrderNumberDto'
import { IGoodsPageProps } from '../../domain/pages/goods/IGoodsPageProps'
import { getResourcesRequest } from '../../store/resources/actions'
import { ILookUpDto } from '../../domain/general/ILookUpDto'
import { setDynamicBranchId } from '../../store/appConfig/actions'

const branchesProps = 'goodsBranches'

export const GoodsPageBase: React.FC<IGoodsPageProps & RouteComponentProps<IIdMatchParams>> = props => {
    const dispatch = useDispatchAction()
    const history = useHistory()
    const { t } = useTranslation()
    const isFetchingGoods = useSelectorState(state => state.goods.isFetching)
    const goods = useSelectorState(state => state.goods.goods)
    const currentCategory = useSelectorState(state => state.goods.currentCategory)

    const isDeleteGoodsFetching = useSelectorState(state => state.goods.isDeleteGoodsFetching)
    const deleteGoodsResult = useSelectorState(state => state.goods.deleteGoodsResult)
    const isToggleActiveGoodsFetching = useSelectorState(state => state.goods.isToggleActiveGoodsFetching)
    const toggleActiveGoodsResult = useSelectorState(state => state.goods.toggleActiveGoodsResult)
    const resources = useSelectorState(state => state.resources.resources)
    const isGetResourcesFetching = useSelectorState(state => state.resources.isGetResourcesFetching)
    const userBranchId = useSelectorState(state => state.appConfig.userBranchId)
    const userDynamicBranchId = useSelectorState(state => state.appConfig.userDynamicBranchId)

    const loadGoods = () => {
        dispatch(getGoodsRequest(
            props.match.params.id ?? null,
            props.match.params.id ? undefined : userBranchId || userDynamicBranchId))
    }

    useEffect(() => {
        dispatch(changeNavigatePage(Pages.Goods))

        if (!props.match.params.id && !userBranchId && !userDynamicBranchId) {
            dispatch(getResourcesRequest({ propName: branchesProps, route: BRANCHES_RESOURCE }))
        } else {
            loadGoods()
        }

        return function () {
            dispatch(clearDeleteGoodsResult())
            dispatch(clearToggleGoodsResult())
        }
    }, [dispatch, props.match.params.id, userBranchId, userDynamicBranchId])

    const editCategoryHandler = (id?: string) => {
        const containerCategoryId = currentCategory ? `${currentCategory.id}/` : ''
        history.push(`/category/${containerCategoryId}${id ?? 'new'}`)
    }

    const editProductHandler = (id?: string) => {
        const containerCategoryId = currentCategory ? `${currentCategory.id}/` : ''
        history.push(`/product/${containerCategoryId}${id ?? 'new'}`)
    }

    const getEditHandlerByType = (type: GoodsType) => {
        switch (type) {
            case GoodsType.Category: return editCategoryHandler
            case GoodsType.Product: return editProductHandler
        }
    }

    const [openModal, setOpenModal] = useState(false)
    const [payloadForDelete, setPayloadForDelete] = useState<IGoodsPayload>()
    const openDeleteModalHandler = (id: string, type: GoodsType) => {
        setPayloadForDelete({ id, type })
        setOpenModal(true)
    }
    const closeDeleteModalHandler = () => {
        setOpenModal(false)
        setPayloadForDelete(undefined)
    }

    const confirmedDeleteHandler = () => {
        if (payloadForDelete)
            dispatch(deleteGoodsRequest(payloadForDelete))

        closeDeleteModalHandler()
    }

    const selectCategoryHandler = (id: string) => {
        setGoodsStore([])
        history.push(`/${Pages.Goods}/${id}`)
    }

    const setGoodsStore = (goods: Array<IGoodsDto>) => dispatch(setGoods(goods))

    const [toggleGoodsId, setToggleGoodsId] = useState<string>()
    const toggleActiveGoodsHandler = (id: string, type: GoodsType) => {
        const payload: IGoodsPayload = { id, type }
        setToggleGoodsId(id)
        dispatch(toggleActiveGoodsRequest(payload))
    }

    const actionChangeResultDispatch = (result: IAppResult | null) => {
        if (result && result.isError)
            toast.error(result.message)
        else if (result && !result.isError) {
            toast.success(result.message, { autoClose: 5000 })
            loadGoods()
        }
    }

    useEffect(() => {
        actionChangeResultDispatch(deleteGoodsResult)
    }, [deleteGoodsResult])

    useEffect(() => {
        if (toggleActiveGoodsResult && toggleActiveGoodsResult.isError)
            toast.error(toggleActiveGoodsResult.message)
        else if (toggleActiveGoodsResult && !toggleActiveGoodsResult.isError) {
            toast.success(toggleActiveGoodsResult.message, { autoClose: 5000 })
            const goodsForChange = [...goods]
            const itemIndex = goodsForChange.findIndex(p => p.id == toggleGoodsId)
            const item = goodsForChange[itemIndex]
            const newItem = { ...item, isActive: !item.isActive }
            goodsForChange[itemIndex] = newItem

            setGoodsStore(goodsForChange)
            setToggleGoodsId(undefined)
        }
    }, [toggleActiveGoodsResult])

    const getSegmentHeader = () => currentCategory?.name || t(`${Pages.Goods}`)
    const goBackCategory = () => {
        const goodsId = currentCategory?.parentCategoryId ? `/${currentCategory?.parentCategoryId}` : ''
        history.push(`/goods${goodsId}`)
    }

    const getCardClickHandler = (type: GoodsType) => {
        switch (type) {
            case GoodsType.Category: return selectCategoryHandler
            case GoodsType.Product: return editProductHandler
        }
    }

    const branchResource = resources[branchesProps]
    const branchOptions: Array<DropdownItemProps> = useMemo(() => branchResource
        ?.map(p => ({ text: p.name, value: p.value }))
        ?? [], [branchResource])

    useEffect(() => {
        if (branchResource && branchResource.length > 0 && !userBranchId) {
            dispatch(setDynamicBranchId(branchResource[0].value))
        }
    }, [branchResource])

    useEffect(() => {
        if (branchOptions && branchOptions.length == 1) {
            dispatch(setDynamicBranchId(branchResource[0].value))
        }
    }, [branchOptions])

    const SortableCard = SortableElement(({ item }: { item: IGoodsDto }) =>
        <Card key={item.id} raised className='sortable-card'>
            <Image
                src={item.imagePath || DEFAULT_IMAGE_SRC}
                wrapped
                ui={false}
                onClick={() => getCardClickHandler(item.type)(item.id)} />
            <Card.Content onClick={() => getCardClickHandler(item.type)(item.id)}>
                <Card.Header>{item.name}</Card.Header>
                <Card.Meta>
                    {item.type == GoodsType.Category ? `${t('manyGoods')}: ${item.extraInfo}` : `${t('price')}: ${item.extraInfo}`}
                </Card.Meta>
            </Card.Content>
            <Card.Content extra className={'card-extra-button-groups'}>
                <Button.Group basic size='tiny'>
                    <Popup
                        content={t('edit')}
                        position='bottom right'
                        trigger={
                            <Button
                                icon='edit'
                                onClick={() => getEditHandlerByType(item.type)(item.id)}
                            />}
                    />
                    <Popup
                        content={item.isActive ? t('deactivate') : t('activate')}
                        position='bottom right'
                        trigger={
                            <Button
                                icon={item.isActive ? 'eye' : 'eye slash'}
                                onClick={() => toggleActiveGoodsHandler(item.id, item.type)}
                            />}
                    />
                    <Popup
                        content={t('delete')}
                        position='bottom right'
                        trigger={
                            <Button
                                icon='trash'
                                onClick={() => openDeleteModalHandler(item.id, item.type)}
                            />}
                    />
                </Button.Group>
            </Card.Content>
        </Card>
    )

    const SortableCardGroup = SortableContainer(({ items }: { items: IGoodsDto[] }) => {
        return (
            <Card.Group itemsPerRow={props.itemsPerRow || 8} stackable>
                {items.map((item, index) => (
                    <SortableCard key={`card-${index}`} index={index} item={item} />
                ))}
            </Card.Group>
        )
    })

    const sortEndHandler = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
        const orderedGoods = reorder(goods, oldIndex, newIndex)
        const goodsOrderNumbers: Array<IGoodsOrderNumberDto> = orderedGoods.map((value, index) => ({
            id: value.id,
            type: value.type,
            orderNumber: index + 1
        }))

        setGoodsStore(orderedGoods)
        dispatch(updateProductsOrderNumber(goodsOrderNumbers))
    }

    const getSortableHelperClass = () => {
        if (!props.itemsPerRow || props.itemsPerRow == 8)
            return 'size-eight'

        return undefined
    }

    const changeSelectHandler = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        if (event.type !== 'click')
            return

        const item: ILookUpDto | null = branchResource
            ?.find(p => p.value === data.value)
            ?? null

        item && dispatch(setDynamicBranchId(item.value))
    }

    const isLoading = () => isFetchingGoods || isDeleteGoodsFetching || isToggleActiveGoodsFetching || isGetResourcesFetching

    return (
        <div className='container'>
            <Segment attached='top'>
                <div className='segmentHeaderWithAdditionalBlock'>
                    <div className={'segmentHeaderWithGoBackBtn'}>
                        {
                            currentCategory &&
                            <Button
                                size='small'
                                basic
                                icon='arrow left'
                                onClick={goBackCategory}
                            />
                        }
                        <Header as='h3' className={'segmentHeader'}>{getSegmentHeader()}</Header>
                    </div>

                    <div className={'segmentHeaderButtonsWrapper'}>
                        {!props.match.params.id && !userBranchId && branchOptions && branchOptions.length != 1 &&
                            <Form.Dropdown
                                className='header-branch'
                                disabled={isLoading()}
                                selection fluid
                                placeholder={t('branch.placeholder')}
                                value={userDynamicBranchId || undefined}
                                options={branchOptions}
                                onChange={changeSelectHandler}
                                name={branchesProps}
                                noResultsMessage={t('dropdownNotResultMessage')}
                            />
                        }
                        <Button.Group basic size='small'>
                            <Popup
                                content={t('addCategory')}
                                position='bottom right'
                                trigger={
                                    <Button
                                        icon='zip'
                                        onClick={() => editCategoryHandler()}
                                    />}
                            />
                            <Popup
                                content={t('addProduct')}
                                position='bottom right'
                                trigger={
                                    <Button
                                        icon='cart plus'
                                        onClick={() => editProductHandler()}
                                    />}
                            />
                        </Button.Group>
                    </div>
                </div>
            </Segment>
            {
                ((goods && goods.length > 0) || isLoading()) &&
                <Segment
                    attached='bottom'
                    className={'containerWrapper containerWrapperWithMinSizeForLoadingIndicator'}
                    loading={isLoading()}>
                    {
                        goods && !!goods.length &&
                        <SortableCardGroup
                            items={goods}
                            onSortEnd={sortEndHandler}
                            axis='xy'
                            distance={1}
                            helperClass={getSortableHelperClass()}
                        />
                    }
                </Segment>
            }
            {
                ((!goods || !goods.length) && !isLoading()) &&
                <Message attached='bottom' size='tiny' info style={{ textAlign: 'center' }}>
                    <Icon name='info' />
                    {t('goodsInfo')}
                </Message>
            }
            <Modal
                size='mini'
                dimmer='blurring'
                open={openModal}
                onClose={closeDeleteModalHandler}
            >
                <Modal.Header>{t('delete.confirm.header')}</Modal.Header>
                <Modal.Content>
                    <p>{t('delete.confirm.question')}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={closeDeleteModalHandler}>
                        {t('false')}
                    </Button>
                    <Button positive onClick={confirmedDeleteHandler}>
                        {t('true')}
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>)
}
import { AnyAction } from '@reduxjs/toolkit'
import { IStockState } from '../../../domain/pages/promotion/stocks/IStockState'

import {
    CLEAR_DELETE_STOCK_RESULT,
    CLEAR_STOCK_FORM_SAVING_RESULT,
    CLEAR_TOGGLE_STOCK_RESULT,
    FETCH_DELETE_STOCK_FAILURE,
    FETCH_DELETE_STOCK_REQUEST,
    FETCH_DELETE_STOCK_SUCCESS,
    FETCH_GET_BY_ID_STOCK_FAILURE,
    FETCH_GET_BY_ID_STOCK_REQUEST,
    FETCH_GET_BY_ID_STOCK_SUCCESS,
    FETCH_GET_STOCKS_FAILURE,
    FETCH_GET_STOCKS_REQUEST,
    FETCH_GET_STOCKS_SUCCESS,
    FETCH_SAVE_FORM_STOCK_FAILURE,
    FETCH_SAVE_FORM_STOCK_REQUEST,
    FETCH_SAVE_FORM_STOCK_SUCCESS,
    FETCH_TOGGLE_STOCK_FAILURE,
    FETCH_TOGGLE_STOCK_REQUEST,
    FETCH_TOGGLE_STOCK_SUCCESS,
    SET_STOCKS
} from './actions'

const initialState: IStockState = {
    error: '',
    isGetStocksFetching: false,
    stockFormSavingResult: null,
    isStockFormSaveFetching: false,
    items: [],
    isGetByIdStockFetching: false,
    form: undefined,
    stockDeleteResult: null,
    isStockDeleteFetching: false,
    isStockToggleFetching: false,
    stockToggleResult: null
}

export const stocksReducer = (state = initialState, action: AnyAction): IStockState => {
    switch (action.type) {
        case FETCH_GET_STOCKS_REQUEST:
            return {
                ...initialState,
                isGetStocksFetching: true,
                error: '',
                stockFormSavingResult: null,
                stockDeleteResult: null
            }
        case FETCH_GET_STOCKS_SUCCESS:
            return {
                ...state,
                isGetStocksFetching: false,
                items: action.payload
            }
        case FETCH_GET_STOCKS_FAILURE:
            return {
                ...state,
                isGetStocksFetching: false,
                error: action.payload
            }
        case SET_STOCKS:
            return {
                ...state,
                items: action.payload
            }
        case FETCH_TOGGLE_STOCK_REQUEST:
            return {
                ...state,
                isStockToggleFetching: true,
                error: '',
                stockToggleResult: null
            }
        case FETCH_TOGGLE_STOCK_SUCCESS:
            return {
                ...state,
                stockToggleResult: action.payload,
                isStockToggleFetching: false,
            }
        case FETCH_TOGGLE_STOCK_FAILURE:
            return {
                ...state,
                isStockToggleFetching: false,
                error: action.payload
            }
        case CLEAR_TOGGLE_STOCK_RESULT:
            return {
                ...state,
                stockToggleResult: null,
            }
        case FETCH_GET_BY_ID_STOCK_REQUEST:
            return {
                ...initialState,
                isGetStocksFetching: true,
                error: '',
                stockFormSavingResult: null
            }
        case FETCH_GET_BY_ID_STOCK_SUCCESS:
            return {
                ...state,
                isGetStocksFetching: false,
                form: action.payload
            }
        case FETCH_GET_BY_ID_STOCK_FAILURE:
            return {
                ...state,
                isGetStocksFetching: false,
                error: action.payload
            }
        case FETCH_SAVE_FORM_STOCK_REQUEST:
            return {
                ...state,
                isStockFormSaveFetching: true,
                error: '',
                stockFormSavingResult: null
            }
        case FETCH_SAVE_FORM_STOCK_SUCCESS:
            return {
                ...state,
                stockFormSavingResult: action.payload,
                isStockFormSaveFetching: false,
            }
        case FETCH_SAVE_FORM_STOCK_FAILURE:
            return {
                ...state,
                isStockFormSaveFetching: false,
                error: action.payload
            }
        case CLEAR_STOCK_FORM_SAVING_RESULT: {
            return {
                ...state,
                stockFormSavingResult: null,
                form: undefined
            }
        }
        case FETCH_DELETE_STOCK_REQUEST:
            return {
                ...state,
                isStockDeleteFetching: true,
                error: '',
                stockDeleteResult: null
            }
        case FETCH_DELETE_STOCK_SUCCESS:
            return {
                ...state,
                stockDeleteResult: action.payload,
                isStockDeleteFetching: false,
            }
        case FETCH_DELETE_STOCK_FAILURE:
            return {
                ...state,
                isStockDeleteFetching: false,
                error: action.payload
            }
        case CLEAR_DELETE_STOCK_RESULT:
            return {
                ...state,
                stockDeleteResult: null
            }
        default: return state
    }
}
import { toggleActiveGoodsFailure, toggleActiveGoodsSuccess } from './../../../store/goods/actions'
import { AnyAction } from '@reduxjs/toolkit'
import { put } from '@redux-saga/core/effects'
import { postman } from '../../../utils/postman'
import { GOODS_ACTIVE_TOGGLE_REQUEST } from '../../../api'

export function* toggleActiveGoodsSaga(action: AnyAction): any {
    try {
        const url = `${GOODS_ACTIVE_TOGGLE_REQUEST}/${action.payload.id}/${action.payload.type}`
        const result = yield postman.post(url)

        yield put(toggleActiveGoodsSuccess(result))
    } catch (ex: any) {
        yield put(toggleActiveGoodsFailure(ex.response.data))
    }
}
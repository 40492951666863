import { toggleNewsItemFailure, toggleNewsItemSuccess } from './../../../../store/promotion/news/actions';
import { AnyAction } from '@reduxjs/toolkit'
import { put } from '@redux-saga/core/effects'
import { INFORMATION_POST_TOGGLE_REQUEST } from '../../../../api'
import { postman } from '../../../../utils/postman'

export function* toggleInformationPostsSaga(action: AnyAction): any {
    try {
        const url = `${INFORMATION_POST_TOGGLE_REQUEST}/${action.payload}`
        const deleteResult = yield postman.post(url)

        yield put(toggleNewsItemSuccess(deleteResult))
    } catch (ex: any) {
        yield put(toggleNewsItemFailure(ex.response.data))
    }
}
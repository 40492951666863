import { FETCH_LOGIN_REQUEST, FETCH_LOGIN_SUCCESS, FETCH_LOGIN_FAILURE, DEFAULT_STATE } from './actions'
import { AnyAction } from '@reduxjs/toolkit'
import { ACCESS_TOKEN } from '../../domain/constants'
import { IAuthState } from '../../domain/pages/auth/IAuthState'

const isExistAccessToken = () => {
    return Boolean(localStorage.getItem(ACCESS_TOKEN))
}

const initState = (): IAuthState  => ({
    isAuthFetching: false,
    isAuth: isExistAccessToken(),
    error: ''
})

export const authReducer = (state = initState(), action: AnyAction): IAuthState => {
    switch (action.type) {
        case FETCH_LOGIN_REQUEST:
            return {
                ...state,
                isAuthFetching: true,
                error: ''
            }
        case FETCH_LOGIN_SUCCESS:
            return {
                ...state,
                isAuth: isExistAccessToken(),
                isAuthFetching: false
            }
        case FETCH_LOGIN_FAILURE:
            return {
                ...state,
                isAuthFetching: false,
                error: action.payload
            }
        case DEFAULT_STATE:
            return {
                ...initState()
            }
        default: return state
    }
}
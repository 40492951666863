import React from "react"
import { MediaContextProvider } from "../../utils/media"
import { DesktopNavbar } from "./DesktopNavbar"
import { MobileNavbar } from "./MobileNavbar"

export const Navbar: React.FC = () => {
    return (
        <MediaContextProvider>
            <DesktopNavbar/>
            <MobileNavbar/>
        </MediaContextProvider>
    )
}
import { IValidationResult } from './../../domain/validationResult/IValidationResult'
import { put } from "@redux-saga/core/effects"
import { AnyAction } from "@reduxjs/toolkit"
import { postman } from '../../utils/postman'
import { UPLOAD_FILE } from '../../api'
import { getFileDtoRequest, uploadFileFailure } from '../../store/file/actions'

export function* uploadFileSaga(action: AnyAction): any {
    try {
        const form = new FormData()
        form.append('formFile', action.payload)

        const result: IValidationResult = yield postman.post(
            UPLOAD_FILE,
            form,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
 
        if (result.isError)
            yield put(uploadFileFailure(result.message))
        else
            yield put(getFileDtoRequest(result.id))
    } catch (ex: any) {
        yield put(uploadFileFailure(ex.response.data))
    }
}
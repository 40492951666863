import React from 'react'
import DatePicker from 'react-datepicker'
import { Form } from 'semantic-ui-react'
import { formatDate, parseDate } from '../../../utils/dateTimeHelper'
import './style.css'
import 'react-datepicker/dist/react-datepicker.css'

type DatePeriodProps = {
    startPeriod: string | null | undefined,
    endPeriod: string | null | undefined,
    label: string,
    placeholder?: string | undefined
    required?: boolean
    disabled?: boolean
    locale?: string | Locale | undefined,
    error?: string | undefined
    setDateRangeHandler: (startPeriod: string | null, endPeriod: string | null) => void
}

export const DatePeriod: React.FC<DatePeriodProps> = props => {

    return (
        <Form.Input
            disabled={props.disabled}
            required={props.required}
            error={props.error}
            label={props.label}
            input={
                <DatePicker
                    wrapperClassName='custom-datepicker-wrapper-width'
                    disabled={props.disabled}
                    placeholderText={props.placeholder}
                    locale={props.locale}
                    popperPlacement="bottom"
                    selectsRange={true}
                    dateFormat='dd.MM.yyyy'
                    startDate={parseDate(props.startPeriod)}
                    endDate={parseDate(props.endPeriod)}
                    onChange={(period: [Date | null, Date | null]) => {
                        props.setDateRangeHandler(formatDate(period[0]), formatDate(period[1]))
                    }}
                />}
        />
    )
}
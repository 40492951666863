import {
    all,
    takeEvery
} from 'redux-saga/effects'
import { FETCH_LOAD_PROFILE_REQUEST, FETCH_SAVE_PROFILE_REQUEST } from '../../../store/profile/actions'
import { loadProfileSaga } from './loadProfileSaga'
import { saveProfileSaga } from './saveProfileSaga'

export function* saga() {
    yield all([
        takeEvery(FETCH_LOAD_PROFILE_REQUEST, loadProfileSaga),
        takeEvery(FETCH_SAVE_PROFILE_REQUEST, saveProfileSaga),
    ])
}

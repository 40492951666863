import { ICategoryDto } from '../../../domain/pages/goods/category/ICategoryDto';
import { GOODS_UPDATE_ORDER_NUMBER_REQUEST } from '../../../api/requestEndpoints';
import { AnyAction } from "@reduxjs/toolkit"
import { postman } from '../../../utils/postman'
import { IGoodsOrderNumberDto } from '../../../domain/pages/goods/IGoodsOrderNumberDto'

export function* updateGoodsOrderNumberSaga(action: AnyAction): any {    
    try {
        const dtos: Array<IGoodsOrderNumberDto> = action.payload

        yield postman.post(GOODS_UPDATE_ORDER_NUMBER_REQUEST, dtos)
    } catch (ex: any) {
        console.log('updateGoodsOrderNumberSaga:', ex)
    }
}
import { AnyAction } from '@reduxjs/toolkit'
import { put } from '@redux-saga/core/effects'
import { postman } from '../../../utils/postman'
import { PRODUCT_GET_REQUEST } from '../../../api'
import { getProductByIdFailure, getProductByIdSuccess } from '../../../store/product/form/actions'

export function* getProductFormSaga(action: AnyAction): any {
    try {
        const url = `${PRODUCT_GET_REQUEST}/${action.payload || 'default'}`
        const category = yield postman.get(url)

        yield put(getProductByIdSuccess(category))
    } catch (ex: any) {
        yield put(getProductByIdFailure(ex.response.data))
    }
}
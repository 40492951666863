import React, { useState } from "react"
import { Form } from "semantic-ui-react"
import { IInputControl } from "../../../domain/components/form/dictionaries/IInputControl"

const PasswordControl: React.FC<IInputControl> = ({
    required,
    label,
    name,
    value,
    error,
    onChange,
}) => {
    const [passwordViewer, setPasswordViewerState] = useState(false)
    const togglePasswordViewHandler = () => setPasswordViewerState(prev => !prev)

    return <Form.Input
        required={required}
        label={label}
        name={name}
        value={value}
        error={error}
        onChange={onChange}
        type={passwordViewer ? 'text' : 'password'}
        autoComplete='new-password'
        action={{
            icon: passwordViewer ? 'eye slash' : 'eye',
            onClick: (event: any) => {
                event.preventDefault()
                togglePasswordViewHandler()
            }
        }}
    />
}

export default PasswordControl